import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 80%;
  /* margin: 94px auto auto auto; */
  padding-top: 94px;
  margin: auto;

  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    padding-top: 160px;
    /* margin-top: 160px; */
  }
  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    margin: auto;
    padding-top: 50px;
    /* padding-right: 15px; */
    /* padding-left: 40px; */
  }
`;

export const FlexRow = styled.div`
  display: flex;
  margin-top: 20px;
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Card = styled.div`
  background: #fff;
  border-radius: 10px;
  border: none;
  height: 580px;
  margin: auto;
  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    height: 690px;
    width: 1091px;
  }
`;

export const Form = styled.div`
  display: flex;
`;

export const Heading = styled.h1`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  color: #ffaa00;
  padding-top: 15px;

  text-align: center;

  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    font-size: 24px;
    /* line-height: 24px; */
  }
  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    font-size: 36px;
    /* line-height: 36px; */
    padding-left: 86px;
    padding-top: 34px;
    text-align: left;
  }
`;

export const Line = styled.div`
  margin: auto;
  max-width: 90%;
  opacity: 0.5;
  height: 0px;
  border: 1px solid rgba(3, 57, 73, 0.25);
  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    width: 90%;
  }
`;

export const Grid = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    display: grid;
    grid-template-columns: 2fr 2fr;
    height: 500px;
  }
`;

export const LeftColumn = styled.div`
  display: none;
  margin: auto;
  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    display: flex;
    left: 48px;
    top: 120px;
    flex-direction: column;
    margin: auto;
    justify-content: space-between;
  }
`;

export const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  justify-content: center;
  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    /* position: absolute; */
    right: 105px;
    top: 120px;
    justify-content: left;
  }
`;

export const ListGroup = styled.div`
  display: flex;
  align-items: baseline;
`;

export const FormLabelRight = styled.p`
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
`;
export const FormLabelLeft = styled(FormLabelRight)`
  font-weight: 500;
`;

export const UL = styled.ul`
  list-style: none;
  font-weight: 500;
  line-height: 18px;
  font-size: 18px;
`;

export const ULsm = styled(UL)`
  color: #bdbdbd;
  list-style-type: square;
  font-weight: 400;
  line-height: 12px;
  font-size: 12px;
  margin: 5px auto 5px auto;
`;

export const LI = styled.li`
  font-style: normal;
  color: black;
  margin-bottom: 23px;
`;

export const LIsm = styled(LI)`
  color: #bdbdbd;
  margin-bottom: auto;
`;

export const Circle = styled.div`
  width: 22px;
  height: 22px;
  border-radius: 50px;
  border: none;
  background-color: #e0e0e0;
`;

export const InputGroup = styled.div`
  margin: 15px auto 15px auto;
  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    /* margin-left: 32px; */
    /* width: 256px; */
  }
`;

export const Input = styled.input`
  height: 30px;
  min-width: 256px;
  border: none;
  margin: auto;
  margin: 15px auto 15px auto;
  background: rgba(196, 196, 196, 0.2);
  border-radius: 10px;
  text-align: left;
  font-size: 14px;
  line-height: 14px;
  padding: 5px;
  display: inline;
  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    width: 311px;
  }

  ::-webkit-input-placeholder {
    text-align: center;
  }

  :-moz-placeholder {
    text-align: center;
  }

  &:active {
    border: 1.5px solid #ffaa00;
  }

  &:focus {
    border: 3px solid #ffaa00;
  }
`;

export const Input2 = styled.input`
  border-radius: 0 10px 10px 0;
  height: 30px;
  border: none;
  margin: auto;
  /* margin: 15px auto 15px auto; */
  background: rgba(196, 196, 196, 0.2);
  text-align: left;
  font-size: 14px;
  line-height: 14px;
  padding: 5px;
  display: inline;
  @media (min-width: 992px) {
    width: 260px;
    border-left: none;
  }
  ::-webkit-input-placeholder {
    text-align: center;
  }

  :-moz-placeholder {
    text-align: center;
  }

  &:active {
    border: 1.5px solid #ffaa00;
  }

  &:focus {
    border: 3px solid #ffaa00;
  }
`;

export const TextArea = styled.textarea`
  height: 300px;
  width: 311px;
  border: none;
  margin: auto auto 15px auto;
  background: rgba(196, 196, 196, 0.2);
  border-radius: 10px;
  text-align: left;
  font-size: 14px;
  /* line-height: 14px; */
  resize: none;
  padding: 10px;
  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    width: 500px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    width: 372px;
    margin-left: 32px;
    height: 306px;
  }

  ::-webkit-input-placeholder {
    text-align: center;
  }

  :-moz-placeholder {
    text-align: center;
  }

  &:active {
    border: 1.5px solid #ffaa00;
  }

  &:focus {
    border: 3px solid #ffaa00;
  }
`;

export const Select = styled.select`
  height: 30px;
  width: auto;
  border: none;
  /* margin: auto; */
  /* margin: 15px auto 15px auto; */
  background: #ffaa00;
  border-radius: 10px 0 0 10px;
  border-style: none;
  text-align: center;
  font-size: 14px;
  line-height: 14px;
  color: white;
  display: inline;
  padding: 0;
  margin: 0;

  option {
    color: black;
    background: white;
    font-weight: small;
    display: flex;
    white-space: pre;
    min-height: 20px;
    padding: 0px 2px 1px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    width: 55px;
  }

  ::-webkit-input-placeholder {
    text-align: center;
  }

  :-moz-placeholder {
    text-align: center;
  }

  &:active {
    border: 1.5px solid #ffaa00;
  }

  &:focus {
    border: 3px solid #ffaa00;
  }
`;

export const InputLabel = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 18px;
  text-align: center;

  /* margin: 15px 0 0 0; */
  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    text-align: left;
    margin-left: 30px;
  }
`;

export const InputFooter = styled.div`
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  color: #bdbdbd;
  margin: 0 auto;
  text-align: left;
  list-style-type: none;

  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    font-size: 14px;
    line-height: 14px;
  }
`;

export const DateFieldLabels = styled(InputLabel)`
  font-size: 14px;
  line-height: 14px;
  color: ${(props) => props.theme.colors.gray4};
  margin: 15px 0 0 0;
`;

export const Button = styled.button`
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
  width: 146px;
  height: 37px;
  font-style: normal;
  float: left;
  margin-right: 9px;
  border: none;
  border-radius: 10px;
  background-color: #ffaa00;
  color: white;
  mix-blend-mode: normal;
  margin-top: 25px;

  &:hover {
    background: ${(props) => props.theme.colors.carrot};
  }
`;

export const NextButton = styled(Button)`
  margin-right: 36px;
  margin-left: 9px;
`;

export const AddButton = styled(Button)`
  width: 67px;
  height: 30px;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  float: none;
`;

export const UploadButton = styled.input`
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  width: 90px;
  height: 30px;
  font-style: normal;
  float: none;
  margin-right: 9px;
  border: none;
  border-radius: 10px;
  background-color: #ffaa00;
  color: white;
  mix-blend-mode: normal;
  /* margin-top: 25px; */

  &:hover {
    background: ${(props) => props.theme.colors.carrot};
  }
`;

export const Footer = styled.div`
  position: relative;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  justify-self: center;
`;

export const Subheading = styled.p`
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  color: #bdbdbd;
  margin: 10px auto;
  /* text-align: right; */

  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    margin-left: 52px;
    font-size: 14px;
    line-height: 14px;
  }
`;

export const Error = styled.p`
  color: ${(props) => props.theme.colors.tomato};
  margin: 0;
  font-size: 10px;
  line-height: 14px;
  margin: 0 auto;
`;

export const SelectType = styled.select`
  height: 30px;
  min-width: 256px;
  width: auto;
  border: none;
  /* margin: auto; */
  /* margin: 15px auto 15px auto; */
  background: #ffaa00;
  border-radius: 10px;
  border-style: none;
  text-align: center;
  font-size: 14px;
  line-height: 14px;
  color: white;
  display: inline;
  padding: 0;
  margin: 25px auto 10px auto;

  option {
    color: black;
    background: white;
    font-weight: small;
    display: flex;
    white-space: pre;
    min-height: 20px;
    padding: 0px 2px 1px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    width: 311px;
    margin-bottom: 15px;
  }

  ::-webkit-input-placeholder {
    text-align: center;
  }

  :-moz-placeholder {
    text-align: center;
  }

  &:active {
    border: 1.5px solid #ffaa00;
  }

  &:focus {
    border: 3px solid #ffaa00;
  }
`;

export const DeliverableGroup = styled.div`
  display: flex;
  align-items: center;
`;
