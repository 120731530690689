/* eslint-disable */
import React from 'react';
import { useHistory } from 'react-router-dom';
import Backdrop from '../Backdrop';
import PropTypes from 'prop-types';
import * as S from './MyModal.styled';
import { useSelector } from 'react-redux';
import BriefSubmissionNotification from '../../Modals/Modal Messages/BriefSubmissionNotification';
import TC from '../../Modals/Modal Messages/TC';
import TreatmentSubmissionNotification from '../../Modals/Modal Messages/TreatmentSubmissionNotification';
import { userSelector } from '../../../features/User/UserSlice';

export default function MyModal({
  link,
  linkTo,
  show,
  modalClosed,
  title,
  content,
  showAcceptBtn,
  showCancelBtn,
  button,
  button2,
  briefId,
}) {
  const { isAuthenticated, user } = useSelector(userSelector);

  const modalBody = [
    <TC />,
    <BriefSubmissionNotification />,
    <TreatmentSubmissionNotification />,
  ];
  const showButton = [true, false];

  const buttonText = ['Accept', 'Cancel', 'Close'];

  const history = useHistory();

  const openBrief = (data, slug) => {
    const url = [
      `/briefs/${data}`,
      '/briefs/my',
      '/briefs',
      '/briefs/my/new/step1',
    ];
    history.push(url[slug]);
  };
  const closeBrief = (data) => {
    const sendTo = ['/briefs/my', '/briefs', '/dashboard'];
    history.push(sendTo[linkTo]);
  };
  return (
    <>
      <Backdrop show={show} clicked={modalClosed} />

      <S.Modal
        style={{
          transform: show ? 'translateY(0)' : 'translateY(-100vh)',
          opacity: show ? 1 : 0,
        }}
      >
        <S.Wrapper>
          <div className="box-header">
            <S.Title>{title}</S.Title>
          </div>
          <div className="box-content" style={{ textAlign: 'center' }}>
            <S.Content>{modalBody[content]}</S.Content>
          </div>

          <div className="box-footer">
            {showButton[showAcceptBtn] ? (
              <S.ButtonAccept
                onClick={() => openBrief(briefId, link)}
                style={{ color: 'white' }}
              >
                {buttonText[button]}
              </S.ButtonAccept>
            ) : null}

            {showButton[showCancelBtn] ? (
              <S.ButtonCancel onClick={modalClosed}>
                {buttonText[button2]}
              </S.ButtonCancel>
            ) : null}
          </div>
        </S.Wrapper>
      </S.Modal>
    </>
  );
}

MyModal.propTypes = {
  link: PropTypes.string.isRequired,
  linkTo: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  modalClosed: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.number.isRequired,
  showAcceptBtn: PropTypes.number.isRequired,
  showCancelBtn: PropTypes.number.isRequired,
  button: PropTypes.number.isRequired,
  button2: PropTypes.number.isRequired,
  briefId: PropTypes.string.isRequired,
};
