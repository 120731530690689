/* eslint-disable */
import styled from 'styled-components';

export const Container = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 297px;
  width: 334px;
  border-radius: 10px;
  margin-top: 83px;
  align-self: center;
  background: white;
  box-shadow: -10px 10px 30px rgba(0, 0, 0, 0.3);
  @media (max-width: ${(props) => props.theme.breakpoints.xs}) {
  }
  @media (min-width: ${(props) =>
      props.theme.breakpoints.xs}) and (max-width: ${(props) =>
      props.theme.breakpoints.s}) {
  }
  @media (min-width: ${(props) =>
      props.theme.breakpoints.s}) and (max-width: ${(props) =>
      props.theme.breakpoints.m}) {
    height: 350px;
    width: 444px;
    margin-top: 73px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    align-self: center;
    width: 572px;
    height: 400px;
    margin-top: 293px;
    position: absolute;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    /* align-self: flex-start; */
    width: 559px;
    margin-top: 293px;
    position: absolute;
    /* right: calc(50% - 20px); */
  }
`;

export const Error = styled.p`
  margin: 0;
  font-size: 12px;
  color: ${(props) => props.theme.colors.tomato};
`;

export const Button = styled.button`
  background: ${(props) => props.theme.colors.orange};
  color: white;
  border-radius: 10px;
  border: 0;
  outline: 0;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  letter-spacing: -0.055em;
  padding: 5px 20px;

  &:hover {
    background: #ffaa00;
  }

  &:active {
    outline: none;
    border: 2px solid #d64127;
    box-sizing: border-box;
  }
  @media (min-width: ${(props) =>
      props.theme.breakpoints.s}) and (max-width: ${(props) =>
      props.theme.breakpoints.l}) {
    margin-top: 5px;
    padding: 10px 37px;
    font-size: 16px;
    line-height: 16px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    width: 152px;
    height: 48px;
    font-size: 18px;
    line-height: 18px;
  }
`;

export const Input = styled.input`
  width: 209px;
  height: 35px;
  background: rgba(196, 196, 196, 0.2);
  border: none;
  outline: none;
  border-radius: 10px;
  margin-bottom: 14px;
  padding: 5px;
  @media (min-width: ${(props) =>
      props.theme.breakpoints.s}) and (max-width: ${(props) =>
      props.theme.breakpoints.m}) {
    width: 277px;
    height: 47px;
    right: 58px;
    margin-bottom: 21px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    width: 349px;
    height: 47px;
    margin-bottom: 31px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    width: 349px;
    height: 47px;
    margin-bottom: 31px;
  }
`;
