/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as S from './Account.styled';
import axios from 'axios';

import { getUserProfile, postProfile, upload } from '../../../../redux/action';

import ReactPlayer from 'react-player/lazy';

export default function BrandProfile() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [profile, setProfile] = useState({});
  const [isError, setIsError] = useState(false);
  let history = useHistory();

  const {
    hasProfile,
    pending,
    error,
    userProfileData,
    isAuthenticated,
    user,
  } = useSelector((state) => ({
    isAuthenticated: state.states.isAuthenticated,
    user: state.states.user,
    pending: state.states.pending,
    error: state.states.error,
    hasProfile: state.states.hasProfile,
    userProfileData: state.states.userProfileData,
  }));

  const onClickEdit = () => {
    history.push(`/brands/${user.sub}/profile/edit`);
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsError(false);
      setIsLoading(true);
      try {
        const result = await axios({
          method: 'get',
          url: `/api/brands/${user.sub}/profile`,
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwtToken'),
          },
        });
        setProfile(result.data);
        setIsLoading(false);
      } catch (error) {
        setIsError(true);
        console.log(error);
      }
    };
    fetchData();
  }, []);

  console.log(profile);

  if (!isLoading) {
    return (
      <S.Wrapper>
        <S.TopSection>
          <S.Breadcrumb>My Account</S.Breadcrumb>
          <S.ButtonEdit
            onClick={() => onClickEdit()}
            style={{
              marginTop: '10px',
              marginRight: '33px',
            }}
          >
            Edit
          </S.ButtonEdit>
        </S.TopSection>

        <S.Cards>
          <S.FlexRow>
            <S.BasicInfoCard>
              <S.CardTitle>Basic info</S.CardTitle>
              <S.FlexRow>
                <S.LeftColumn>
                  <S.Avatar
                    src={profile.profilePictureUrl}
                    alt="profile picture"
                  />
                </S.LeftColumn>

                <S.MiddleColumn>
                  <S.Field>
                    First name: <S.FieldValue>{profile.firstName}</S.FieldValue>
                  </S.Field>

                  <S.Field>
                    Last name: <S.FieldValue>{profile.lastName}</S.FieldValue>
                  </S.Field>

                  <S.Field>
                    Username: <S.FieldValue>@{profile.userName}</S.FieldValue>
                  </S.Field>

                  <S.Field>
                    Location:{' '}
                    <S.FieldValue>
                      {profile.city}, {profile.country}
                    </S.FieldValue>
                  </S.Field>
                </S.MiddleColumn>
                <S.RightColumn>
                  <S.Field>
                    Main role: <S.FieldValue>{profile.role}</S.FieldValue>
                  </S.Field>

                  <S.Field>
                    Phone number:{' '}
                    <S.FieldValue>{profile.mobilePhone}</S.FieldValue>
                  </S.Field>

                  <S.Field>
                    Email: <S.FieldValue>{profile.primaryEmail}</S.FieldValue>
                  </S.Field>

                  <S.Field>
                    Website: <S.FieldValue>{profile.website}</S.FieldValue>
                  </S.Field>
                </S.RightColumn>
              </S.FlexRow>
            </S.BasicInfoCard>
          </S.FlexRow>
          <S.FlexRow>
            <S.PortfolioCard>
              <S.CardTitle>Portfolio</S.CardTitle>
              <S.Grid1>
                <S.SocialContainer>
                  {profile.socialMediaHandles[0].url ? (
                    <S.SocialRow>
                      <i
                        className="fa fa-facebook-square"
                        style={{
                          color: 'black',
                          marginRight: '29px',
                          marginLeft: '38px',
                        }}
                      ></i>
                      <S.SocialHandle>
                        {profile.socialMediaHandles[0].url}
                      </S.SocialHandle>
                    </S.SocialRow>
                  ) : null}

                  {profile.socialMediaHandles[1].url ? (
                    <S.SocialRow>
                      <i
                        className="fa fa-instagram"
                        style={{
                          color: 'black',
                          marginRight: '29px',
                          marginLeft: '38px',
                        }}
                      ></i>
                      <S.SocialHandle>
                        {profile.socialMediaHandles[1].url}
                      </S.SocialHandle>
                    </S.SocialRow>
                  ) : null}
                  {profile.socialMediaHandles[6].url ? (
                    <S.SocialRow>
                      <i
                        className="fa fa-behance-square"
                        style={{
                          color: 'black',
                          marginRight: '29px',
                          marginLeft: '38px',
                        }}
                      ></i>
                      <S.SocialHandle>
                        {profile.socialMediaHandles[6].url}
                      </S.SocialHandle>
                    </S.SocialRow>
                  ) : null}
                  {profile.socialMediaHandles[2].url ? (
                    <S.SocialRow>
                      <i
                        className="fa fa-twitter-square"
                        style={{
                          color: 'black',
                          marginRight: '29px',
                          marginLeft: '38px',
                        }}
                      ></i>
                      <S.SocialHandle>
                        {profile.socialMediaHandles[2].url}
                      </S.SocialHandle>
                    </S.SocialRow>
                  ) : null}
                  {profile.socialMediaHandles[3].url ? (
                    <S.SocialRow>
                      <i
                        className="fa fa-youtube"
                        style={{
                          color: 'black',
                          marginRight: '29px',
                          marginLeft: '38px',
                        }}
                      ></i>
                      <S.SocialHandle>
                        {profile.socialMediaHandles[3].url}
                      </S.SocialHandle>
                    </S.SocialRow>
                  ) : null}
                  {profile.socialMediaHandles[5].url ? (
                    <S.SocialRow>
                      <i
                        className="fa fa-vimeo-square"
                        style={{
                          color: 'black',
                          marginRight: '29px',
                          marginLeft: '38px',
                        }}
                      ></i>
                      <S.SocialHandle>
                        {profile.socialMediaHandles[5].url}
                      </S.SocialHandle>
                    </S.SocialRow>
                  ) : null}
                  {profile.socialMediaHandles[4].url ? (
                    <S.SocialRow>
                      <i
                        className="fa fa-linkedin-square"
                        style={{
                          color: 'black',
                          marginRight: '29px',
                          marginLeft: '38px',
                        }}
                      ></i>
                      <S.SocialHandle>
                        {profile.socialMediaHandles[4].url}
                      </S.SocialHandle>
                    </S.SocialRow>
                  ) : null}
                </S.SocialContainer>
                {/* <S.VideoContainer>
                  <ReactPlayer width="100%" height="100%" />
                </S.VideoContainer> */}
              </S.Grid1>
            </S.PortfolioCard>
            <S.ShortBioCard>
              <S.CardTitle>Short bio</S.CardTitle>
              <S.Content>
                <S.BioText>{profile.bio}</S.BioText>
              </S.Content>
            </S.ShortBioCard>
          </S.FlexRow>
          <S.FlexRow>
            {/* <S.SecurityCard>
              <S.CardTitle>Security</S.CardTitle>

              <S.SecurityIconContainer>
                <i
                  className="fa fa-key"
                  style={{
                    color: 'black',
                    marginRight: '14px',
                    marginLeft: '38px',
                  }}
                ></i>{' '}
                <S.CardMenuItem>Password</S.CardMenuItem>
                <br />
              </S.SecurityIconContainer>

              <S.SecurityIconContainer>
                <i
                  className="fa fa-phone"
                  style={{
                    color: 'black',
                    marginRight: '15px',
                    marginLeft: '38px',
                  }}
                ></i>{' '}
                <S.CardMenuItem>Phone number</S.CardMenuItem>
                <br />
              </S.SecurityIconContainer>

              <S.SecurityIconContainer>
                <i
                  className="fa fa-envelope"
                  style={{
                    color: 'black',
                    marginRight: '12px',
                    marginLeft: '38px',
                  }}
                ></i>{' '}
                <S.CardMenuItem>Additional email address</S.CardMenuItem>
                <br />
              </S.SecurityIconContainer>
              <br />
              <S.ButtonEdit
                style={{
                  position: 'absolute',
                  bottom: '10px',
                  right: '22px',
                }}
              >
                Edit
              </S.ButtonEdit>
            </S.SecurityCard> */}
            {/* <S.PaymentsCard>
              <S.CardTitle>Payments</S.CardTitle>
              <S.AccountBalance>U$ {profile.accountBalance}</S.AccountBalance>
              <S.ButtonEdit
                style={{
                  position: 'absolute',
                  bottom: '10px',
                  right: '22px',
                }}
              >
                Payout
              </S.ButtonEdit>
              <S.KaleColorText>Balance available</S.KaleColorText>
              <S.ButtonPayout
                style={{
                  position: 'absolute',
                  bottom: '10px',
                  left: '33px',
                }}
              >
                Payout
              </S.ButtonPayout>
            </S.PaymentsCard> */}
            {/* <S.AccountStatusCard>
              <S.CardTitle>Account Status</S.CardTitle>

              <S.CurrentAccountStatus>
                {profile.accountStatus}
              </S.CurrentAccountStatus>
              <S.KaleColorText>
                Apply to become a premium brand.{' '}
              </S.KaleColorText>
              <S.ButtonEdit
                style={{
                  position: 'absolute',
                  bottom: '10px',
                  right: '22px',
                }}
              >
                Apply
              </S.ButtonEdit>
            </S.AccountStatusCard> */}
          </S.FlexRow>
        </S.Cards>
      </S.Wrapper>
    );
  } else if (profile.userName === null) {
    history.push(`/brands/${user.sub}/profile/edit`);
  }

  return <></>;
}
