/* eslint-disable */
import React, { useEffect } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import * as S from '../Signup/Signup.style';
import * as L from './loginStyle';
import { Container } from './loginStyle';
import FormLabel from '../../components/FormLabel';

import {
  loginUser,
  userSelector,
  clearState,
} from '../../features/User/UserSlice';

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { register, errors, handleSubmit } = useForm();

  const {
    isFetching,
    isSuccess,
    isError,
    errorMessage,
    user,
    isAuthenticated,
  } = useSelector(userSelector);

  const onSubmit = (data) => {
    dispatch(loginUser(data));
  };

  useEffect(() => {
    if (isError) {
      setTimeout(() => {
        dispatch(clearState());
      }, 2000);
    }
  }, [dispatch, isError]);

  if (isAuthenticated && user) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <>
      <Container onSubmit={handleSubmit(onSubmit)}>
        <S.Heading>Login</S.Heading>
        <FormLabel>Email</FormLabel>
        <L.Input type="text" name="email" ref={register({ required: true })} />
        <S.Error>{errors.email && 'Email is required'}</S.Error>
        <FormLabel>Password</FormLabel>
        <L.Input
          type="password"
          name="password"
          ref={register({ required: true })}
        />
        <S.Error>{errors.password && 'Password is required'}</S.Error>
        <L.Error>{errorMessage}</L.Error>

        <L.Button type="submit">Login</L.Button>

        <S.TermsContainerLogin>
          <S.Terms
            style={{
              position: 'relative',
              marginTop: '15px',
              marginLeft: '0',
            }}
          >
            Don&apos;t have an account?
            <Link to="/signup" style={{ fontSize: '10px' }}>
              {' '}
              Sign Up
            </Link>
          </S.Terms>
          <S.Terms
            style={{
              position: 'relative',
              marginTop: '5px',
              marginLeft: '0',
            }}
          >
            <Link
              to="/forgot-password"
              style={{ fontSize: '10px', color: '#02686D' }}
            >
              Forgot Password
            </Link>
          </S.Terms>
        </S.TermsContainerLogin>
      </Container>
    </>
  );
};

export default Login;
