/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import * as S from './Card.Style';

function Card({ title, description, image, buttonText, url }) {
  return (
    <S.Card>
      {/* <S.CardTitle>
        {title.length > 16 ? title.substring(0, 16) + ' ...' : title}
      </S.CardTitle> */}
      <S.CardBody>
        <S.Paragraph>{title}</S.Paragraph>
      </S.CardBody>
      <S.ImageDiv>
        <img src={image} alt="card_image" />
      </S.ImageDiv>

      <S.CardFooter>
        <S.Button href={url} alt="link" rel="noreferrer" target="_blank">
          {buttonText}
        </S.Button>
      </S.CardFooter>
    </S.Card>
  );
}

Card.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
};

export default Card;
