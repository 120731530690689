/* eslint-disable */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import * as S from './BriefCreationForm.style';
import LeftColumn from './LeftColumn';
import {
  briefFormSelector,
  setAdditionalNotes,
  setAdditionalNotesProgressIndicator,
} from './briefFormSlice';

import { storeToSessionStorage } from '../../../helpers/SessionController';

export const Step11 = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    additionalNotes,
    keyDetailsProgressIndicator,
    theBriefProgressIndicator,
    additionalNotesProgressIndicator,
  } = useSelector(briefFormSelector);
  const { register, required, errors, handleSubmit } = useForm({
    defaultValues: { additionalNotes },
  });

  const handleChange = () => {
    dispatch(setAdditionalNotesProgressIndicator(true));
  };

  const onSubmit = (data) => {
    dispatch(setAdditionalNotes(data.additionalNotes));
    storeToSessionStorage(data);
    history.push('/briefs/my/new/brief-review');
  };

  const previous = () => {
    history.push('/briefs/my/new/step10');
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <S.Wrapper>
        <S.Card>
          <S.Heading> Create a brief</S.Heading>
          <S.Line />
          <S.Grid>
            <LeftColumn
              progress={99}
              keyDetailsCompleted={keyDetailsProgressIndicator}
              theBriefCompleted={theBriefProgressIndicator}
              additionalNotesCompleted={additionalNotesProgressIndicator}
            />
            <S.RightColumn>
              <S.InputLabel htmlFor="additionalNotes">
                Additional Notes
              </S.InputLabel>
              <S.TextArea
                name="additionalNotes"
                type="textarea"
                row="10"
                cols="50"
                placeholder="Any additional notes that might be helpful to the creative"
                ref={register({ required: true })}
                onChange={handleChange}
              />
              <S.Error>
                {errors.additionalNotes && 'Field is required!'}
              </S.Error>
            </S.RightColumn>
          </S.Grid>
          <S.Footer>
            <S.Button onClick={previous}>Previous</S.Button>
            <S.NextButton type="submit">Review</S.NextButton>
          </S.Footer>
        </S.Card>
      </S.Wrapper>
    </form>
  );
};
