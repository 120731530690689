/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as S from './Signup.style';
import Toggle from '../../components/Switch';
import FormLabel from '../../components/FormLabel';
import {
  signupUser,
  userSelector,
  clearState,
} from '../../features/User/UserSlice';
import 'react-toastify/dist/ReactToastify.css';

export default function Signup() {
  const dispatch = useDispatch();
  const { register, handleSubmit, required, errors, watch } = useForm();
  const password = useRef({});
  password.current = watch('password', '');
  const history = useHistory();
  const [account, setAccount] = useState(true);
  const [disabled, setDisabled] = useState(true);

  const {
    isFetching,
    isSuccess,
    isError,
    errorMessage,
    isAuthenticated,
    user,
  } = useSelector(userSelector);

  useEffect(
    () => () => {
      dispatch(clearState());
    },
    [],
  );

  useEffect(() => {
    if (isSuccess) {
      dispatch(clearState());
      history.push('/signup/email-sent');
    }
    if (isError) {
      toast.error(errorMessage);
      dispatch(clearState());
    }
  }, [isSuccess, isError]);

  const setEnabled = (e) => {
    setDisabled(!disabled);
  };

  const onSubmit = (data) => {
    let type = null;
    if (!account) {
      type = 'Creative';
    } else {
      type = 'Brand';
    }
    const newUser = {
      data,
      role: type,
    };
    dispatch(signupUser(newUser, history));
  };

  const Switch = () => {
    if (account) {
      setAccount(false);
    } else {
      setAccount(true);
    }
  };

  if (isAuthenticated) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <>
      <S.Container onSubmit={handleSubmit(onSubmit)}>
        <S.Heading>Sign up</S.Heading>
        <FormLabel>Email</FormLabel>
        <S.Input
          type="text"
          name="email"
          ref={register({
            required: 'Required',
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'Invalid email address',
            },
          })}
        />
        <S.Error>
          {(errors.email &&
            errors.email.type === 'required' &&
            'Email is required') ||
            (errors.email &&
              errors.email.type === 'pattern' &&
              errors.email.message)}
        </S.Error>
        <FormLabel>Password</FormLabel>
        <S.Input
          type="password"
          name="password"
          ref={register({
            required: 'You must specify a password',
            minLength: {
              value: 8,
              message: 'Password must have at least 8 characters',
            },
          })}
        />
        <S.Error>{errors.password && errors.password.message}</S.Error>
        <FormLabel>Confirm Password</FormLabel>
        <S.Input
          type="password"
          name="confirmPassword"
          ref={register({
            validate: (value) =>
              value === password.current || 'The passwords do not match',
          })}
        />
        <S.Error>
          {errors.confirmPassword && errors.confirmPassword.message}
        </S.Error>
        <S.TermsContainer>
          <S.TermsControlContainer>
            <S.Checkbox
              type="checkbox"
              checked={!disabled}
              onChange={setEnabled}
              ref={register({ required: true })}
            />
          </S.TermsControlContainer>
          <S.Terms>
            I have read and agreed to the BlueAvo{' '}
            <Link to="terms" style={{ 'font-size': '10px' }}>
              Terms and Conditions
            </Link>
          </S.Terms>
        </S.TermsContainer>
        <S.Error>{errorMessage}</S.Error>
        <S.UserContainer>
          Brand
          <Toggle margin="0 15px" handleToggle={() => Switch()} />
          Creative
        </S.UserContainer>
        <S.Button disabled={disabled} type="submit">
          Submit
        </S.Button>

        <S.TermsContainer>
          <S.Terms
            style={{
              marginTop: '5px',
              marginLeft: '0',
            }}
          >
            Already have an account?{' '}
            <Link to="/login" style={{ fontSize: '10px' }}>
              {' '}
              Login
            </Link>
          </S.Terms>
        </S.TermsContainer>
      </S.Container>
    </>
  );
}
