/* eslint-disable */
import React, { Component } from 'react';
import * as S from '../Signup/Signup.style';
import * as L from '../Login/loginStyle';
import { Container } from '../Login/loginStyle';
import FormInput from '../../components/FormInput';
import FormLabel from '../../components/FormLabel';

import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { resendConfirmationEmail } from '../../redux/action';

class ResendConfirmationEmail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      errors: {},
      disable: false,
    };
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmitHandler = (e) => {
    this.setState({ disable: true });
    e.preventDefault();

    const userData = {
      email: this.state.email,
    };

    this.props.resendConfirmationEmail(userData, this.props.history);
  };

  componentWillReceiveProps(nextProps) {
    this.setState({ disable: false });
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  render() {
    const { email, errors } = this.state;
    const { error, isAuthenticated, user } = this.props;

    return (
      <>
        <Container onSubmit={this.onSubmitHandler}>
          <S.Heading>Resend Verification Email</S.Heading>
          <FormLabel>Email</FormLabel>
          <FormInput
            type="email"
            name="email"
            value={email}
            placeholder={'Enter your email address'}
            onChange={this.onChange}
          />

          {!this.state.disable ? (
            <L.Button style={{ width: 'auto' }} disabled={!this.state.email}>
              Resend Email
            </L.Button>
          ) : null}

          <S.TermsContainer>
            <S.Terms
              style={{
                position: 'absolute',
                marginTop: '5px',
                marginLeft: '0',
              }}
            >
              Already have an account?
              <Link to="/login" style={{ fontSize: '10px' }}>
                {' '}
                Login
              </Link>
            </S.Terms>
          </S.TermsContainer>
        </Container>
      </>
    );
  }
}

ResendConfirmationEmail.propTypes = {
  resendConfirmationEmail: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.states.isAuthenticated,
  errors: state.states.errors,
});

export default connect(mapStateToProps, { resendConfirmationEmail })(
  ResendConfirmationEmail,
);
