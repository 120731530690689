/* eslint-disable */
import styled from 'styled-components';

export const Label = styled.label`
  color: ${(props) => props.theme.colors.blueberry};
  font-size: 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  letter-spacing: -0.055em;
  margin-bottom: 2px;
  @media (min-width: ${(props) =>
      props.theme.breakpoints.s}) and (max-width: ${(props) =>
      props.theme.breakpoints.m}) {
    margin-bottom: 7px;
  }
`;
