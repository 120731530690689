/*eslint-disable*/
import axios from 'axios';
import setAuthToken from '../utils/setAuthToken';
import jwt_decode from 'jwt-decode';

import {
  SET_CURRENT_USER,
  LOGOUT,
  GET_ERRORS,
  PENDING,
  CLEAR_ERRORS,
  GET_USER_PROFILE,
  GET_USER_PROFILE_ERROR,
  GET_USER_PROFILE_PENDING,
  GET_USER_PROFILE_SUCCESS,
  USER_PROFILE_UPDATED,
  CHANGE_VIEW,
  GET_MY_BRIEF,
  GET_MY_BRIEF_PENDING,
  GET_MY_BRIEF_ERROR,
  GET_MY_BRIEF_SUCCESS,
  SEARCH_BRIEF,
  POST_BRIEF,
  UPLOAD_FILE,
  VERIFY_ACCOUNT,
  GET_TREATMENTS,
  USER_LOADING,
} from './constant';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
let errorNotification;
let notification;

const notify = (errorNotification) =>
  toast.error(`${errorNotification}`, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

///////////////////////////////////////////////////
//START OF AUTH STUFF
// register user
export const registerUser = (userData, history) => (dispatch) => {
  axios
    .post('/api/auth/register', userData)
    .then((res) => {
      // console.log(res);
      // redirect user to login page
      history.push('/signup/email-sent');
      // clear errors
      dispatch({
        type: CLEAR_ERRORS,
        payload: {},
      });
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data.message,
        });
      }

      if (err.request) {
        // console.log(err.request.response);
      }
    });
};

export const accountVerified = (email, token) => (dispatch) => {
  axios
    .get(`/api/auth/confirmation/${email}/${token}`)
    .then((res) => {
      // console.log(res.data.message);
      dispatch(sendStatus(res));
      dispatch({
        type: GET_ERRORS,
        payload: res.data.message,
      });
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data.message,
        });
      }

      if (err.request) {
        let error = JSON.parse(err.request.response).message;
        // console.log(error);
        dispatch({
          type: GET_ERRORS,
          payload: error,
        });
      }
    });
};

export const sendStatus = (data) => () => {
  return {
    type: VERIFY_ACCOUNT,
    payload: data,
  };
};

// login user
export const loginUser = (userData, history) => (dispatch) => {
  axios
    .post('/api/auth/login', userData)
    .then((res) => {
      if (res.status === 200) {
        // save to localStorage
        const { token } = res.data;
        // set token to localStorage
        localStorage.setItem('jwtToken', token);
        // set token to auth header
        setAuthToken(token);
        // decode token to get user data
        const decoded = jwt_decode(token);
        //   set current user
        dispatch(setCurrentUser(decoded));

        history.push('/login');

        dispatch({
          type: CLEAR_ERRORS,
          payload: {},
        });
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: res.message,
        });
      }
    })
    .catch((err) => {
      if (err.response) {
        notify(err.response.data.message);
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data.message,
        });
      }

      if (err.request) {
        // console.log(err.request.response);
      }
    });
};

export const forgotPassword = (data, history) => (dispatch) => {
  axios
    .post('/api/auth/forgot-password', data)
    .then((res) => {
      history.push('/forgot-password/email-sent');
      // clear errors
      dispatch({
        type: CLEAR_ERRORS,
        payload: {},
      });
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data.message,
        });
      }

      if (err.request) {
        // console.log(err.request.response);
      }
    });
};

export const resendConfirmationEmail = (data, history) => (dispatch) => {
  axios
    .post('/api/auth/resendConfirmationEmail', data)
    .then((res) => {
      history.push('/signup/email-sent');
      // clear errors
      dispatch({
        type: CLEAR_ERRORS,
        payload: {},
      });
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data.message,
        });
      }

      if (err.request) {
        // console.log(err.request.response);
      }
    });
};

export const changePassword = (data, history) => (dispatch) => {
  axios({
    method: 'put',
    url: `/api/auth/forgot-password/${data.email}/${data.token}`,
    data,
    headers: { Authorization: 'Bearer ' + localStorage.getItem('jwtToken') },
  })
    .then((res) => {
      // console.log(res);
      if (res.status === 200) {
        // console.log(res);
        let value = true;
        history.push(`/forgot-password/success=${value}`);
      } else if (res.status !== 200) {
        // console.log(res);
        let value = false;
        history.push(`/forgot-password/success=${value}`);
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// set token of logged in user
export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

//User loading
export const setUserLoading = () => {
  return {
    type: USER_LOADING,
  };
};

export const logoutUser = () => {
  return function (dispatch) {
    dispatch({
      type: LOGOUT,
      payload: null,
    });
  };
};

//END OF AUTH STUFF
///////////////////////////////////////////////////

///////////////////////////////////////////////////
//START OF PROFILE STUFF

export const pending = () => {
  return {
    type: PENDING,
  };
};

export const getUserProfileError = (error) => {
  return {
    type: GET_USER_PROFILE_ERROR,
    payload: error,
  };
};

export const getUserProfileSuccess = (data) => {
  return {
    type: GET_USER_PROFILE_SUCCESS,
    payload: data,
  };
};

// getUser Profile data
export const getCreativeProfile = (id) => (dispatch) => {
  axios({
    method: 'get',
    url: `/api/creatives/${id}/profile`,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('jwtToken'),
    },
  })
    .then((res) => {
      // console.log(res.data);
      dispatch(getUserProfileSuccess(JSON.stringify(res.data)));
    })
    .catch((err) => {
      // console.log(err);
      dispatch(getUserProfileError(err));
    });
};

export const getBrandProfile = (id) => (dispatch) => {
  axios({
    method: 'get',
    url: `/api/brands/${id}/profile`,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('jwtToken'),
    },
  })
    .then((res) => {
      // console.log(res.data.profile);
      dispatch(getUserProfileSuccess(JSON.stringify(res.data.profile)));
    })
    .catch((err) => {
      // console.log(err);
      dispatch(getUserProfileError(err));
    });
};

//set user profile data
export const setProfile = (data) => {
  return {
    type: GET_USER_PROFILE,
    payload: data,
  };
};

// Post Profile
export const postProfile = (id, data) => (dispatch) => {
  axios
    .post(`/api/profile/post/${id}`, data)
    .then((res) => {
      // console.log(res);
      if (res.data.success) {
        //refresh screen
        window.location.reload(false);
      } else {
        //console.log("false")
      }
    })
    .catch((err) => {
      // console.log(err);
    });
};

export const updateCreativeAccount = (id, data) => (dispatch) => {
  let obj = JSON.parse(data);

  let profile = {
    userName: obj.userName,
    firstName: obj.firstName,
    lastName: obj.lastName,
    mobilePhone: obj.mobilePhone,
    bio: obj.bio,
    city: obj.city,
    country: obj.country,
  };

  dispatch(updateUser(id, obj)).then(() =>
    dispatch(updateCreative(id, profile)),
  );
};

export const updateBrandAccount = (id, data) => (dispatch) => {
  let obj = JSON.parse(data);
  dispatch(updateUser(id, obj));
  // dispatch(updateBrand(id, obj));
};
export const updateUser = (id, data) => (dispatch) => {
  let obj = JSON.parse(data);
  newData = {
    role: obj.role,
    socialMediaHandles: obj.socialMediaHandles,
    skills: obj.skills,
    profile: {
      userName: obj.userName,
      firstName: obj.firstName,
      lastName: obj.lastName,
      mobilePhone: obj.mobilePhone,
      bio: obj.bio,
      city: obj.city,
      country: obj.country,
    },
  };

  axios({
    method: 'put',
    url: `/api/users/${id}/profile`,
    data: obj,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('jwtToken'),
    },
  })
    .then((res) => {
      if (res.data.success) {
        // dispatch(getUserProfileSuccess(JSON.stringify(res.data.profile)));
        //refresh screen
        window.location.reload(false);
      } else {
      }
    })
    .catch((err) => {
      dispatch(getUserProfileError(err));
    });
};

export const updateCreative = (id, data) => (dispatch) => {
  let obj = JSON.parse(data);

  let newData = {
    profileVideoUrls: obj.profileVideoUrls,
    role: obj.role,
    skills: obj.skills,
    socialMediaHandles: obj.socialMediaHandles,
    profile: {
      userName: obj.userName,
      firstName: obj.firstName,
      lastName: obj.lastName,
      mobilePhone: obj.mobilePhone,
      website: obj.website,
      profilePictureUrl: obj.profilePictureUrl,
      bio: obj.bio,
      city: obj.city,
      country: obj.country,
    },
  };

  axios({
    method: 'put',
    url: `/api/creatives/${id}/profile/`,
    data: newData,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('jwtToken'),
    },
  })
    .then((res) => {
      if (res.data.success) {
        //refresh screen
        window.location.reload(false);
      } else {
      }
    })
    .catch((err) => {
      dispatch(getUserProfileError(err));
    });
};

export const updateBrand = (id, data) => (dispatch) => {
  let obj = JSON.parse(data);

  let newData = {
    company: obj.company,
    socialMediaHandles: obj.socialMediaHandles,
    profileVideoUrl: obj.profileVideoUrl,
    company: obj.company,
    profile: {
      userName: obj.userName,
      firstName: obj.firstName,
      lastName: obj.lastName,
      mobilePhone: obj.mobilePhone,
      website: obj.website,
      profilePictureUrl: obj.profilePictureUrl,
      bio: obj.bio,
      city: obj.city,
      country: obj.country,
    },
  };

  axios({
    method: 'put',
    url: `/api/brands/${id}/profile/`,
    data: newData,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('jwtToken'),
    },
  })
    .then((res) => {
      if (res.data.success) {
        //refresh screen
        window.location.reload(false);
      } else {
      }
    })
    .catch((err) => {
      dispatch(getUserProfileError(err));
    });
};

//END OF PROFILE STUFF
///////////////////////////////////////////////////

///////////////////////////////////////////////////
//START OF BRIEF STUFF

export const getMyBriefs = (data) => (dispatch) => {
  const token = localStorage.getItem('jwtToken');
  axios({
    method: 'get',
    url: `/api/briefs/my/${data.sub}`,
    headers: { Authorization: 'Bearer ' + localStorage.getItem('jwtToken') },
  })
    .then((res) => {
      dispatch(setBriefData(res.data[0]));
    })
    .catch((err) => {});
};

export const getBriefs = (data) => (dispatch) => {
  axios({
    method: 'get',
    url: `/api/briefs`,
    headers: { Authorization: 'Bearer ' + localStorage.getItem('jwtToken') },
  })
    .then((res) => {
      dispatch(setBriefData(res.data));
    })
    .catch((err) => {});
};

export const postBriefForm = (data) => (dispatch) => {
  axios({
    method: 'post',
    url: '/api/briefs/post',
    data: {
      title: data.title,
      additionalNotes: data.additionalNotes,
      briefDetails: data.briefDetails,
      budget: data.budget,
      currency: data.currency,
      clientDetails: data.clientDetails,
      commissionDateUtc: data.commissionDateUtc,
      creativeDirection: data.creativeDirection,
      client: data.client,
      deliverables: data.deliverables,
      description: data.description,
      dueDateUtc: data.dueDateUtc,
      deliveryDateUtc: data.deliveryDateUtc,
      imageUrl: data.imageUrl,
      location: data.location,
      objectives: data.objectives,
      strapline: data.strapline,
      targetAudience: data.targetAudience,
      title: data.title,
      treatmentRequirements: data.treatmentRequirements,
      type: data.type,
      urls: data.urls,
      user_id: data.user_id,
    },
    headers: { Authorization: 'Bearer ' + localStorage.getItem('jwtToken') },
  })
    .then((res) => {
      dispatch(setBriefData(res.data));
    })
    .catch((err, msg) => {});
};

export const viewBrief = (id) => (dispatch) => {
  dispatch(pending());
  axios({
    method: 'get',
    url: `/api/briefs/${id}`,
    id,
    headers: { Authorization: 'Bearer ' + localStorage.getItem('jwtToken') },
  })
    .then((res) => {
      dispatch(setBriefData(res.data));
    })
    .catch((err) => {});
};

export const viewBriefSubmission = (id) => (dispatch) => {
  dispatch(pending());

  axios
    .get(`/api/briefs/${id}/apply`)
    .then((res) => {
      dispatch(setBriefData(res.data));
    })
    .catch((err) => {});
};

export const setBriefData = (data) => {
  return {
    type: GET_MY_BRIEF,
    payload: data,
    pending: false,
  };
};

export const getTreatments = (data) => (dispatch) => {
  axios
    .get('/api/treatments/', data)
    .then((res) => {
      dispatch(setTreatments(res.data.data));
    })
    .catch((err) => {});
};

export const deleteBrief = (data) => (dispatch) => {
  axios
    .post('/api/delete/', data)
    .then((res) => {
      dispatch(setBriefData(res.data.data));
    })
    .catch((err) => {});
};

//END OF BRIEF STUFF
///////////////////////////////////////////////////

///////////////////////////////////////////////////
//ADDITIONAL ACTIONS
export const getMyContacts = (id) => (dispatch) => {
  axios
    .get(`/api/profile/${id}`)
    .then((res) => {
      dispatch(setProfile(res.data));
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const getMesagesFrom = (id) => (dispatch) => {
  axios
    .get(`/api/profile/${id}`)
    .then((res) => {
      dispatch(setProfile(res.data));
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Upload
export const upload = (data) => (dispatch) => {
  axios
    .post('/upload', data)
    .then((res) => {
      if (res.data.success) {
        if (res.data.function === 'update treatments') {
          dispatch(setTreatments(res.data.data));
        } else if (data.function === 'update profile') {
          dispatch(setProfile(res.data.data));
          window.location.reload(false);
        }
      }
    })
    .catch((err) => {});
};

export const setTreatments = (decoded) => {
  return {
    type: GET_TREATMENTS,
    payload: decoded,
  };
};

export const changeView = (data) => (dispatch) => {};

export const search = (data) => (dispatch) => {
  axios
    .post('/api/briefs/search', data)
    .then((res) => {
      dispatch(setBriefData(res.data.data));
    })
    .catch((err) => {});
};
