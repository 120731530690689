import React from 'react';
import Markdown from 'react-markdown';

const markdown = `**You have just completed a brief submission!**
\n
**Your brief has been submitted for review by the BlueAvo Team.**
\n
**You will be notified once your brief goes live!** `;

function BriefSubmissionNotification() {
  return <Markdown source={markdown} />;
}

export default BriefSubmissionNotification;
