import styled from 'styled-components';
import Dots from '../../../../assets/images/dots_small.png';

export const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  /* background: ${(props) => props.theme.colors.gray2}; */
  /* background-image: url(${Dots});
  background-repeat: repeat; */
  padding-bottom: 100px;
  padding-top: 0;
`;

export const FlexRow = styled.div`
  display: flex;
  justify-content: center;
  order: 3;
  @media (min-width: 992px) {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 80%;
    order: 1;
  }
`;

export const Button = styled.button`
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
  width: 146px;
  height: 37px;
  font-style: normal;
  float: left;
  margin-top: 10px;
  margin-bottom: 50px;
  border: none;
  border-radius: 10px;
  background-color: #ffaa00;
  color: white;
  mix-blend-mode: normal;

  &:hover {
    background: ${(props) => props.theme.colors.carrot};
  }
  @media (min-width: 992px) {
    margin-top: 15px;
    margin-bottom: 15px;
    float: right;
  }
`;

export const Card = styled.div`
  background: #fff;
  border-radius: 10px;
  border: none;
  min-height: 559px;
  min-width: 80%;
  max-width: 10px;
  height: auto;
  margin-bottom: 35px;
  order: 2;
  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    order: 1;
  }
`;

export const BriefSummaryCard = styled(Card)`
  margin-top: 25px;
  min-width: 80%;
  order: 1;

  @media (min-width: 992px) {
    margin-top: 0;
    min-height: 343px;
    height: auto;
  }
`;

export const Heading = styled.h1`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  color: ${(props) => props.theme.colors.gray1};
  /* padding-top: 15px; */

  text-align: center;

  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    font-size: 18px;
    line-height: 18x;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    font-size: 24px;
    line-height: 24px;
    margin-left: 75px;
    /* padding-top: 34px; */
    text-align: left;
  }
`;

export const Line = styled.div`
  margin: auto;
  max-width: 90%;
  opacity: 0.5;
  height: 0px;
  border: 1px solid rgba(3, 57, 73, 0.25);
  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    width: 90%;
  }
`;
export const Grid = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    display: grid;
    grid-template-columns: 1fr 2fr 2fr;
  }
`;

export const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  justify-content: center;

  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    justify-content: space-between;
  }
`;

export const MiddleColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  justify-content: center;
  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    justify-content: left;
    margin: 20px auto;
  }
`;

export const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  justify-content: center;
  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    justify-content: left;
    margin: 20px auto;
  }
`;

export const Title = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  margin-bottom: 5px;
  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    text-align: left;
    font-size: 16px;
    line-height: 16px;
  }
`;

export const OrangeValue = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  color: #ffaa00;
  text-align: center;
  margin-top: 5px;
  @media (min-width: 992px) {
    text-align: left;
    margin: 0;
  }
`;

export const TextBody = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 176%;
  color: ${(props) => props.theme.colors.gray1};
  /* margin: 10px 75px 50px 75px; */
  padding-left: 15px;
  padding-right: 15px;

  @media (min-width: 992px) {
    font-size: 18px;
    line-height: 169%;
    padding-right: 75px;
    padding-left: 75px;
  }
`;

export const ImageDiv = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  border-radius: 10px;
  img {
    width: 210px;
    height: 198px;
    object-fit: cover;
    margin: auto;
    background-position: center;
  }
  @media (min-width: 992px) {
    margin-top: 30px;
    margin-left: 55px;
    border-radius: 20px;
  }
`;

export const Image = styled.img`
  border-radius: 10px;
`;

export const UL = styled.ul`
  display: block;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 176%;
  color: ${(props) => props.theme.colors.gray1};
  margin: auto;
  padding-left: 15px;
  padding-right: 15px;
  list-style: none;

  @media (min-width: 992px) {
    font-size: 18px;
    line-height: 169%;
    padding-left: 75px;
    padding-right: 75px;
  }
`;
