/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { IconContext } from 'react-icons';
import {
  FaUserTie,
  FaCalendarDay,
  FaGlobeAfrica,
  FaMoneyBillWave,
  FaTag,
} from 'react-icons/fa';
import { userSelector } from '../../../User/UserSlice';
import * as S from './BrandBriefCards.style';
import MyModal from '../../../../components/Modals/Terms&Conditions/MyModal';
import Loader from '../../../../components/Loader/Loader';

export default function BrandBriefCards() {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState({});
  const [acceptModal, setAcceptModal] = useState(null);

  const { user } = useSelector(userSelector);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [briefID, setBriefID] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const result = await axios({
          method: 'get',
          url: `/api/briefs/my/${user.sub}`,
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
          },
        });
        setData(result.data);
        setIsLoading(false);
      } catch (error) {
        setIsError(true);
        console.log(error);
      }
    };
    fetchData();
  }, []);

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const viewBrief = (id) => {
    setBriefID(id);
    setAcceptModal(0);
    setModalIsOpen(true);
  };
  const createBrief = () => {
    setAcceptModal(3);
    setModalIsOpen(true);
  };

  if (isLoading) {
    return <Loader />;
  }
  return (
    <S.Container>
      {modalIsOpen && (
        <MyModal
          show={modalIsOpen}
          modalClosed={closeModal}
          title="BlueAvo Terms and Conditions"
          content={0}
          briefId={briefID}
          link={acceptModal}
          linkTo={2}
          button={0}
          button2={1}
          showAcceptBtn={0}
          showCancelBtn={0}
        />
      )}
      <S.FlexRow>
        <S.CreateBriefCard>
          <S.BriefButtonGroup>
            <S.RoundButton onClick={createBrief}>+</S.RoundButton>
            <S.CreateBriefButton onClick={createBrief}>
              Create brief
            </S.CreateBriefButton>
          </S.BriefButtonGroup>
        </S.CreateBriefCard>
      </S.FlexRow>
      <S.GridRow>
        {data &&
          data.map((_data, index) => (
            <S.Card key={index}>
              <S.Status>{_data.stage}</S.Status>
              <S.CompanyNameTitle>
                <IconContext.Provider
                  value={{
                    color: '#033949',
                    title: 'Client',
                    size: '1.3em',
                    style: {
                      marginRight: '15px',
                      paddingLeft: '6px',
                      verticalAlign: 'bottom',
                    },
                  }}
                >
                  <FaUserTie />
                </IconContext.Provider>
                {_data.client.length > 16
                  ? `${_data.client.substring(0, 16)} ...`
                  : _data.client}
              </S.CompanyNameTitle>
              <S.BriefBody>
                <S.Subtitles>
                  <IconContext.Provider
                    value={{
                      color: '#033949',
                      title: 'Brief type',
                      size: '1.3em',
                      style: {
                        marginRight: '15px',
                        paddingLeft: '6px',
                        verticalAlign: 'bottom',
                      },
                    }}
                  >
                    <FaTag />
                  </IconContext.Provider>
                  {_data.title}
                </S.Subtitles>
                <S.Subtitles>
                  <IconContext.Provider
                    value={{
                      color: '#033949',
                      title: 'Treatment deadline',
                      size: '1.3em',
                      style: {
                        marginRight: '15px',
                        paddingLeft: '6px',
                        verticalAlign: 'middle',
                      },
                    }}
                  >
                    <FaCalendarDay />
                  </IconContext.Provider>
                  {new Date(_data.dueDateUtc).toDateString()}
                </S.Subtitles>
                <S.Subtitles>
                  <IconContext.Provider
                    value={{
                      color: '#033949',
                      title: 'Production location',
                      size: '1.5em',
                      style: {
                        marginRight: '15px',
                        paddingLeft: '6px',
                        verticalAlign: 'middle',
                      },
                    }}
                  >
                    <FaGlobeAfrica />
                  </IconContext.Provider>
                  {_data.location}
                </S.Subtitles>
                <S.Subtitles>
                  <IconContext.Provider
                    value={{
                      color: '#033949',
                      title: 'Budget',
                      size: '1.6em',
                      style: {
                        marginRight: '15px',
                        paddingLeft: '6px',
                        verticalAlign: 'middle',
                      },
                    }}
                  >
                    <FaMoneyBillWave />
                  </IconContext.Provider>
                  <strong>{_data.currency}</strong>{' '}
                  {parseFloat(_data.budget.$numberDecimal)}
                </S.Subtitles>
              </S.BriefBody>
              <S.ImageDiv
                onClick={() => {
                  viewBrief(_data._id);
                }}
              >
                <img src={_data.imageUrl} alt="brief image" />
              </S.ImageDiv>

              <S.BriefCardFooter>
                <S.Button
                  onClick={() => {
                    viewBrief(_data._id);
                  }}
                >
                  View brief
                </S.Button>
              </S.BriefCardFooter>
            </S.Card>
          ))}
      </S.GridRow>
    </S.Container>
  );
}
