import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';

export const theme = {
  fonts: {
    font1: {
      main: "'Metropolis', sans-serif",
    },
  },
  colors: {
    primary: {
      main: '#000',
      light: '#000',
      dark: '#000',
    },
    secondary: {
      main: '#000',
      light: '#000',
      dark: '#000',
    },
    textPrimary: {
      main: '#000',
      light: 'rgba(255, 255, 255, 0.5)',
      dark: '#000',
    },
    textSecondary: {
      main: '#000',
      light: 'rgba(255, 255, 255, 0.5)',
      dark: '#000',
    },
    link: {
      primary: {
        main: '#ff00ff',
      },
      secondary: {
        main: '#ff00ff',
      },
    },
    tomato: '#A50418',
    carrot: '#D64127',
    orange: '#FFAA00',
    kale: '#02686D',
    blueberry: '#033949',
    lemon: '#F0DA0A',
    avo: '#5EC3EA',
    spinach: '#619E72',
    gray1: '#333333',
    gray2: '#ececec',
    gray3: '#828282',
    gray4: '#BDBDBD',
    gray5: '#E0E0E0',
    gray6: '#F2F2F2',
    black: 'rgba(0, 0, 0, 0.9)', // Default black
    white: '#fff', // Default white
    backgrounds: {},
    separators: {
      // Page separators
    },
  },
  animations: {
    short: '130ms',
  },
  breakpoints: {
    xs: '600px',
    s: '768px',
    m: '992px',
    l: '1200px',
    xl: '1480px',
  },
};

const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

Theme.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Theme;
