/* eslint-disable */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import * as S from './BriefCreationForm.style';
import LeftColumn from './LeftColumn';
import { briefFormSelector, setCreativeDirection } from './briefFormSlice';

import { storeToSessionStorage } from '../../../helpers/SessionController';

export const Step7 = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { creativeDirection, keyDetailsProgressIndicator } = useSelector(
    briefFormSelector,
  );
  const { register, required, errors, handleSubmit } = useForm({
    defaultValues: { creativeDirection },
  });

  const onSubmit = (data) => {
    dispatch(setCreativeDirection(data.creativeDirection));
    storeToSessionStorage(data);
    history.push('/briefs/my/new/step8');
  };

  const previous = () => {
    history.push('/briefs/my/new/step6');
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <S.Wrapper>
        <S.Card>
          <S.Heading> Create a brief</S.Heading>
          <S.Line />
          <S.Grid>
            <LeftColumn
              progress={60}
              keyDetailsCompleted={keyDetailsProgressIndicator}
            />
            <S.RightColumn>
              <S.InputLabel htmlFor="creativeDirection">
                Creative Direction
              </S.InputLabel>
              <S.TextArea
                autoFocus
                name="creativeDirection"
                type="textarea"
                row="10"
                cols="50"
                placeholder="What is the creative direction"
                ref={register({ required: true })}
              />
              <S.Error>
                {errors.creativeDirection && 'Field is required!'}
              </S.Error>
            </S.RightColumn>
          </S.Grid>
          <S.Footer>
            <S.Button onClick={previous}>Previous</S.Button>
            <S.NextButton type="submit">Next</S.NextButton>
          </S.Footer>
        </S.Card>
      </S.Wrapper>
    </form>
  );
};
