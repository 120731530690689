import styled from 'styled-components';

export const TopMenuBar = styled.div`
  display: none;

  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    position: fixed;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    background-color: ${(props) => props.theme.colors.blueberry};
    justify-content: space-between;
    width: 100%;
    height: 45px;
    right: 0;
    top: 0;
    z-index: 1;
  }
`;

export const TopRightIcons = styled.div`
  align-self: center;
  padding-right: 52px;
`;

export const I = styled.i`
  color: #033949;
  margin-right: 15px;
  font-size: 24px;
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.colors.carrot};
  }
`;

export const LogoSection = styled.div`
  display: none;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: center;
  margin-bottom: 60px;
  margin-top: 18px;
  text-align: center;

  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
    margin-top: 0;
    top: 0;
  }
`;

export const Logo = styled.img`
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 33px;
  height: 20.6px;
  align-self: flex-start;
`;

export const Group = styled.div`
  display: flex;
  justify-content: space-between;
`;
