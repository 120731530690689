export const GET_ERRORS = 'GET_ERRORS';
export const PENDING = 'PENDING';
export const VERIFY_ACCOUNT = 'VERIFY_ACCOUNT';
export const USER_LOADING = 'USER_LOADING';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const LOGOUT = 'LOGOUT';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const SET_CURRENT_ACCOUNT = 'SET_CURRENT_ACCOUNT';
export const CLEAR_CURRENT_ACCOUNT = 'CLEAR_CURRENT_ACCOUNT';

export const GET_USER_PROFILE = 'GET_USER_PROFILE';
export const LOAD_USER_PROFILE = 'LOAD_USER_PROFILE';
export const RECEIVE_NO_USER_PROFILE = 'RECEIVE_NO_USER_PROFILE';
export const CLEAR_CURRENT_USER_PROFILE = 'CLEAR_CURRENT_USER_PROFILE';
export const GET_USERS_PROFILES = 'GET_USERS_PROFILES';
export const GET_USER_PROFILE_PENDING = 'GET_USER_PROFILE_PENDING';
export const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS';
export const GET_USER_PROFILE_ERROR = 'GET_USER_PROFILE_ERROR';

export const GET_COMPANY_PROFILE = 'GET_COMPANY_PROFILE';
export const LOAD_COMPANY_PROFILE = 'LOAD_COMPANY_PROFILE';
export const RECEIVE_NO_COMPANY_PROFILE = 'RECEIVE_NO_COMPANY_PROFILE';
export const CLEAR_CURRENT_COMPANY_PROFILE = 'CLEAR_CURRENT_COMPANY_PROFILE';
export const GET_COMPANIES_PROFILES = 'GET_COMPANIES_PROFILES';

export const USER_PROFILE_UPDATED = 'USER_PROFILE_UPDATED';

export const CHANGE_VIEW = 'CHANGE_VIEW';
export const SEARCH_BRIEF = 'SEARCH_BRIEF';
export const GET_MY_BRIEF = 'GET_MY_BRIEF';
export const GET_MY_BRIEF_PENDING = 'GET_MY_BRIEF_PENDING';
export const GET_MY_BRIEF_SUCCESS = 'GET_MY_BRIEF_SUCCESS';
export const GET_MY_BRIEF_ERROR = 'GET_MY_BRIEF_ERROR';
export const POST_BRIEF = 'POST_BRIEF';
export const UPLOAD_FILE = 'UPLOAD_FILE';
export const GET_TREATMENTS = 'GET_TREATMENTS';

export const HTTP_STATUS = Object.freeze({
  PENDING: 'PENDING',
  FULFILLED: 'FULFILLED',
  REJECTED: 'REJECTED',
});
