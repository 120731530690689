import styled from 'styled-components';

export const Modal = styled.div`
  position: absolute;
  z-index: 999;
  background-color: white;
  width: 70%;
  border: 1px solid #ccc;
  border-radius: 10px;
  /* box-shadow: 1px 1px 1px black; */
  /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
  padding: 16px;
  left: 15%;
  top: 100px;
  box-sizing: border-box;
  transition: all 0.3s ease-out;
  -webkit-transform: translate3d(0, 0, 0);

  @media (min-width: 600px) {
    .Modal {
      width: 500px;
      left: calc(50% - 250px);
    }
  }
`;

export const Content = styled.div`
  overflow: auto;
  max-height: 300px;
  font-size: 12px;
  background: ${(props) => props.theme.colors.gray6};
  -webkit-overflow-scrolling: touch;
  p {
    text-align: left;
  }
  ul,
  li {
    line-height: 17px;
    text-align: left;
    margin: 0;
    padding: 0;
    margin-bottom: 5px;
  }
`;

export const ButtonAccept = styled.button`
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
  width: 146px;
  height: 37.37px;
  font-style: normal;
  margin-right: 37.5px;
  text-align: center;
  border: none;
  border-radius: 10px;
  background-color: #ffaa00;
  color: white;
  mix-blend-mode: normal;
  letter-spacing: -0.055em;

  &:hover {
    background-color: ${(props) => props.theme.colors.carrot};
  }

  @media (max-width: 900px) {
    margin-right: 0em;
    font-size: 14px;
    width: 120px;
    height: 35px;
  }
  @media (max-width: 600px) {
    margin-right: 0em;
    font-size: 10px;
    width: 70px;
    height: 25px;
  }
`;
export const ButtonCancel = styled(ButtonAccept)`
  margin-left: 37.5px;
`;

export const Title = styled.h1`
  font-size: 36px;
  font-family: Metropolis;
  font-style: normal;
  font-weight: bold;
  text-align: center;
  line-height: 36px;
  color: ${(props) => props.theme.colors.gray1};

  @media (max-width: 900px) {
    font-size: 20px;
  }
  @media (max-width: 600px) {
    font-size: 14px;
    line-height: normal;
  }
`;

export const Paragraph = styled.p`
  font-size: 12px;
  text-align: flex-start;
  font-style: normal;
  font-weight: normal;
  line-height: 33px;
  letter-spacing: 0.005em;
  padding-left: 25px;
  padding-right: 25px;
  color: ${(props) => props.theme.colors.gray1};
  overflow-y: auto;
  justify-content: center;
  @media (min-width: 900px) {
    font-size: 14px;
  }
  @media (min-width: 600px) {
    font-size: 16px;
  }
`;
export const Wrapper = styled.section`
  .box-header {
    height: 85px;
    padding: 0px 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #c7c7c7;
    @media (max-width: 600px) {
      height: 60px;
    }
    @media only screen and (orientation: landscape) and (max-device-height: 400px) {
      height: 40px;
    }
  }
  .box-content {
    @media (max-width: 600px) {
    }
  }
  .box-footer {
    height: 85px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #c7c7c7;
    @media (max-width: 900px) {
      height: 80px;
    }

    @media (max-width: 600px) {
      justify-content: space-around;
      height: 60px;
    }

    @media only screen and (orientation: landscape) and (max-device-height: 400px) {
      height: 40px;
    }
  }
`;
