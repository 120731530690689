/* eslint-disable */
import styled from 'styled-components';

export const Input = styled.input`
  width: 209px;
  height: 35px;
  background: rgba(196, 196, 196, 0.2);
  border: none;
  outline: none;
  border-radius: 10px;
  margin-bottom: 14px;
  padding: 5px;
  @media (min-width: ${(props) =>
      props.theme.breakpoints.s}) and (max-width: ${(props) =>
      props.theme.breakpoints.m}) {
    width: 277px;
    height: 47px;
    right: 58px;
    margin-bottom: 21px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    width: 349px;
    height: 47px;
    margin-bottom: 31px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    width: 349px;
    height: 47px;
    margin-bottom: 31px;
  }
`;
