/* eslint-disable */
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  height: 405px;
  width: 335px;
  border-radius: 10px;
  margin-top: 83px;
  align-self: center;
  background: white;
  box-shadow: -10px 10px 30px rgba(0, 0, 0, 0.3);
  @media (max-width: ${(props) => props.theme.breakpoints.xs}) {
  }
  @media (min-width: ${(props) =>
      props.theme.breakpoints.xs}) and (max-width: ${(props) =>
      props.theme.breakpoints.s}) {
  }
  @media (min-width: ${(props) =>
      props.theme.breakpoints.s}) and (max-width: ${(props) =>
      props.theme.breakpoints.m}) {
    height: 540px;
    width: 444px;
    margin-top: 73px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    align-self: center;
    width: 572px;
    margin-top: 293px;
    height: 550px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    align-self: flex-start;
    width: 572px;
    height: 550px;
    margin-top: 293px;
    /* position: absolute; */
    /* right: calc(50% - 20px); */
  }
`;

export const Input = styled.input`
  width: 209px;
  height: 35px;
  background: rgba(196, 196, 196, 0.2);
  border: none;
  outline: none;
  border-radius: 10px;
  font-size: 14px;
  /* margin-bottom: 14px; */
  padding: 5px;
  @media (min-width: ${(props) =>
      props.theme.breakpoints.s}) and (max-width: ${(props) =>
      props.theme.breakpoints.m}) {
    width: 277px;
    height: 47px;
    right: 58px;
    /* margin-bottom: 21px; */
  }
  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    width: 349px;
    height: 47px;
    /* margin-bottom: 31px; */
  }
  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    width: 349px;
    height: 47px;
    /* margin-bottom: 31px; */
  }
`;

export const TextArea = styled.textarea`
  height: 30px;
  width: 256px;
  height: 93px;
  border: none;
  margin: auto;
  margin: 15px auto 15px auto;
  background: rgba(196, 196, 196, 0.2);
  border-radius: 10px;
  text-align: left;
  font-size: 14px;
  line-height: 14px;
  resize: none;
  padding: 5px;
  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    width: 349px;
    /* margin-left: 32px; */
  }

  ::-webkit-input-placeholder {
    text-align: left;
  }

  :-moz-placeholder {
    text-align: left;
  }

  &:active {
    border: 1.5px solid #ffaa00;
  }

  &:focus {
    border: 3px solid #ffaa00;
  }
`;

export const Error = styled.p`
  color: red;
  font-size: 14px;
  line-height: 14px;
  @media (min-width: ${(props) =>
      props.theme.breakpoints.s}) and (max-width: ${(props) =>
      props.theme.breakpoints.m}) {
    margin-bottom: 15px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    margin-top: 5px;
    margin-bottom: 20px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    margin-bottom: 20px;
  }
`;
