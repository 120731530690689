/* eslint-disable */
import styled from 'styled-components';

export const Label = styled.label`
  margin: ${(props) => props.margin};
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  input:checked + span {
    background-color: rgba(196, 196, 196, 0.2);
    /* background-color: ${(props) => props.theme.colors.kale}; */
  }
  input:focus + span {
    box-shadow: 0 0 1px ${(props) => props.theme.colors.orange};
  }
  input:checked + span:before {
    transform: translateX(20px);
  }
  @media (min-width: ${(props) =>
      props.theme.breakpoints.s}) and (max-width: ${(props) =>
      props.theme.breakpoints.m}) {
    width: 49px;
    height: 27px;
    input:checked + span:before {
      transform: translateX(22px);
    }
  }
`;

export const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(196, 196, 196, 0.2);
  transition: 0.4s;
  border-radius: 34px;
  ::before {
    position: absolute;
    content: '';
    height: 20px; /* 26px */
    width: 20px; /* 26px */
    /* left: 4px; */
    /* bottom: 2px; 4px */
    background: ${(props) => props.theme.colors.orange};
    transition: 0.4s;
    border-radius: 50%;
  }
  @media (min-width: ${(props) =>
      props.theme.breakpoints.s}) and (max-width: ${(props) =>
      props.theme.breakpoints.m}) {
    ::before {
      position: absolute;
      content: '';
      height: 27px; /* 26px */
      width: 27px; /* 26px */
      /* left: 4px; */
      /* bottom: 2px; 4px */
      background: ${(props) => props.theme.colors.orange};
      transition: 0.4s;
      border-radius: 50%;
    }
  }
`;
