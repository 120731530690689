/* eslint-disable */
import React from 'react';
import * as S from './FormInput.style';

const FormInput = ({
  name,
  placeholder,
  label,
  value,
  error,
  type,
  info,
  onChange,
  disabled,
}) => {
  return (
    <S.Input
      type={type}
      placeholder={placeholder}
      label={label}
      name={name}
      value={value}
      onChange={onChange}
      disabled={disabled}
    />
  );
};

export default FormInput;
