/* eslint-disable */
import React from 'react';
import { useForm, useStep } from 'react-hooks-helper';
// import { Form } from '../../../../components/Brief Creation Wizard/BriefCreationWizard.styled';
import * as S from './ProfileCreationForm.style';

import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';

const steps = [{ id: 'step1' }, { id: 'step2' }, { id: 'step3' }];

const defaultData = {
  firstName: '',
  lastName: '',
  userName: '',
  company: '',
  bio: '',
  city: '',
  country: '',
  website: '',
  secondaryEmail: '',
  mobilePhone: '',
  socialMediaHandles: [
    { name: 'facebook', url: '' },
    { name: 'instagram', url: '' },
    { name: 'twitter', url: '' },
    { name: 'youtube', url: '' },
    { name: 'linkedin', url: '' },
    { name: 'vimeo', url: '' },
    { name: 'behance', url: '' },
  ],
  profileVideoUrl: '',
  profilePictureUrl: '',
};

const BriefCreationForm = ({ images }) => {
  const [formData, setForm] = useForm(defaultData);
  const { step, navigation } = useStep({ initialStep: 0, steps });
  const { id } = step;

  const props = { formData, setForm, navigation };

  switch (id) {
    case 'step1':
      return (
        <S.Wrapper>
          <S.Card>
            <S.Heading>Please complete your profile</S.Heading>
            <S.Line />
            <Step1 {...props} />
          </S.Card>
        </S.Wrapper>
      );
    case 'step2':
      return (
        <S.Wrapper>
          <S.Card>
            <S.Heading>Please complete your profile</S.Heading>
            <S.Line />
            <Step2 {...props} />
          </S.Card>
        </S.Wrapper>
      );
    case 'step3':
      return (
        <S.Wrapper>
          <S.Card>
            <S.Heading>Please complete your profile</S.Heading>
            <S.Line />
            <Step3 {...props} />
          </S.Card>
        </S.Wrapper>
      );

    default:
      return null;
  }
};

export default BriefCreationForm;
