/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  progress[value] {
    width: ${(props) => props.width};
    appearance: none;
    border-radius: 10px;
    width: 350px;
    transform: rotate(90deg);
    position: fixed;
    top: 480px;
    top: ${(props) => props.top};
    margin-left: 210px;

    ::-webkit-progress-bar {
      height: 15px;
      border-radius: 100px 100px 100px 100px;
      background-color: ${(props) => props.theme.colors.gray5};
    }

    ::-webkit-progress-value {
      height: 15px;
      border-radius: 100px 100px 100px 100px;
      background-color: ${(props) => props.theme.colors.carrot};
    }
  }
`;

const ProgressBar = (props) => {
  const { value, max, color, width, top } = props;
  return (
    <Container>
      <progress value={value} max={max} color={color} width={width} top={top} />
      {/* <span>{(value / max) * 100}% </span> */}
    </Container>
  );
};

ProgressBar.propTypes = {
  value: PropTypes.number.isRequired,
  max: PropTypes.number,
  color: PropTypes.string,
  width: PropTypes.string,
  top: PropTypes.string,
};

ProgressBar.defaultProps = {
  max: 100,
  color: '#d64127',
  // width: '333px',
  // top: '450px',
};

export default ProgressBar;
