/* eslint-disable */
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import MyModal from '../../../components/Modals/Terms&Conditions/MyModal';
import * as S from './treatmentStyled_temp';
import { Button } from '../briefs/ViewBrief/ViewBrief.styled';
import { userSelector } from '../../User/UserSlice';

export default function Treatments({ setForm, formData }) {
  const history = useHistory();
  const { id } = useParams();
  const { user } = useSelector(userSelector);
  const { register, handleSubmit, required, errors } = useForm();
  const [error, setError] = useState(null);
  const [pending, setPending] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = (e) => {
    setModalIsOpen(true);
  };
  const closeModal = (e) => {
    setModalIsOpen(false);
  };

  const fetchData = async (data) => {
    setPending(true);
    try {
      const result = await axios({
        method: 'post',
        url: `/api/treatments/treatment/${user.sub}`,
        data,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
        },
      });

      setPending(false);
    } catch (error) {
      setError(true);
    }
  };
  const onSubmit = (data) => {
    fetchData(data);
    setModalIsOpen(true);
  };

  const onCancel = () => {
    history.push(`/briefs/${id}`);
  };

  if (!pending) {
    return (
      <div>
        {modalIsOpen && (
          <MyModal
            show={modalIsOpen}
            modalClosed={closeModal}
            title="Congratulations!"
            content={2}
            link={2}
            linkTo={1}
            button={2}
            button2={2}
            showAcceptBtn={0}
          />
        )}
        <S.Wrapper onSubmit={handleSubmit(onSubmit)}>
          <S.MyProfileCard>
            <S.Heading>My Profile</S.Heading>
            <S.Line />
            <S.Grid2>
              <S.Column>
                <S.InputBlock>
                  <S.InputLabel for="name">Name: </S.InputLabel>
                  <S.Input
                    name="name"
                    ref={register({ required: true })}
                    type="text"
                    placeholder="Your name"
                    id="name"
                  />
                  {errors.name && <S.Error>This field is required</S.Error>}
                </S.InputBlock>

                <S.InputBlock>
                  <S.InputLabel for="surname">Surname: </S.InputLabel>
                  <S.Input
                    type="text"
                    ref={register({ required: true })}
                    name="surname"
                    placeholder="Your surname"
                    id="surname"
                  />
                  {errors.surname && <S.Error>This field is required</S.Error>}
                </S.InputBlock>

                <S.InputBlock>
                  <S.InputLabel for="location">Location: </S.InputLabel>
                  <S.Input
                    type="text"
                    ref={register({ required: true })}
                    name="location"
                    placeholder="Country"
                    id="location"
                  />
                  {errors.location && <S.Error>This field is required</S.Error>}
                </S.InputBlock>
              </S.Column>

              <S.Column>
                <S.InputLabel>Short Bio:</S.InputLabel>
                {errors.bio && <S.Error>This field is required</S.Error>}
                <S.TextArea2
                  type="textarea"
                  ref={register({ required: true })}
                  name="bio"
                  placeholder="About yourself"
                />
              </S.Column>
            </S.Grid2>
          </S.MyProfileCard>

          <S.Card>
            <S.Heading>Portfolio and Treatment</S.Heading>
            <S.Line />
            <S.Grid2>
              <S.Column>
                <S.InputLabel for="portfolio">
                  Link to your work and treatment
                </S.InputLabel>
                <S.Subheading>
                  Gdrive, Dropbox, etc link to your portfolio and treament
                  submission
                </S.Subheading>
                <S.Input
                  ref={register({ required: true })}
                  name="portfolio"
                  type="text"
                  placeholder="Link"
                  id="portfolio"
                />
                {errors.portfolio && <S.Error>This field is required</S.Error>}
              </S.Column>
              <S.Column>
                <S.InputLabel>Additional Links (URLs)</S.InputLabel>
                <S.Input
                  placeholder="Link to your work"
                  name="url1"
                  ref={register}
                />
                <S.Input
                  placeholder="Link to your work"
                  name="url2"
                  ref={register}
                />
                <S.Input
                  placeholder="Link to your work"
                  name="url3"
                  ref={register}
                />
              </S.Column>
            </S.Grid2>
          </S.Card>

          <S.Card>
            <S.Heading>Additional Notes</S.Heading>
            <S.Line />
            <S.InputLabel1>
              Why are you positioned for this brief?
            </S.InputLabel1>
            <S.TextArea
              placeholder="A short explanation as to why you are the best creative for this brief."
              ref={register({ required: true })}
              name="notes"
            />
            {errors.notes && <S.Error>This field is required</S.Error>}
          </S.Card>

          <S.FlexRow>
            <Button
              type="submit"
              onClick={onCancel}
              style={{ marginRight: '15px' }}
            >
              Cancel
            </Button>
            <Button type="submit">Apply</Button>
          </S.FlexRow>
        </S.Wrapper>
      </div>
    );
  }
  return <></>;
}
