export function storeToSessionStorage(data) {
  const formData = Object.entries(data);
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of formData) {
    sessionStorage.setItem(key, value);
  }
}

export function readFromSessionStorage() {
  const values = Object.entries(sessionStorage);
  console.log(values);
  return values;
}
