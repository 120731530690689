/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Container } from '../Login/loginStyle';
import { useDispatch, useSelector } from 'react-redux';
import * as S from '../Account Verified/AccountVerified.style';
import styled from 'styled-components';

const InputControl = styled.input`
  width: 208.52px;
  height: 34.88px;
  background: rgba(196, 196, 196, 0.2);
  border: none;
  outline: none;
  border-radius: 10px;
  margin-bottom: 14px;
  text-align: center;

  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    width: 407px;
    height: 53px;
  }
`;

function EmailSentNotification() {
  let history = useHistory();

  const handleChangePasswordClick = () => {
    history.push('/api/auth/forgot-password/change/:email/:token');
  };
  return (
    <S.Card>
      <S.H2>Password recovery email sent.</S.H2>
      <S.Container>
        <S.P>
          Please click the <strong>Reset Password</strong> button or copy and
          paste the link from the email into your browser to reset your account
          password.
          <br />
          <br />
          If you have not received an email within 5 minutes, please do check
          your spam folder.
        </S.P>
      </S.Container>
      <S.Button onClick={handleChangePasswordClick}>Change Password</S.Button>
    </S.Card>
  );
}

export default EmailSentNotification;
