/* eslint-disable */
import React from 'react';
import styled from 'styled-components';

const Select = styled.select`
  height: 30px;
  width: auto;
  border: none;
  /* margin: auto; */
  /* margin: 15px auto 15px auto; */
  background: #ffaa00;
  border-radius: 10px;
  border-style: none;
  text-align: center;
  font-size: 14px;
  line-height: 14px;
  color: white;
  display: inline;
  padding: 0;
  margin: auto;

  option {
    color: black;
    background: white;
    font-weight: small;
    display: flex;
    white-space: pre;
    min-height: 20px;
    padding: 0px 2px 1px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    width: 311px;
    margin-bottom: 15px;
  }

  ::-webkit-input-placeholder {
    text-align: center;
  }

  :-moz-placeholder {
    text-align: center;
  }

  &:active {
    border: 1.5px solid #ffaa00;
  }

  &:focus {
    border: 3px solid #ffaa00;
  }
`;

const roles = [
  ['Select', 'Select your primary role'],
  ['Director', 'Director'],
  ['Animator', 'Animator'],
  ['Editor', 'Editor'],
  ['Writer', 'Writer'],
  ['DOP', 'DOP'],
  ['Camera Operator', 'Camera Operator'],
  ['VFX Artist', 'VFX Artist'],
  ['Producer', 'Producer'],
  ['Drone Pilot', 'Drone Pilot'],
  ['Art Director', 'Art Director'],
  ['Sound', 'Sound'],
  ['Production Company', 'Production Company'],
  ['Film Maker', 'Film Maker'],
];

const RoleDrop = ({ label, ...others }) => (
  <>
    <label>{label}</label>
    <Select {...others}>
      {roles.map(([value, name]) => (
        <option value={value}>{name}</option>
      ))}
    </Select>
  </>
);

export default RoleDrop;
