import styled from 'styled-components';

export const Card = styled.div`
  display: grid;
  grid-gap: 12px;
  align-items: center;

  max-width: 800px;
  height: 350px;
  background-color: #ffffff;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.1);
  margin: auto 25px 15px 25px;
  @media (min-width: 992px) {
    width: 300px;
    margin: auto;
  }
`;
export const CardBody = styled.div`
  padding-left: 15px;
  padding-right: 15px;
`;
export const Status = styled.div`
  width: 60px;
  height: 20px;
  color: white;
  font-size: 12px;
  background: ${(props) => props.theme.colors.gray5};
  z-index: 0;
  text-align: center;
  float: right;
`;

export const Button = styled.a`
  display: flex;
  font-size: 18px;
  font-weight: 500;
  width: 120px;
  height: 35px;
  top: 360px;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 10px;
  background-color: ${(props) => props.theme.colors.kale};
  color: white;
  mix-blend-mode: normal;
  margin-left: auto;
  margin-right: auto;

  &:hover {
    background-color: ${(props) => props.theme.colors.carrot};
  }

  /* @media (max-width: 900px) {
    font-size: 14px;
    width: 120px;
    height: 35px;
  }
  @media (max-width: 600px) {
    font-size: 10px;
    width: 70px;
    height: 25px;
  } */
`;

export const CardTitle = styled.h1`
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  color: #000000;
  text-align: center;
`;
export const Title = styled.h2`
  font-style: normal;
  font-weight: bold;
  text-align: center;
  font-size: 18px;
  line-height: 18px;
  color: #033333;
`;

export const Paragraph = styled.p`
  font-style: normal;
  font-weight: bold;
  line-height: 1.375rem;
  font-size: 1rem;
  margin-top: 15px;
  margin-bottom: 20px;
  height: 50px;
`;

export const CardFooter = styled.div`
  justify-content: center;
  text-align: center;
  margin-bottom: 15px;
`;

export const ImageDiv = styled.div`
  justify-content: center;
  text-align: center;
  img {
    width: 100%;
    height: 130px;
    object-fit: cover;
    margin: auto;
    background-position: center;
  }

  &:hover {
    opacity: 50%;
  }
`;
