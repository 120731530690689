/* eslint-disable */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { HTTP_STATUS } from '../../redux/constant';

export const postLoginForm = createAsyncThunk(
  'login/postLoginForm',
  async () => {
    const { token } = await axios.post('/api/auth/login').then((res) => {});
    return token;
  },
);

const loginSlice = createSlice({
  name: 'login',
  initialState: {
    email: '',
    password: '',
    user: null,
    status: null,
    isAuthenticated: false,
  },
  reducers: {
    login: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      const { email, password } = action.payload;
      state.email = email;
      state.password = password;
      state.user = action.payload;
    },
  },
  extraReducers: {
    [postLoginForm.pending]: (state) => {
      state.status = HTTP_STATUS.PENDING;
    },
    [postLoginForm.fulfilled]: (state, { payload }) => {
      state.user = payload;
      state.status = HTTP_STATUS.FULFILLED;
      state.isAuthenticated = true;
    },
    [postLoginForm.rejected]: (state) => {
      state.status = HTTP_STATUS.REJECTED;
    },
  },
});

export default loginSlice.reducer;
