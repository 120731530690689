/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import Loader from '../../../components/Loader/Loader';
import Card from '../../../components/Card/Card';
import * as S from './dashboard.Style';
import MyModal from '../../../components/Modals/Terms&Conditions/MyModal';

function BrandDashboard() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [blogData, setBlogData] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const closeModal = () => {
    setModalIsOpen(false);
  };

  const openModal = (id) => {
    setModalIsOpen(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsError(false);
      setIsLoading(true);
      try {
        const result = await axios({
          method: 'get',
          url: '/api/blog/',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
          },
        });
        setBlogData(result.data);
        setIsLoading(false);
      } catch (error) {
        setIsError(true);
      }
    };
    fetchData();
  }, []);
  function handleCreateClick() {
    history.push('/briefs/my/new/step1');
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <S.Wrapper>
      {modalIsOpen && (
        <MyModal
          show={modalIsOpen}
          modalClosed={closeModal}
          title="BlueAvo Terms and Conditions"
          content={0}
          link={3}
          linkTo={2}
          button={0}
          button2={1}
          showAcceptBtn={0}
          showCancelBtn={0}
        />
      )}
      <S.Column>
        <S.Container>
          <S.Title>Getting started with BlueAvo</S.Title>
          <S.CreateBriefCard>
            <S.BriefButtonGroup>
              <S.RoundButton onClick={openModal}>+</S.RoundButton>
              <S.CreateBriefButton onClick={openModal}>
                Create brief
              </S.CreateBriefButton>
            </S.BriefButtonGroup>
          </S.CreateBriefCard>
        </S.Container>
        <S.Container>
          <S.AdditionalHelpCard>
            <S.Title>Additional help</S.Title>
            <S.CardGrid>
              <S.Item>
                <S.Span>How to write a great brief</S.Span>
                <S.ButtonA
                  href="https://drive.google.com/file/d/1yL6kfCjNbJjOJDe7RXq37xagdk8H8KEi/view?usp=sharing"
                  target="_blank"
                  rel="noreferrer"
                >
                  Read now
                </S.ButtonA>
              </S.Item>
              <S.Item>
                <S.Span>See how our clients use BlueAvo</S.Span>
                <S.ButtonA
                  href="https://drive.google.com/file/d/1XZOQ2zPDWRLgvXJnCv9KflepaVtsxmlm/view?usp=sharing"
                  target="_blank"
                  rel="noreferrer"
                >
                  Read now
                </S.ButtonA>
              </S.Item>
              <S.Item>
                <S.Span>Post a brief</S.Span>
                <S.Button onClick={openModal}>Create brief</S.Button>
              </S.Item>
              <S.Item>
                <S.Span>Contact our team for assistance</S.Span>
                <S.ButtonA href="/contact-us" target="_blank">
                  Contact us
                </S.ButtonA>
              </S.Item>
            </S.CardGrid>
          </S.AdditionalHelpCard>
        </S.Container>
      </S.Column>

      <S.Column style={{ border: 'none' }}>
        <S.Title>Latest Articles</S.Title>
        <S.Grid>
          {blogData &&
            blogData.map((_data, index) => (
              <Card
                title={_data.title}
                description={_data.description}
                buttonText="Read more"
                image={_data.image}
                url={_data.url}
              />
            ))}
        </S.Grid>
      </S.Column>
    </S.Wrapper>
  );
}

export default BrandDashboard;
