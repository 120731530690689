/* eslint-disable */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as S from './CheckCircle.styled';

export default function CheckCircle({ completed }) {
  if (completed === true) {
    return (
      <S.CompletedCircle>
        <S.CompletedTick>
          <FontAwesomeIcon icon={['fas', 'check']} />
        </S.CompletedTick>
      </S.CompletedCircle>
    );
  }
  return (
    <S.GrayCircle>
      <S.GrayTick>
        <FontAwesomeIcon icon={['fas', 'check']} />
      </S.GrayTick>
    </S.GrayCircle>
  );
}
