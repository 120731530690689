/*eslint-disable*/
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useParams, useHistory } from 'react-router-dom';

import * as S from './ProfileCreationForm.style';
import {
  updateUser,
  updateCreative,
  updateCreativeAccount,
} from '../../../../redux/action';
import LeftColumn from './LeftColumn';

function multiAction(actions) {
  return (dispatch) => actions.map((a) => dispatch(a));
}

const Step3 = ({ setForm, formData, navigation }) => {
  const { socialMediaHandles = [] } = formData;

  const { register, handleSubmit, watch, required, errors } = useForm();
  const dispatch = useDispatch();
  let history = useHistory();

  const { isAuthenticated, user } = useSelector((state) => ({
    isAuthenticated: state.states.isAuthenticated,
    user: state.states.user,
  }));
  const { previous, go } = navigation;

  function onSubmit(formData) {
    dispatch(updateCreative(user.sub, JSON.stringify(formData)));

    history.push(`/creatives/${user.sub}/profile`);
  }

  return (
    <form>
      <S.Grid>
        <LeftColumn progress={100} />
        <S.RightColumn>
          <S.Input
            id="facebookHandle"
            name="facebookHandle"
            type="text"
            placeholder="Facebook"
            value={socialMediaHandles[0].url} // Prop: The company name S.Input data
            onChange={setForm}
            // Prop: Puts data into state
          />
          <S.Input
            id="twitterHandle"
            name="twitterHandle"
            type="text"
            placeholder="Twitter"
            value={socialMediaHandles[2].url} // Prop: The company name S.Input data
            onChange={setForm}
            // Prop: Puts data into state
          />
          <S.Input
            id="instagramHandle"
            name="instagramHandle"
            type="text"
            placeholder="Instagram"
            value={socialMediaHandles[1].url} // Prop: The company name S.Input data
            onChange={setForm}
            // Prop: Puts data into state
          />
          <S.Input
            id="youtubeHandle"
            name="youtubeHandle"
            type="text"
            placeholder="YouTube"
            value={socialMediaHandles[3].url} // Prop: The company name S.Input data
            onChange={setForm}
            // Prop: Puts data into state
          />
          <S.Input
            id="vimeoHandle"
            name="vimeoHandle"
            type="text"
            placeholder="Vimeo"
            value={socialMediaHandles[5].url} // Prop: The company name S.Input data
            onChange={setForm}
            // Prop: Puts data into state
          />
          <S.Input
            id="behanceHandle"
            name="behanceHandle"
            type="text"
            placeholder="Behance"
            value={socialMediaHandles[6].url} // Prop: The company name S.Input data
            onChange={setForm}
            // Prop: Puts data into state
          />
          <S.Input
            id="linkedinHandle"
            name="linkedinHandle"
            type="text"
            placeholder="LinkedIn"
            value={socialMediaHandles[4].url} // Prop: The company name S.Input data
            onChange={setForm}
            // Prop: Puts data into state
          />
        </S.RightColumn>
      </S.Grid>
      <S.Footer>
        <S.Button onClick={previous}>Previous</S.Button>
        <S.NextButton onClick={() => onSubmit(formData)} type="submit">
          Submit
        </S.NextButton>
      </S.Footer>
    </form>
  );
};

export default Step3;
