/* eslint-disable */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as S from './TopMenuBar.styled';
import Burger from '../Nav/Burger';
import logo from '../../assets/images/logo-1.png';
import { userSelector } from '../../features/User/UserSlice';

const TopMenuBar = ({ setRenderView }) => {
  const history = useHistory();

  const { user } = useSelector(userSelector);

  function handleUserClick() {
    if (user.roles[0] === 'Creative') {
      history.push(`/creatives/${user.sub}/profile`);
    } else {
      history.push(`/brands/${user.sub}/profile`);
    }
  }

  function handleLogoClick() {
    history.push('/dashboard');
  }

  return (
    <S.TopMenuBar>
      <S.Group>
        <Burger setRenderView={setRenderView} />
        <S.Logo src={logo} onClick={handleLogoClick} />
      </S.Group>

      <S.TopRightIcons>
        {/* <S.I
          className="fa fa-comments-o"
          onClick={() => setRenderView('chats')}
        />
        <S.I
          className="fa fa-bell-o"
          onClick={() => setRenderView('notifications')}
        /> */}
        {/* <S.I className="fa fa-user-circle-o" onClick={handleUserClick} /> */}
      </S.TopRightIcons>
    </S.TopMenuBar>
  );
};

export default TopMenuBar;
