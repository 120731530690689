/* eslint-disable */
import React, { useState } from 'react';
import styled from 'styled-components';
// Style the Button component
const Button = styled.button`
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  width: 90px;
  height: 30px;
  font-style: normal;
  float: none;
  margin-right: 9px;
  border: none;
  border-radius: 10px;
  background-color: #ffaa00;
  color: white;
  mix-blend-mode: normal;
  /* margin-top: 25px; */

  &:hover {
    background: ${(props) => props.theme.colors.carrot};
  }
`;
const FileUploader = (props) => {
  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef(null);

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = (event) => {
    event.preventDefault();
    hiddenFileInput.current.click();
  };
  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleChange = (event) => {
    event.preventDefault();
    const fileUploaded = event.target.files[0];
    props.handleFile(fileUploaded);
  };
  const [file, setFile] = useState(null);
  const [previewSrc, setPreviewSrc] = useState('');

  return (
    <>
      <Button onClick={handleClick}>Choose file</Button>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: 'none' }}
        name={props.name}
        accept={props.types}
      />
    </>
  );
};
export default FileUploader;
