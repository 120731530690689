/* eslint-disable */
import React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import * as S from './ProfileCreationForm.style';
import LeftColumn from './LeftColumn';

const Step1 = ({ setForm, formData, navigation }) => {
  const { firstName, lastName, userName, city, country } = formData;
  let { id } = useParams();
  const { register, required, errors } = useForm();
  const { next } = navigation;
  let history = useHistory();

  const onCancel = () => {
    history.push(`/brands/${id}/profile`);
  };

  return (
    <form>
      <S.Grid>
        <LeftColumn progress={10} width={'450px'} top={'540px'} />
        <S.RightColumn>
          <S.Input
            id="firstName"
            name="firstName"
            type="text"
            placeholder="First name"
            value={firstName} // Prop: The first name input data
            onChange={setForm} // Prop: Puts data into state
            ref={register({ required: true })}
          />
          {errors.firstName && <S.Error>This field is required</S.Error>}
          {/* <S.InputLabel htmlFor="fistName">First name</S.InputLabel> */}
          <S.Input
            id="lastName"
            name="lastName"
            type="text"
            placeholder="Last name"
            value={lastName} // Prop: The first name input data
            onChange={setForm} // Prop: Puts data into state
          />

          {/* <S.InputLabel htmlFor="lastName">Last name</S.InputLabel> */}

          {/* <S.InputLabel htmlFor="username">Username</S.InputLabel> */}
          <S.Input
            id="userName"
            name="userName"
            type="text"
            placeholder="A unique username"
            value={userName} // Prop: The email input data
            onChange={setForm} // Prop: Puts data into state
          />
          <S.Input
            id="city"
            name="city"
            type="text"
            placeholder="City"
            value={city} // Prop: The email input data
            onChange={setForm} // Prop: Puts data into state
          />
          <S.Input
            id="country"
            name="country"
            type="text"
            placeholder="Country"
            value={country} // Prop: The email input data
            onChange={setForm} // Prop: Puts data into state
          />
        </S.RightColumn>
      </S.Grid>
      <S.Footer>
        <S.Button onClick={onCancel}>Cancel</S.Button>
        <S.NextButton onClick={next}>Next</S.NextButton>
      </S.Footer>
    </form>
  );
};

export default Step1;
