import React from 'react';
import PropTypes from 'prop-types';
import * as S from './FormLabel.style';

function FormLabel({ children }) {
  return <S.Label>{children}</S.Label>;
}

export default FormLabel;

FormLabel.propTypes = {
  children: PropTypes.node.isRequired,
};
