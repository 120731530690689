/* eslint-disable */
import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import DatePicker from 'react-date-picker';
import * as S from './BriefCreationForm.style';
import LeftColumn from './LeftColumn';
import FileUploader from '../../../../components/FileUploader/FileUploader';
import { briefFormSelector, uploadFile } from './briefFormSlice';
import {
  setImage,
  setStrapline,
  setDueDateUtc,
  setCommissionDateUtc,
  setDeliveryDateUtc,
  setDisplayImg,
  setTempFile,
  setFileName,
  setFileType,
  setFileSize,
  setKeyDetailsProgressIndicator,
} from './briefFormSlice.jsx';
import './picker.css';
import { storeToSessionStorage } from '../../../helpers/SessionController';

export const Step2 = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    image,
    strapline,
    dueDateUtc,
    commissionDateUtc,
    deliveryDateUtc,
    displayImg,
    tempFile,
    fileName,
    fileType,
    fileSize,
  } = useSelector(briefFormSelector);

  const {
    register,
    required,
    errors,
    handleSubmit,
    control,
    selected,
  } = useForm({
    defaultValues: {
      image,
      strapline,
      dueDateUtc,
      commissionDateUtc,
      deliveryDateUtc,
      tempFile,
      fileName,
      fileType,
      fileSize,
    },
  });

  const [dueDate, setDueDate] = useState(new Date());
  const [commissionDate, setCommissionDate] = useState(new Date());
  const [deliveryDate, setDeliveryDate] = useState(new Date());
  const [disableButton, setDisableButton] = useState(true);
  const [fileSizeError, setFileSizeError] = useState('');

  // if (dueDate < new Date()) {
  //   console.log("THAT's NOT RIGHT");
  //   console.log(dueDate);
  //   console.log(new Date());
  // }

  // const dueDateRef = useRef(dueDate);
  // dueDateRef.current = dueDate;

  // const commissionDateRef = useRef(commissionDate);
  // commissionDateRef.current = commissionDate;

  // const deliveryDateRef = useRef(deliveryDate);
  // deliveryDateRef.current = deliveryDate;

  const handleUpload = (e) => {
    const file = e.target.files[0];
    setFileSizeError('');
    if (file.size > 2097152) {
      setFileSizeError('File must be smaller than 2MB');
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
    const reader = new FileReader();
    reader.onload = (event) => {
      const fileAsString = event.target.result;
      dispatch(setTempFile(fileAsString));
    };
    reader.readAsDataURL(file);
  };

  const onSubmit = (data) => {
    const Img = URL.createObjectURL(data.image[0]);
    dispatch(setDisplayImg(Img));
    dispatch(setFileName(data.image[0].name));
    dispatch(setFileType(data.image[0].type));
    dispatch(setFileSize(data.image[0].size));
    dispatch(setStrapline(data.strapline));
    dispatch(setDueDateUtc(data.dueDate));
    dispatch(setCommissionDateUtc(data.commissionDate));
    dispatch(setDeliveryDateUtc(data.deliveryDate));
    dispatch(setKeyDetailsProgressIndicator(true));
    storeToSessionStorage(data);
    history.push('/briefs/my/new/step3');
  };

  const previous = () => {
    history.push('/briefs/my/new/step1');
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <S.Wrapper>
        <S.Card>
          <S.Heading> Create a brief</S.Heading>
          <S.Line />
          <S.Grid>
            <LeftColumn progress={20} />
            <S.RightColumn>
              <div className="picker">
                <S.DateFieldLabels htmlFor="duedate">
                  Due Date (dd-mm-y)
                </S.DateFieldLabels>
                <Controller
                  as={DatePicker}
                  control={control}
                  valueName="dueDate"
                  onChange={setDueDate}
                  name="dueDate"
                  disableClock={true}
                  isClockOpen={false}
                  calendarIcon={null}
                  defaultValue={dueDateUtc}
                  format={'dd-MM-y'}
                  selected={selected}
                  setValue={register('dueDate', { required: true })}
                />
                <S.Error>{errors.dueDate && 'Due Date is required!'}</S.Error>
              </div>
              <div className="picker">
                <S.DateFieldLabels htmlFor="commissionDate">
                  Commission Date (dd-mm-y)
                </S.DateFieldLabels>
                <Controller
                  as={DatePicker}
                  control={control}
                  valueName="commissionDate"
                  onChange={setCommissionDate}
                  name="commissionDate"
                  disableClock={true}
                  isClockOpen={false}
                  calendarIcon={null}
                  defaultValue={commissionDateUtc}
                  format={'dd-MM-y'}
                  setValue={register('commissionDate', { required: true })}
                />
                <S.Error>
                  {errors.commissionDate && 'Commssion Date is required!'}
                </S.Error>
              </div>
              <div className="picker">
                <S.DateFieldLabels htmlFor="deliveryDate">
                  Delivery Date (dd-mm-y)
                </S.DateFieldLabels>
                <Controller
                  as={DatePicker}
                  control={control}
                  valueName="deliveryDate"
                  onChange={setDeliveryDate}
                  name="deliveryDate"
                  disableClock={true}
                  isClockOpen={false}
                  calendarIcon={null}
                  defaultValue={deliveryDateUtc}
                  format={'dd-MM-y'}
                  setValue={register('deliveryDate', { required: true })}
                />
                <S.Error>
                  {errors.deliveryDate && 'Delivery Date is required!'}
                </S.Error>
              </div>
              <S.FlexRow
                style={{ flexDirection: 'column', alignItems: 'center' }}
              >
                <S.Subheading>
                  Upload thumbnail image for your brief listing.
                </S.Subheading>

                <input
                  type="file"
                  ref={register}
                  name="image"
                  accept=".jpg, .jpeg, .png"
                  onChange={(e) => handleUpload(e)}
                  style={{ width: '70%' }}
                />
                <S.Error>{errors.image && 'Image is required!'}</S.Error>
                <S.Error>{fileSizeError}</S.Error>
              </S.FlexRow>
              <S.ULsm>
                <S.LIsm>File formats accepted: .jpg, .jpeg or .png</S.LIsm>
                <S.LIsm>Optimal size: 640x320px</S.LIsm>
              </S.ULsm>
              <S.Input
                name="strapline"
                type="text"
                placeholder="Add a strapline/callout. (Max 50 characters)"
                ref={register({
                  required: true,
                  maxLength: 50,
                })}
              />
              <S.Error>
                {' '}
                {errors.strapline?.type === 'required' &&
                  'Strapline is required'}
              </S.Error>
              <S.Error>
                {errors.strapline?.type === 'maxLength' &&
                  'Your input exceed 50 characters'}{' '}
              </S.Error>

              <S.InputFooter>
                <S.ULsm>
                  <div>Examples:</div>
                  <li>
                    Create a TVC and social content in Cape Town, South Africa
                  </li>
                  <li>Make an animated explainer video for TikTok</li>
                  <li>Design the UI for a new e-commerce store</li>
                </S.ULsm>
              </S.InputFooter>
            </S.RightColumn>
          </S.Grid>
          <S.Footer>
            <S.Button onClick={previous}>Previous</S.Button>
            <S.NextButton disabled={disableButton} type="submit">
              Next
            </S.NextButton>
          </S.Footer>
        </S.Card>
      </S.Wrapper>
    </form>
  );
};
