/* eslint-disable */
import styled from 'styled-components';

const remy = (px) => `${px / 16}rem`;

export const Container = styled.div`
  width: 80%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 94px;

  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    padding-top: 160px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    padding-top: 50px;
  }
`;

export const FlexContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 21px 23px;
  justify-items: start;
  margin-top: 53px;
  margin-bottom: 38px;
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 38px;
  justify-content: flex-start;
  @media (min-width: 768px) and (max-width: 992px) {
    margin-top: 53px;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 257px;
  height: 420px;
  background-color: #ffffff;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.1);
  margin: auto;
`;

export const Status = styled.div`
  width: 60px;
  height: 20px;
  color: white;
  font-size: 12px;
  background: ${(props) => props.theme.colors.gray5};
  z-index: 0;
  text-align: center;
  float: right;
`;

export const CreateBriefCard = styled(Card)`
  height: 72px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: auto;
  @media (min-width: 992px) {
    margin-left: 0px;
  }
`;

export const GridRow = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(260px, auto));
  grid-gap: 21px 23px;
  justify-items: center;
  align-items: center;
`;

export const GridColumn = styled.div`
  padding-right: ${remy(15)};
  padding-left: ${remy(15)};
`;

export const BriefBody = styled.div`
  padding-left: 25px;
`;

export const DescriptionBody = styled.div`
  height: 30px;
`;

export const Button = styled.button`
  font-size: 18px;
  font-weight: 500;
  width: 120px;
  height: 35px;
  top: 360px;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  border: none;
  border-radius: 10px;
  background-color: ${(props) => props.theme.colors.kale};
  color: white;
  mix-blend-mode: normal;

  &:hover {
    background-color: ${(props) => props.theme.colors.carrot};
  }

  /* @media (max-width: 900px) {
    font-size: 14px;
    width: 120px;
    height: 35px;
  }
  @media (max-width: 600px) {
    font-size: 10px;
    width: 70px;
    height: 25px;
  } */
`;

export const CreateBriefButton = styled(Button)`
  background-color: ${(props) => props.theme.colors.orange};
  width: 164px;
  height: 37px;
  margin-left: 6px;
`;
export const BriefButtonGroup = styled.div`
  display: flex;
  align-items: center;
  &:hover ${CreateBriefButton} {
    background-color: ${(props) => props.theme.colors.carrot};
  }
`;

export const RoundButton = styled(CreateBriefButton)`
  border-radius: 100px;
  width: 37px;
  height: 37px;
  margin-right: 6px;
  font-style: normal;
  font-weight: 200;
  font-size: 36px;
  line-height: 36px;
  text-align: center;
`;

export const CompanyNameTitle = styled.h1`
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  color: #000000;
  padding-left: 25px;
`;
export const Title = styled.h2`
  font-style: normal;
  font-weight: bold;
  text-align: center;
  font-size: 18px;
  line-height: 18px;
  color: #033333;
`;

export const Subtitles = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  /* margin-top: 15px;
  margin-bottom: 20px; */
`;

export const BriefCardFooter = styled.div`
  justify-content: center;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;
`;

export const ImageDiv = styled.div`
  justify-content: center;
  text-align: center;
  margin-top: 5px;
  img {
    width: 100%;
    height: 130px;
    object-fit: cover;
    margin: auto;
    background-position: center;
  }

  &:hover {
    opacity: 50%;
  }
`;

export const customStyles = {
  content: {
    position: 'absolute',
    top: '40px',
    left: '40px',
    right: '40px',
    bottom: '40px',
    border: '1px solid #ccc',
    background: '#fff',
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    borderRadius: '4px',
    outline: 'none',
    padding: '20px',
  },
  overlay: {
    position: 'absolute',
    top: '100px',
    // bottom: 0,
    right: '5%',
    left: '5%',
    maxWidth: '996px',
    maxHeight: '733px',
    // 'max-height': 'calc(100vh - 210px)',
    // height: 'auto',
    marginTop: 'auto',
    marginBottom: '53px',
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: '#F2F2F2',
    borderRadius: '10px',
    zIndex: '1',
  },
};

export const Col = styled.div`
  float: left;
  width: auto;
  padding: 0 10px;
`;

export const Row = styled.div`
  margin: 0 -5px;

  ::after {
    content: '';
    display: table;
    clear: both;
  }
`;
