/* eslint-disable */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const postData = createAsyncThunk(
  'briefs/postData',
  async (data, thunkAPI) => {
    try {
      const response = await axios({
        method: 'post',
        url: '/api/briefs/post',
        data,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      return thunkAPI.rejectWithValue(response);
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data.message);
    }
  },
);

export const briefFormSlice = createSlice({
  name: 'briefForm',
  initialState: {
    title: sessionStorage.getItem('title') || '',
    client: sessionStorage.getItem('client') || '',
    location: sessionStorage.getItem('location') || '',
    currency: sessionStorage.getItem('currency') || '',
    budget: sessionStorage.getItem('budget') || '',
    commissionDateUtc: '',
    deliveryDateUtc: '',
    dueDateUtc: '',
    image: null,
    strapline: sessionStorage.getItem('strapline') || '',
    type: sessionStorage.getItem('type') || '',
    clientDetails: sessionStorage.getItem('clientDetails') || '',
    briefDetails: sessionStorage.getItem('briefDetails') || '',
    objectives: sessionStorage.getItem('objectives') || '',
    targetAudience: sessionStorage.getItem('targetAudience') || '',
    creativeDirection: sessionStorage.getItem('creativeDirection') || '',
    deliverables: sessionStorage.getItem('deliverables') || [],
    urls: sessionStorage.getItem('urls') || [],
    treatmentRequirements: sessionStorage.getItem('treamentRequirements') || '',
    additionalNotes: sessionStorage.getItem('additionalNotes') || '',
    user_id: '',
    displayImg: null,
    tempFile: null,
    fileName: '',
    fileType: '',
    fileSize: null,
    keyDetailsProgressIndicator: false,
    theBriefProgressIndicator: false,
    additionalNotesProgressIndicator: false,
  },
  reducers: {
    setTitle: (state, action) => {
      state.title = action.payload;
    },
    setClient: (state, action) => {
      state.client = action.payload;
    },
    setLocation: (state, action) => {
      state.location = action.payload;
    },
    setCurrency: (state, action) => {
      state.currency = action.payload;
    },
    setBudget: (state, action) => {
      state.budget = action.payload;
    },
    setCommissionDateUtc: (state, action) => {
      state.commissionDateUtc = action.payload;
    },
    setDeliveryDateUtc: (state, action) => {
      state.deliveryDateUtc = action.payload;
    },
    setDueDateUtc: (state, action) => {
      state.dueDateUtc = action.payload;
    },
    setImage: (state, action) => {
      state.image = action.payload;
    },
    setStrapline: (state, action) => {
      state.strapline = action.payload;
    },
    setType: (state, action) => {
      state.type = action.payload;
    },
    setClientDetails: (state, action) => {
      state.clientDetails = action.payload;
    },
    setBriefDetails: (state, action) => {
      state.briefDetails = action.payload;
    },
    setObjectives: (state, action) => {
      state.objectives = action.payload;
    },
    setTargetAudience: (state, action) => {
      state.targetAudience = action.payload;
    },
    setCreativeDirection: (state, action) => {
      state.creativeDirection = action.payload;
    },
    setDeliverables: (state, action) => {
      state.deliverables = action.payload;
    },
    setURLS: (state, action) => {
      state.urls = action.payload;
    },
    setTreatmentRequirements: (state, action) => {
      state.treatmentRequirements = action.payload;
    },
    setAdditionalNotes: (state, action) => {
      state.additionalNotes = action.payload;
    },
    setUserID: (state, action) => {
      state.user_id = action.payload;
    },
    setDisplayImg: (state, action) => {
      state.displayImg = action.payload;
    },
    setTempFile: (state, action) => {
      state.tempFile = action.payload;
    },
    setFileName: (state, action) => {
      state.fileName = action.payload;
    },
    setFileType: (state, action) => {
      state.fileType = action.payload;
    },
    setFileSize: (state, action) => {
      state.fileSize = action.payload;
    },
    setKeyDetailsProgressIndicator: (state, action) => {
      state.keyDetailsProgressIndicator = action.payload;
    },
    setTheBriefProgressIndicator: (state, action) => {
      state.theBriefProgressIndicator = action.payload;
    },
    setAdditionalNotesProgressIndicator: (state, action) => {
      state.additionalNotesProgressIndicator = action.payload;
    },
  },
});

// export const briefFormReducer = briefFormSlice.reducer;

export const {
  setTitle,
  setClient,
  setLocation,
  setCurrency,
  setBudget,
  setCommissionDateUtc,
  setDeliveryDateUtc,
  setDueDateUtc,
  setImage,
  setStrapline,
  setType,
  setClientDetails,
  setBriefDetails,
  setObjectives,
  setTargetAudience,
  setCreativeDirection,
  setDeliverables,
  setURLS,
  setTreatmentRequirements,
  setAdditionalNotes,
  setUserID,
  setDisplayImg,
  setTempFile,
  setFileName,
  setFileType,
  setFileSize,
  setKeyDetailsProgressIndicator,
  setTheBriefProgressIndicator,
  setAdditionalNotesProgressIndicator,
} = briefFormSlice.actions;

export const uploadFile = (file) => async (dispatch) => {
  const fileData = await uploadFile(file, dispatch);
  dispatch(setImage(fileData));
};

export const briefFormSelector = (state) => state.briefForm;
