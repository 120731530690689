/* eslint-disable */
import React from 'react';
import { useHistory } from 'react-router-dom';
import * as S from '../../Account Verified/AccountVerified.style';

export default function EmailSent() {
  const history = useHistory();

  const handleLoginClick = () => {
    history.push('/resend-confirmation-email');
  };

  return (
    <S.Card>
      <S.H2>Verification email sent.</S.H2>
      <S.Container>
        <S.P>
          Please click the <strong>Confirm Email</strong> button or copy and
          paste the link from the email into your browser to verify your email
          and activate your BlueAvo account. <br />
          <br />
          If you have not received an email within 5 minutes, please do check
          your spam folder. If you have still not received the email, please
          click the button below to resend the verification email.
        </S.P>
      </S.Container>
      <S.Button onClick={handleLoginClick}>Resend Email</S.Button>
    </S.Card>
  );
}
