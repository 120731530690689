/* eslint-disable */
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import AccountReducer from './reducer';
import { briefFormSlice } from '../features/brands/briefs/FormWizard/briefFormSlice';
import loginReducer from '../pages/Login/loginSlice';
import signUpReducer from '../pages/Signup/signupSlice.jsx';
import { userSlice } from '../features/User/UserSlice';
import accountVerifiedReducer from '../pages/Account Verified/accountVerifiedSlice';

const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: false,
});

const store = configureStore({
  reducer: {
    user: userSlice.reducer,
    signUpReducer,
    loginReducer,
    accountVerifiedReducer,
    briefForm: briefFormSlice.reducer,
  },
  middleware: [...customizedMiddleware],
  // middleware: [...getDefaultMiddleware()],
});

export default store;
