/* eslint-disable */
import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import SideMenu from '../SideMenu/SideMenu';
import { userSelector } from '../../features/User/UserSlice';

const StyledBurger = styled.div`
  width: 2rem;
  height: 2rem;
  position: fixed;
  top: 15px;
  left: 20px;
  z-index: 20;
  display: block;

  @media (max-width: 992px) {
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
  }

  @media (min-width: 768px) {
    top: 45px;
    right: 40px;
  }

  @media (min-width: 992px) {
    position: relative;
    top: 0;
  }

  div {
    width: 2rem;
    height: 0.25rem;
    background-color: ${({ open }) => (open ? '#ffaa00' : '#ffaa00')};
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.2s linear;

    @media (min-width: ${(props) => props.theme.breakpoints.m}) {
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }
`;

const Burger = ({ setRenderView }) => {
  const [close, setClose] = useState(true);
  const { isAuthenticated } = useSelector(userSelector);
  return (
    <>
      {isAuthenticated ? (
        <div>
          <StyledBurger open={close} onClick={() => setClose(!close)}>
            <div />
            <div />
            <div />
          </StyledBurger>
          <SideMenu setRenderView={setRenderView} open={close} />
        </div>
      ) : null}
    </>
  );
};

export default Burger;
