/* eslint-disable */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import axios from 'axios';

export const signupUser = createAsyncThunk(
  'signup/signupUser',
  async ({ userData }, thunkAPI) => {
    try {
      const response = await axios({
        method: 'post',
        url: `signup/signupUser`,
        data: userData,
      });
      let responseData = await response.json();
      console.log('responseData', responseData);

      if (response.status === 200) {
        localStorage.setItem('token', responseData.token);
        return { ...responseData, email: userData.email };
      } else {
        return thunkAPI.rejectWithValue(responseData);
      }
    } catch (e) {
      console.log('Error', e.response.responseData);
      return thunkAPI.rejectWithValue(e.response.responseData);
    }
  },
);

const signUpSlice = createSlice({
  name: 'signup',
  initialState: {
    email: '',
    password: '',
    user: null,
    status: null,
    errorMessage: '',
    isFetching: false,
    isSuccess: false,
    isError: false,
    isAuthenticated: false,
  },
  reducers: {
    register: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      const { email, password } = action.payload;
      state.email = email;
      state.password = password;
      state.user = action.payload;
    },
  },
  extraReducers: {
    [signupUser.pending]: (state) => {
      state.isFetching = true;
    },
    [signupUser.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.email = payload.user.email;
      state.password = payload.user.password;
    },
    [signupUser.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
    },
  },
});

export default signUpSlice.reducer;
