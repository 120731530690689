import styled from 'styled-components';

export const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 100px;
  padding-top: 50px;
`;

export const FlexRow = styled.div`
  display: flex;
  justify-content: center;
  order: 3;
  @media (min-width: 992px) {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 80%;
    order: 3;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 10px;
  border: none;
  min-height: auto;
  width: 80%;
  height: auto;
  margin-bottom: 35px;
  margin-left: 48px;
  margin-right: 48px;
  order: 2;
  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    order: 1;
    /* min-height: 559px; */
  }
`;

export const MyProfileCard = styled(Card)`
  /* margin-top: 100px; */
  min-width: 80%;
  order: 1;
  min-height: auto;
  margin-left: 48px;
  margin-right: 48px;
  @media (min-width: 768px) and (max-width: 992px) {
    margin-top: 150px;
  }

  @media (min-width: 992px) {
    margin-top: 100px;
    /* min-height: 343px; */
    height: 274px;
  }
`;

export const Heading = styled.h1`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  color: ${(props) => props.theme.colors.gray1};
  /* padding-top: 15px; */

  text-align: center;

  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    font-size: 18px;
    line-height: 18x;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    font-size: 24px;
    line-height: 24px;
    margin-left: 75px;
    /* padding-top: 34px; */
    text-align: left;
  }
`;

export const Subheading = styled.p`
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  color: #bdbdbd;
  margin: 10px auto;
  text-align: center;

  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    /* margin-left: 52px; */
    font-size: 14px;
    line-height: 14px;
  }
`;

export const Field = styled.h4`
  font-size: 14px;
  line-height: 14px;
  font-weight: bold;
  color: #333333;
  margin: 5px auto;

  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    font-size: 18px;
    line-height: 18px;
    margin: 5px 0;
  }
`;

export const FieldValue = styled.p`
  font-size: 14px;
  line-height: 14px;
  font-weight: normal;
  margin: 5px auto;
  display: inline;

  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    font-size: 18px;
    line-height: 18px;
  }
`;

export const Line = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 90%;
  opacity: 0.5;
  height: 0px;
  border: 1px solid rgba(3, 57, 73, 0.25);
  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    /* margin: auto; */
    width: 90%;
  }
`;
export const Grid = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    margin-top: 30px;
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 16px;
    margin-bottom: 30px;
  }
`;

export const Grid2 = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 992px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

export const Grid3 = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    margin-top: 30px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: auto;
    margin-bottom: 30px;
  }
`;

export const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    /* justify-content: space-between; */
    justify-content: left;
    margin-left: 75px;
  }
`;

export const MiddleColumn = styled.div`
  display: none;
  margin: auto;
  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

export const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  justify-content: center;
  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    justify-content: left;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: auto auto;
`;

export const Title = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  margin-bottom: 5px;
  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    text-align: left;
    font-size: 18px;
    line-height: 18px;
  }
`;

export const Button = styled.button`
  font-size: 14px;
  line-height: 14px;
  width: 91px;
  height: 27px;
  font-style: normal;
  float: left;
  margin-right: 9px;
  border: none;
  border-radius: 10px;
  background-color: #ffaa00;
  color: white;
  mix-blend-mode: normal;
  margin-bottom: 25px;

  &:hover {
    background: ${(props) => props.theme.colors.carrot};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    font-size: 16px;
    line-height: 16px;
    width: 121px;
    height: 36;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    font-size: 18px;
    line-height: 18px;
    width: 146px;
    height: 37px;
  }
`;

export const TextArea = styled.textarea`
  min-height: 200px;
  height: auto;
  width: 80%;
  border: none;
  margin: 0 auto 15px auto;
  /* margin: auto; */
  background: rgba(196, 196, 196, 0.2);
  border-radius: 10px;
  text-align: left;
  font-size: 14px;
  resize: none;
  padding: 10px;
  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
    height: 150px;
    /* font-size: 18px; */
    line-height: 18px;
  }

  ::-webkit-input-placeholder {
    text-align: left;
  }

  :-moz-placeholder {
    text-align: left;
  }

  &:active {
    border: 1.5px solid #ffaa00;
  }

  &:focus {
    border: 3px solid #ffaa00;
  }
`;

export const TextArea2 = styled(TextArea)`
  min-height: 150px;
  width: 100%;
  min-width: 250px;
  margin: 0 auto 15px auto;

  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    width: 455px;
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
    height: 150px;
    /* font-size: 18px; */
    line-height: 18px;
  }
`;
export const InputBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;
export const InputLabel = styled.label`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  text-align: center;

  color: #333333;

  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    margin: 10px auto 10px 0;
    font-size: 18px;
    line-height: 18px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    text-align: left;
  }
`;

export const InputLabel1 = styled(InputLabel)`
  margin: 10px 15px;
  @media (min-width: 992px) {
    margin-left: 75px;
  }
`;
export const Input = styled.input`
  height: 30px;
  min-width: 200px;
  border: none;
  margin: auto;
  margin: 15px auto 15px auto;
  background: rgba(196, 196, 196, 0.2);
  border-radius: 10px;
  text-align: left;
  font-size: 14px;
  line-height: 14px;
  padding: 5px;
  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    width: 256px;

    ::-webkit-input-placeholder {
      text-align: center;
    }

    :-moz-placeholder {
      text-align: center;
    }

    &:active {
      border: 1.5px solid #ffaa00;
    }

    &:focus {
      border: 3px solid #ffaa00;
    }
  }
`;

export const Error = styled.p`
  color: red;
  font-size: 10px;
  line-height: 10px;
  margin: 0;
`;

export const Checkbox = styled.input`
  border: 0.545455px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 2.72727px;
  display: inline-block;
`;

export const TextBody = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 176%;
  color: ${(props) => props.theme.colors.gray1};
  /* width: 300px; */
  padding-left: 15px;
  padding-right: 15px;
  max-width: 600px;
  text-align: justify;

  @media (min-width: 992px) {
    font-size: 18px;
    line-height: 169%;
    min-width: 500px;
    width: 80%;
    padding-left: 0;
    padding-right: 0;

    /* margin: 10px 75px 50px 75px; */
    margin: -1px 0;
  }
`;
