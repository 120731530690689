/* eslint-disable */
import React, { useState } from 'react';

import * as S from './ProfileCreationForm.style';
import LeftColumn from './LeftColumn';
import RoleDrop from './RoleDrop';
import TodoForm from '../../../brands/briefs/Todo/TodoForm';
import TodoList from '../../../brands/briefs/Todo/TodoList';

const Step2 = ({ setForm, formData, navigation }) => {
  const { bio, role, skills = [], website } = formData;
  const { previous, next } = navigation;
  const [todos, setTodos] = useState([]);
  return (
    <form>
      <S.Grid>
        <LeftColumn progress={20} />
        <S.RightColumn>
          <S.TextArea
            id="bio"
            name="bio"
            type="textarea"
            row="10"
            cols="50"
            placeholder="Add a short bio to your profile"
            value={bio} // Prop: The company name S.Input data
            onChange={setForm} // Prop: Puts data into state
          />

          <RoleDrop
            id="role"
            name="role"
            type="text"
            value={role}
            onChange={setForm}
          />

          {/* <S.InputLabel htmlFor="email">Email</S.InputLabel> */}
          {/* <div style={{ margin: 'auto' }}>
            <TodoForm
              placeholder={'Add skill'}
              saveTodo={(todoText) => {
                const trimmedText = todoText.trim();
                if (trimmedText.length > 0) {
                  setTodos([...todos, trimmedText]);
                  skills.push(...todos);
                }
              }}
            />
            <TodoList
              todos={todos}
              onChange={setForm}
              value={skills}
              deleteTodo={(todoIndex) => {
                const newTodos = todos.filter(
                  (_, index) => index !== todoIndex,
                );

                setTodos(newTodos);
              }}
            />
          </div> */}
          <S.Input
            id="skills"
            name="skills"
            type="text"
            placeholder="Skills"
            value={skills} // Prop: The email S.Input data
            onChange={setForm} // Prop: Puts data into state
          />

          {/* <S.InputLabel htmlFor="website">Website</S.InputLabel> */}
          <S.Input
            id="website"
            name="website"
            type="text"
            placeholder="Website"
            value={website} // Prop: The website input data
            onChange={setForm} // Prop: Puts data into state
          />
        </S.RightColumn>
      </S.Grid>
      <S.Footer>
        <S.Button onClick={previous}>Previous</S.Button>
        <S.NextButton onClick={next}>Next</S.NextButton>
      </S.Footer>
    </form>
  );
};

export default Step2;
