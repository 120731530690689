/* eslint-disable */
import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as S from './MenuBar.style';
import LogoImg from '../../assets/images/logo1.svg';
import Burger from '../Nav/Burger';
import { userSelector } from '../../features/User/UserSlice';

function MenuBar({ containerHeight, setOpen }) {
  const { isAuthenticated } = useSelector(userSelector);
  return (
    <S.Wrapper>
      {isAuthenticated ? (
        <Link to="/dashboard">
          <S.Logo src={LogoImg} alt="Blue Avo Logo" />
        </Link>
      ) : (
        <Link to="/">
          <S.Logo src={LogoImg} alt="Blue Avo Logo" />
        </Link>
      )}

      <Burger />
    </S.Wrapper>
  );
}

export default MenuBar;
