/*eslint-disable*/
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IconContext } from 'react-icons';
import { FaClipboardList, FaHome, FaSignOutAlt } from 'react-icons/fa';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { userSelector, logoutUser } from '../../features/User/UserSlice';
import logo from '../../assets/images/logo-1.png';
import * as S from '../user/DashboardStyled';

const SideMenu = ({ open, setRenderView, props }) => {
  let data;
  let dispatch = useDispatch();
  let history = useHistory();

  const { isAuthenticated, user } = useSelector(userSelector);
  const { id } = useParams();

  let temp;

  if (user && user !== null) {
    if (user.roles === undefined) {
      temp = user;
    } else {
      temp = user.roles[0];
    }
  }

  data = { type: temp };

  function handleNavigationClick(slug) {
    if (slug === 'dashboard') {
      history.push('/dashboard');
    }
    if (slug === 'briefCards') {
      if (temp === 'Brand') {
        history.push('/briefs/my');
      } else {
        history.push('/briefs');
      }
    } else if (slug === 'account') {
      if (temp === 'Brand') {
        history.push(`/brands/${user.sub}/profile`);
      } else {
        history.push(`/creatives/${user.sub}/profile`);
      }
    } else if (slug === 'profileForm') {
      if (temp === 'Brand') {
        history.push(`/brand/${user.sub}/profile/form`);
      } else {
        history.push(`/creatives/${user.sub}/profile/form`);
      }
    }
  }

  if (data.type === 'Brand') {
    return (
      <>
        <S.LeftMenu open={open}>
          <S.LogoSection>
            <S.Logo src={logo} />
          </S.LogoSection>
          <S.SideNav>
            <S.List>
              <S.ListItem>
                <S.MenuTitle onClick={() => handleNavigationClick('dashboard')}>
                  <IconContext.Provider
                    value={{
                      title: 'Dashboard',
                      size: '1.2em',
                      style: {
                        marginRight: '10px',
                        verticalAlign: 'top',
                      },
                    }}
                  >
                    <FaHome />
                  </IconContext.Provider>
                  Dashboard
                </S.MenuTitle>
              </S.ListItem>
            </S.List>
            <S.List>
              <S.ListItem>
                <S.MenuTitle
                  onClick={() => handleNavigationClick('briefCards')}
                >
                  <IconContext.Provider
                    value={{
                      title: 'My Briefs',
                      size: '1.2em',
                      style: {
                        marginRight: '10px',
                        verticalAlign: 'top',
                      },
                    }}
                  >
                    <FaClipboardList />
                  </IconContext.Provider>
                  My Briefs
                </S.MenuTitle>
              </S.ListItem>
            </S.List>
            {/* <S.List>
              <S.ListItem>
                <S.MenuTitle
                  onClick={() => handleNavigationClick('treatments')}
                >
                  <i
                    className="fa fa-envelope-o"
                    style={{ marginRight: '15px' }}
                  ></i>
                  Treatments
                </S.MenuTitle>
              </S.ListItem>
            </S.List> */}

            {/* <S.List>
              <S.ListItem>
                <S.MenuTitle>
                  <i
                    className="fa fa-dollar"
                    style={{
                      marginRight: '15px',
                      paddingLeft: '6px',
                    }}
                  ></i>
                  Payments
                </S.MenuTitle>
              </S.ListItem>
            </S.List> */}

            {/* <S.List>
              <S.ListItem>
                <S.MenuTitle onClick={() => handleNavigationClick('account')}>
                  <i
                    className="fa fa-user-circle"
                    style={{ marginRight: '15px' }}
                  ></i>
                  My Account
                </S.MenuTitle>
              </S.ListItem>
            </S.List> */}
            <hr style={{ color: 'white' }} />
            <S.List>
              <S.ListItem
                onClick={() => {
                  localStorage.clear();
                  window.location.href = 'https://app.blueavo.com/';
                }}
              >
                <S.MenuTitle>
                  <IconContext.Provider
                    value={{
                      title: 'Logout',
                      size: '1.2em',
                      style: {
                        marginRight: '10px',
                        verticalAlign: 'top',
                      },
                    }}
                  >
                    <FaSignOutAlt />
                  </IconContext.Provider>
                  Logout
                </S.MenuTitle>
              </S.ListItem>
            </S.List>
          </S.SideNav>
        </S.LeftMenu>
      </>
    );
  } else if (data.type === 'Creative') {
    return (
      <>
        <S.LeftMenu open={open}>
          <S.LogoSection>
            <S.Logo src={logo} />
          </S.LogoSection>
          <S.SideNav>
            <S.List>
              <S.ListItem>
                <S.MenuTitle onClick={() => handleNavigationClick('dashboard')}>
                  <IconContext.Provider
                    value={{
                      title: 'Dashboard',
                      size: '1.2em',
                      style: {
                        marginRight: '10px',
                        verticalAlign: 'top',
                      },
                    }}
                  >
                    <FaHome />
                  </IconContext.Provider>
                  Dashboard
                </S.MenuTitle>
              </S.ListItem>
            </S.List>
            <S.List>
              <S.ListItem>
                <S.MenuTitle
                  onClick={() => handleNavigationClick('briefCards')}
                >
                  <IconContext.Provider
                    value={{
                      title: 'Briefs',
                      size: '1.2em',
                      style: {
                        marginRight: '10px',
                        verticalAlign: 'top',
                      },
                    }}
                  >
                    <FaClipboardList />
                  </IconContext.Provider>
                  Briefs
                </S.MenuTitle>
              </S.ListItem>
            </S.List>
            {/* <S.List>
              <S.ListItem>
                <S.MenuTitle
                  onClick={() => handleNavigationClick('treatments')}
                >
                  <i
                    className="fa fa-envelope-o"
                    style={{ marginRight: '15px' }}
                  ></i>
                  Treatments
                </S.MenuTitle>
              </S.ListItem>
            </S.List> */}

            {/* <S.List>
              <S.ListItem>
                <S.MenuTitle>
                  <i
                    className="fa fa-dollar"
                    style={{
                      marginRight: '15px',
                      paddingLeft: '6px',
                    }}
                  ></i>
                  Payments
                </S.MenuTitle>
              </S.ListItem>
            </S.List> */}

            {/* <S.List>
              <S.ListItem>
                <S.MenuTitle onClick={() => handleNavigationClick('account')}>
                  <i
                    className="fa fa-user-circle"
                    style={{ marginRight: '15px' }}
                  ></i>
                  My Account
                </S.MenuTitle>
              </S.ListItem>
            </S.List> */}
            <hr style={{ color: 'white' }} />
            <S.List>
              <S.ListItem
                onClick={() => {
                  localStorage.clear();
                  logoutUser();
                  window.location.href = 'https://app.blueavo.com/';
                }}
              >
                <S.MenuTitle>
                  <IconContext.Provider
                    value={{
                      title: 'Logout',
                      size: '1.2em',
                      style: {
                        marginRight: '10px',
                        verticalAlign: 'top',
                      },
                    }}
                  >
                    <FaSignOutAlt />
                  </IconContext.Provider>
                  Logout
                </S.MenuTitle>
              </S.ListItem>
            </S.List>
          </S.SideNav>
        </S.LeftMenu>
      </>
    );
  } else {
    return <></>;
  }
};

export default SideMenu;
