/* eslint-disable */
import React from 'react';
import * as S from './ProfileCreationForm.style';
import CheckCircle from '../../../../components/CheckCircle/CheckCircle';
import ProgressBar from '../../../../components/ProgressBar/ProgressBar';

function LeftColumn(props) {
  return (
    <S.LeftColumn>
      <S.UL>
        <S.LI>
          <S.ListGroup>
            <CheckCircle />
            First name
          </S.ListGroup>
        </S.LI>
        <S.LI>
          <S.ListGroup>
            <CheckCircle />
            Last name
          </S.ListGroup>
        </S.LI>
        <S.LI>
          <S.ListGroup>
            <CheckCircle />
            Username
          </S.ListGroup>
        </S.LI>
        <S.LI>
          <S.ListGroup>
            <CheckCircle />
            Company name
          </S.ListGroup>
        </S.LI>
        <S.LI>
          <S.ListGroup>
            <CheckCircle />
            Role
          </S.ListGroup>
        </S.LI>
        <S.LI>
          <S.ListGroup>
            <CheckCircle />
            Website
          </S.ListGroup>
        </S.LI>
        <S.LI>
          <S.ListGroup>
            <CheckCircle />
            Social Media
          </S.ListGroup>
        </S.LI>
      </S.UL>
      <ProgressBar
        value={props.progress}
        width={props.length}
        top={props.gap}
      />
    </S.LeftColumn>
  );
}

export default LeftColumn;
