/* eslint-disable */
import styled from 'styled-components';

export const Container = styled.form`
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  height: 405px;
  width: 335px;
  border-radius: 10px;
  margin-top: 83px;
  align-self: center;
  background: white;
  box-shadow: -10px 10px 30px rgba(0, 0, 0, 0.3);
  @media (max-width: ${(props) => props.theme.breakpoints.xs}) {
  }
  @media (min-width: ${(props) =>
      props.theme.breakpoints.xs}) and (max-width: ${(props) =>
      props.theme.breakpoints.s}) {
  }
  @media (min-width: ${(props) =>
      props.theme.breakpoints.s}) and (max-width: ${(props) =>
      props.theme.breakpoints.m}) {
    height: 540px;
    width: 444px;
    margin-top: 73px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    align-self: center;
    width: 572px;
    margin-top: 293px;
    position: absolute;
    height: 550px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    /* align-self: flex-start; */
    width: 572px;
    height: 550px;
    margin-top: 293px;
    position: absolute;
    /* right: calc(50% - 20px); */
  }
`;

export const Heading = styled.h5`
  color: ${(props) => props.theme.colors.carrot};
  margin-top: 21px;
  margin-bottom: 18px;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -1px;
  @media (min-width: ${(props) =>
      props.theme.breakpoints.s}) and (max-width: ${(props) =>
      props.theme.breakpoints.m}) {
    margin-top: 28px;
    margin-bottom: 31px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    font-size: 36px;
    line-height: 36px;
    font-weight: 600px;
    text-align: left;
  }
`;

export const InputLabel = styled.label`
  color: ${(props) => props.theme.colors.blueberry};
  font-size: 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  letter-spacing: -0.055em;
  margin-bottom: 2px;
`;

export const Error = styled.p`
  margin: 0;
  font-size: 10px;
  color: ${(props) => props.theme.colors.tomato};
`;

export const InputControl = styled.input`
  width: 208.52px;
  height: 34.88px;
  background: rgba(196, 196, 196, 0.2);
  border: none;
  outline: none;
  border-radius: 10px;
  margin-bottom: 14px;

  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    width: 407px;
    height: 53px;
  }
`;

export const TermsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 210px;
  height: 22px;
  font-weight: 500;
  font-size: 10px;
  line-height: 10px;
  letter-spacing: -0.055em;
  margin: 0;
  margin-top: 5px;
  padding: 0;
  @media (min-width: ${(props) =>
      props.theme.breakpoints.s}) and (max-width: ${(props) =>
      props.theme.breakpoints.m}) {
    margin-top: 0;
    width: 280px;
  }
`;

export const TermsContainerLogin = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 210px;
  height: 22px;
  font-weight: 500;
  font-size: 10px;
  line-height: 10px;
  letter-spacing: -0.055em;
  margin: 0;
  margin-top: 5px;
  padding: 0;
  justify-content: center;
  text-align: center;
  @media (min-width: ${(props) =>
      props.theme.breakpoints.s}) and (max-width: ${(props) =>
      props.theme.breakpoints.m}) {
    margin-top: 0;
    width: 280px;
  }
`;

export const TermsControlContainer = styled.div``;

export const Checkbox = styled.input`
  width: 22px;
  height: 22px;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
  margin-top: -4px;
  @media (min-width: ${(props) =>
      props.theme.breakpoints.s}) and (max-width: ${(props) =>
      props.theme.breakpoints.m}) {
    width: 30px;
    height: 30px;
    margin-top: -4px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
  }
`;

export const Terms = styled.div`
  margin-left: 12px;
  color: ${(props) => props.theme.colors.gray1};
  @media (min-width: ${(props) =>
      props.theme.breakpoints.s}) and (max-width: ${(props) =>
      props.theme.breakpoints.m}) {
    margin-left: 19px;
  }
  /* @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    font-size: 18px;
    line-height: 18px;
    font-weight: 500px;
  } */
`;

export const UserContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.055em;
  color: ${(props) => props.theme.colors.blueberry};
  @media (min-width: ${(props) =>
      props.theme.breakpoints.s}) and (max-width: ${(props) =>
      props.theme.breakpoints.m}) {
    margin-top: 40px;
  }
`;

export const Button = styled.button`
  background: ${(props) => props.theme.colors.orange};
  color: white;
  border-radius: 10px;
  border: 0;
  outline: 0;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  letter-spacing: -0.055em;
  padding: 5px 20px;

  &:hover {
    background-color: ${(props) => props.theme.colors.carrot};
  }
  @media (min-width: ${(props) =>
      props.theme.breakpoints.s}) and (max-width: ${(props) =>
      props.theme.breakpoints.l}) {
    margin-top: 5px;
    padding: 10px 37px;
    font-size: 16px;
    line-height: 16px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    width: 152px;
    height: 48px;
    font-size: 18px;
    line-height: 18px;
  }
`;

export const Input = styled.input`
  width: 209px;
  height: 35px;
  background: rgba(196, 196, 196, 0.2);
  border: none;
  outline: none;
  border-radius: 10px;
  margin-bottom: 14px;
  padding: 5px;
  @media (min-width: ${(props) =>
      props.theme.breakpoints.s}) and (max-width: ${(props) =>
      props.theme.breakpoints.m}) {
    width: 277px;
    height: 47px;
    right: 58px;
    margin-bottom: 21px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    width: 349px;
    height: 47px;
    margin-bottom: 31px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    width: 349px;
    height: 47px;
    margin-bottom: 31px;
  }
`;
