/* eslint-disable */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, useStep } from 'react-hooks-helper';
import * as S from './ProfileCreationForm.style';

import ProgressBar from '../../../../components/ProgressBar';

import { getCreativeProfile } from '../../../../redux/action';

import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
// import BriefReview from '../Brief Review/BriefReview';

const steps = [{ id: 'step1' }, { id: 'step2' }, { id: 'step3' }];

const BriefCreationForm = ({ images }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCreativeProfile(user.sub));
  }, []);
  const {
    hasProfile,
    pending = true,
    error,
    userProfileData,
    isAuthenticated,
    user,
  } = useSelector((state) => ({
    isAuthenticated: state.states.isAuthenticated,
    user: state.states.user,
    pending: state.states.pending,
    error: state.states.error,
    hasProfile: state.states.hasProfile,
    userProfileData: state.states.userProfileData,
  }));
  let profile = JSON.parse(userProfileData);

  const defaultData = {
    firstName: 'Emmanuel',
    lastName: 'Tshiteta',
    userName: 'isaac.tshiteta22',
    role: 'Director',
    city: 'Cape Town',
    country: 'South Africa',
    website: 'tshiteta.com',
    bio:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla quis lorem ut libero malesuada feugiat. Donec rutrum congue leo eget malesuada. Vivamus suscipit tortor eget felis porttitor volutpat.',
    mobilePhone: '+27722399538',
    socialMediaHandles: [
      { name: 'facebook', url: 'isaac.tshiteta' },
      { name: 'instagram', url: 'isaac.instagram' },
      { name: 'twitter', url: 'isaac.twitter' },
      { name: 'youtube', url: 'isaac.youtube' },
      { name: 'linkedin', url: 'isaac.linkedin' },
      { name: 'vimeo', url: 'isaac.vimeo' },
      { name: 'behance', url: 'isaac.behance' },
    ],
    skills: ['Editing', 'Color Grading'],
    profileVideoUrls: ['1', '2'],
    profilePictureUrl: 'google.com',
  };

  const [formData, setForm] = useForm(defaultData);
  const { step, navigation } = useStep({ initialStep: 0, steps });
  const { id } = step;

  const props = { formData, setForm, navigation };

  switch (id) {
    case 'step1':
      return (
        <S.Wrapper>
          <S.Card>
            <S.Heading>Please complete your profile</S.Heading>
            <S.Line />
            <Step1 {...props} />
          </S.Card>
        </S.Wrapper>
      );
    case 'step2':
      return (
        <S.Wrapper>
          <S.Card>
            <S.Heading>Please complete your profile</S.Heading>
            <S.Line />
            <Step2 {...props} />
          </S.Card>
        </S.Wrapper>
      );
    case 'step3':
      return (
        <S.Wrapper>
          <S.Card>
            <S.Heading>Please complete your profile</S.Heading>
            <S.Line />
            <Step3 {...props} />
          </S.Card>
        </S.Wrapper>
      );

    default:
      return null;
  }
};

export default BriefCreationForm;
