/* eslint-disable */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HTTP_STATUS } from '../../redux/constant';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

export const getAccountVerified = createAsyncThunk(
  'accountVerified/getAccountVerified',
  async (email, token) => {
    const { data } = await axios.get(
      `/api/auth/confirmation/${email}/${token}`,
    );
    return data;
  },
);

const accountVerifiedSlice = createSlice({
  name: 'accountVerified',
  initialState: {
    status: null,
  },
  reducers: {
    register: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      const { email, password } = action.payload;
      state.email = email;
      state.password = password;
      state.user = action.payload;
    },
  },
  extraReducers: {
    [getAccountVerified.pending]: (state, action) => {
      state.status = HTTP_STATUS.PENDING;
    },
    [getAccountVerified.fulfilled]: (state, { payload }) => {
      state.user = payload;
      state.status = HTTP_STATUS.FULFILLED;
    },
    [getAccountVerified.rejected]: (state, action) => {
      state.status = HTTP_STATUS.REJECTED;
    },
  },
});

export default accountVerifiedSlice.reducer;
