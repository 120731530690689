/*eslint-disable*/
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBriefs } from '../../../../redux/action';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { IconContext } from 'react-icons';
import {
  FaUserTie,
  FaCalendarDay,
  FaGlobeAfrica,
  FaMoneyBillWave,
  FaTag,
} from 'react-icons/fa';
import * as S from './briefCards.style';
import MyModal from '../../../../components/Modals/Terms&Conditions/MyModal';
import { userSelector } from '../../../../features/User/UserSlice';
import Loader from '../../../../components/Loader/Loader';

export default function CreativeBriefCards() {
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { isAuthenticated, user } = useSelector(userSelector);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [briefID, setBriefID] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const result = await axios({
          method: 'get',
          url: `/api/briefs/`,
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwtToken'),
          },
        });
        setData(result.data);
        setIsLoading(false);
      } catch (error) {
        setIsError(true);
      }
    };
    fetchData();
  }, []);

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const openModal = (id) => {
    setBriefID(id);
    setModalIsOpen(true);
  };

  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <S.Container>
        <S.GridRow>
          {modalIsOpen && (
            <MyModal
              show={modalIsOpen}
              modalClosed={closeModal}
              title="BlueAvo Terms and Conditions"
              briefId={briefID}
              content={0}
              link={0}
              linkTo={1}
              button={0}
              button2={1}
              showAcceptBtn={0}
              showCancelBtn={0}
            />
          )}

          {data &&
            data.reverse().map((_data, index) => {
              return (
                <S.Card key={index}>
                  <S.CompanyNameTitle>
                    <IconContext.Provider
                      value={{
                        color: '#033949',
                        title: 'Client',
                        size: '1.3em',
                        style: {
                          marginRight: '15px',
                          paddingLeft: '6px',
                          verticalAlign: 'bottom',
                        },
                      }}
                    >
                      <FaUserTie />
                    </IconContext.Provider>
                    {_data.client.length > 16
                      ? _data.client.substring(0, 16) + ' ...'
                      : _data.client}
                  </S.CompanyNameTitle>
                  <S.BriefBody>
                    <S.Subtitles>
                      <IconContext.Provider
                        value={{
                          color: '#033949',
                          title: 'Brief type',
                          size: '1.3em',
                          style: {
                            marginRight: '15px',
                            paddingLeft: '6px',
                            verticalAlign: 'bottom',
                          },
                        }}
                      >
                        <FaTag />
                      </IconContext.Provider>
                      {_data.type}{' '}
                    </S.Subtitles>
                    <S.Subtitles>
                      <IconContext.Provider
                        value={{
                          color: '#033949',
                          title: 'Treatment deadline',
                          size: '1.3em',
                          style: {
                            marginRight: '15px',
                            paddingLeft: '6px',
                            verticalAlign: 'middle',
                          },
                        }}
                      >
                        <FaCalendarDay />
                      </IconContext.Provider>
                      {new Date(_data.dueDateUtc).toDateString()}
                    </S.Subtitles>
                    <S.Subtitles>
                      <IconContext.Provider
                        value={{
                          color: '#033949',
                          title: 'Production location',
                          size: '1.5em',
                          style: {
                            marginRight: '15px',
                            paddingLeft: '6px',
                            verticalAlign: 'middle',
                          },
                        }}
                      >
                        <FaGlobeAfrica />
                      </IconContext.Provider>
                      {_data.location}
                    </S.Subtitles>
                    <S.Subtitles>
                      <IconContext.Provider
                        value={{
                          color: '#033949',
                          title: 'Budget',
                          size: '1.6em',
                          style: {
                            marginRight: '15px',
                            paddingLeft: '6px',
                            verticalAlign: 'middle',
                          },
                        }}
                      >
                        <FaMoneyBillWave />
                      </IconContext.Provider>
                      <strong>{_data.currency} </strong>
                      {parseFloat(_data.budget.$numberDecimal)}
                    </S.Subtitles>
                  </S.BriefBody>
                  {_data.stage !== 'Open' ? (
                    <S.ImageDiv>
                      <img src={_data.imageUrl} alt="brief image" />
                    </S.ImageDiv>
                  ) : (
                    <S.ImageDiv
                      onClick={() => {
                        openModal(_data._id);
                      }}
                    >
                      <img src={_data.imageUrl} alt="brief image" />
                    </S.ImageDiv>
                  )}

                  <S.BriefCardFooter>
                    {_data.stage === 'Open' ? (
                      <S.Button
                        onClick={() => {
                          openModal(_data._id);
                        }}
                      >
                        View brief
                      </S.Button>
                    ) : (
                      <S.DeactivatedButton>
                        Brief {_data.stage}
                      </S.DeactivatedButton>
                    )}
                  </S.BriefCardFooter>
                </S.Card>
              );
            })}
        </S.GridRow>
      </S.Container>
    );
  }
}
