/* eslint-disable */
import styled from 'styled-components';

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 297px;
  width: 334px;
  border-radius: 10px;
  margin-top: 83px;
  align-self: center;
  background: white;
  box-shadow: -10px 10px 30px rgba(0, 0, 0, 0.3);
  @media (max-width: ${(props) => props.theme.breakpoints.xs}) {
  }
  @media (min-width: ${(props) =>
      props.theme.breakpoints.xs}) and (max-width: ${(props) =>
      props.theme.breakpoints.s}) {
  }
  @media (min-width: ${(props) =>
      props.theme.breakpoints.s}) and (max-width: ${(props) =>
      props.theme.breakpoints.m}) {
    height: 280px;
    width: 444px;
    margin-top: 73px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    align-self: center;
    width: 750px;
    height: 350px;
    margin-top: 293px;
    position: absolute;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    /* align-self: flex-start; */
    width: 750px;
    margin-top: 293px;
    position: absolute;
    /* right: calc(50% - 20px); */
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 25px;
  padding-right: 25px;
`;

export const H2 = styled.h2`
  color: #ffaa00;
  font-size: 20px;
  @media (min-width: 768px) {
    font-size: 24px;
  }

  @media (min-width: 992px) {
    font-size: 32px;
  }
`;

export const H1 = styled.h1`
  font-size: 24px;
  color: #ffaa00;
  margin-bottom: 0;

  @media (min-width: 768px) {
    font-size: 32px;
  }

  @media (min-width: 992px) {
    font-size: 50px;
  }
`;

export const Button = styled.button`
  font-size: 18px;
  width: 250px;
  height: 35px;
  top: 360px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: -0.055em;
  text-align: center;
  border: none;
  border-radius: 10px;
  margin-top: 15px;
  background-color: ${(props) => props.theme.colors.orange};
  color: white;
  mix-blend-mode: normal;

  &:hover {
    background-color: ${(props) => props.theme.colors.carrot};
  }

  @media (max-width: 900px) {
    font-size: 14px;
    width: 180px;
    height: 35px;
  }
  @media (max-width: 600px) {
    font-size: 10px;
    width: 150px;
    height: 25px;
  }
`;

export const P = styled.p`
  font-size: 14px;
  font-weight: normal;
`;
