/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import MyModal from '../../../../components/Modals/Terms&Conditions/MyModal';
import { userSelector } from '../../../User/UserSlice';
import {
  briefFormSelector,
  postData,
  setTitle,
  setClient,
  setLocation,
  setCurrency,
  setBudget,
  setType,
  setUserID,
  setStrapline,
  setClientDetails,
  setBriefDetails,
  setObjectives,
  setTargetAudience,
  setCreativeDirection,
  setDeliverables,
  setURLS,
  setTreatmentRequirements,
  setAdditionalNotes,
  setDueDateUtc,
  setCommissionDateUtc,
  setDeliveryDateUtc,
} from '../FormWizard/briefFormSlice';
import * as S from './BriefReview.styled';

export default function BriefReview() {
  const { sub } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    title,
    client,
    location,
    currency,
    budget,
    image,
    type,
    strapline,
    commissionDateUtc,
    deliveryDateUtc,
    dueDateUtc,
    briefDetails,
    clientDetails,
    objectives,
    targetAudience,
    creativeDirection,
    deliverables,
    urls,
    treatmentRequirements,
    additionalNotes,
    user_id,
    displayImg,
    tempFile,
    fileName,
    fileType,
    fileSize,
  } = useSelector(briefFormSelector);

  const [modalIsOpen, setModalIsOpen] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [response, setResponse] = useState({});
  const [picture, setPicture] = useState(null);

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const { isAuthenticated, user } = useSelector(userSelector);

  dispatch(setUserID(user.sub));
  const formData = useSelector(briefFormSelector);

  const previous = () => {
    history.push('/briefs/my/new/step1');
  };

  useEffect(() => {
    async function url2blob(url) {
      try {
        const data = await fetch(url);
        const blob = await data.blob();
        const metadata = {
          type: fileType,
          size: fileSize,
        };
        const file = new File([blob], fileName, metadata);
        setPicture(file);
      } catch (err) {
        console.error(err.name, err.message);
      }
    }
    url2blob(tempFile);
  }, []);

  function onSubmit(data) {
    const omit = (prop, { [prop]: _, ...rest }) => rest;
    const objWithoutDisplayImg = omit('displayImg', data);

    const formData = new FormData();
    formData.append('title', title);
    formData.append('client', client);
    formData.append('location', location);
    formData.append('currency', currency);
    formData.append('budget', budget);
    formData.append('type', type);
    formData.append('strapline', strapline);
    formData.append('commissionDateUtc', commissionDateUtc);
    formData.append('deliveryDateUtc', deliveryDateUtc);
    formData.append('dueDateUtc', dueDateUtc);
    formData.append('briefDetails', briefDetails);
    formData.append('clientDetails', clientDetails);
    formData.append('objectives', objectives);
    formData.append('targetAudience', targetAudience);
    formData.append('creativeDirection', creativeDirection);
    formData.append('deliverables', JSON.stringify(deliverables));
    formData.append('urls', JSON.stringify(urls));
    formData.append('treatmentRequirements', treatmentRequirements);
    formData.append('additionalNotes', additionalNotes);
    formData.append('user_id', user_id);
    formData.append('image', picture);
    dispatch(postData(formData));
    sessionStorage.clear();
    dispatch(setType(''));
    dispatch(setTitle(''));
    dispatch(setClient(''));
    dispatch(setCurrency(''));
    dispatch(setBudget(''));
    dispatch(setLocation(''));
    dispatch(setStrapline(''));
    dispatch(setClientDetails(''));
    dispatch(setBriefDetails(''));
    dispatch(setObjectives(''));
    dispatch(setTargetAudience(''));
    dispatch(setCreativeDirection(''));
    dispatch(setDeliverables([]));
    dispatch(setURLS([]));
    dispatch(setTreatmentRequirements(''));
    dispatch(setAdditionalNotes(''));
    dispatch(setDueDateUtc(''));
    dispatch(setCommissionDateUtc(''));
    dispatch(setDeliveryDateUtc(''));
    setModalIsOpen(true);
  }

  return (
    <div>
      {modalIsOpen && (
        <MyModal
          show={modalIsOpen}
          modalClosed={closeModal}
          title="Congratulations!"
          content={1}
          link={1}
          button={2}
          button2={2}
          showAcceptBtn={0}
          showCancelBtn={1}
        />
      )}
      <S.Wrapper enctype="multipart/form-data">
        <S.FlexRow>
          <S.Button onClick={previous}>Edit</S.Button>
          <S.Button onClick={() => onSubmit(formData)} type="button">
            Submit
          </S.Button>
        </S.FlexRow>
        <S.BriefSummaryCard>
          <S.Heading>Brief Summary</S.Heading>
          <S.Line />
          <S.Grid>
            <S.LeftColumn>
              <S.ImageDiv>
                <S.Image src={displayImg} alt="brief image" />
              </S.ImageDiv>
            </S.LeftColumn>
            <S.MiddleColumn>
              <S.Title>Brief Type</S.Title>
              <S.OrangeValue>{`${type}`}</S.OrangeValue>
              <S.Title>Title</S.Title>
              <S.OrangeValue>{`${title}`}</S.OrangeValue>
              <S.Title>Client</S.Title>
              <S.OrangeValue>{`${client}`}</S.OrangeValue>
              <S.Title>Budget</S.Title>
              <S.OrangeValue>
                {`${currency}`} {`${budget}`}
              </S.OrangeValue>
              <S.Title>Production Location</S.Title>
              <S.OrangeValue>{`${location}`}</S.OrangeValue>
            </S.MiddleColumn>

            <S.RightColumn>
              <S.Title>Strapline</S.Title>
              <S.OrangeValue>{`${strapline}`}</S.OrangeValue>
              <S.Title>Submissions Due</S.Title>

              <S.OrangeValue>
                {new Date(dueDateUtc).toDateString()}
              </S.OrangeValue>
              <S.Title>Creator Commission Date</S.Title>
              <S.OrangeValue>
                {new Date(commissionDateUtc).toDateString()}
              </S.OrangeValue>
              <S.Title>Final Delivery Date</S.Title>
              <S.OrangeValue>
                {new Date(deliveryDateUtc).toDateString()}
              </S.OrangeValue>
            </S.RightColumn>
          </S.Grid>
        </S.BriefSummaryCard>

        <S.Card>
          <div>
            <S.Heading>The Brief</S.Heading>

            <S.Line />
            <S.TextBody>{`${briefDetails}`}</S.TextBody>
          </div>

          <div>
            <S.Heading>About the Client</S.Heading>
            <S.Line />
            <S.TextBody>{`${clientDetails}`}</S.TextBody>
          </div>

          <div>
            {' '}
            <S.Heading>Goals and Objectives</S.Heading>
            <S.Line />
            <S.TextBody>{`${objectives}`}</S.TextBody>
          </div>

          <div>
            {' '}
            <S.Heading>Target Audience</S.Heading>
            <S.Line />
            <S.TextBody>{`${targetAudience}`}</S.TextBody>
          </div>

          <div>
            {' '}
            <S.Heading>Creative Direction</S.Heading>
            <S.Line />
            <S.TextBody>{`${creativeDirection}`}</S.TextBody>
          </div>

          <div>
            {' '}
            <S.Heading>Deliverables</S.Heading>
            <S.Line />
            {/* <S.TextBody>{' '} */}
            {deliverables.map((item, index) => (
              <div key={index}>
                <S.UL>
                  <li>{item}</li>
                </S.UL>
              </div>
            ))}
            {/* </S.TextBody> */}
          </div>

          <div>
            {' '}
            <S.Heading>Additional References</S.Heading>
            <S.Line />
            {urls.map((link, index) => (
              <div key={index}>
                <S.UL>
                  <li>{link}</li>
                </S.UL>
              </div>
            ))}
          </div>

          <div>
            {' '}
            <S.Heading>Treatment Requirements</S.Heading>
            <S.Line />
            <S.TextBody>{`${treatmentRequirements}`}</S.TextBody>
          </div>

          <div>
            {' '}
            <S.Heading>Additional Notes</S.Heading>
            <S.Line />
            <S.TextBody>{`${additionalNotes}`}</S.TextBody>
          </div>
        </S.Card>
      </S.Wrapper>
    </div>
  );
}

//
