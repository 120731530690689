import React from 'react';
import styled, { keyframes } from 'styled-components';
import loader from '../../assets/images/loader/loader.svg';

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Rotate = styled.div`
  display: flex;
  justify-content: center;
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);
  background: transparent;
  width: 100px;
  height: 100px;
  position: fixed;
  z-index: 99999;
  top: 30%;
  left: 40%;
  @media (min-width: 768px) {
    left: 44%;
  }

  @media (min-width: 992px) {
    left: 47%;
  }
`;

const Loader = () => (
  <div>
    <Rotate>
      <img src={loader} alt="Loading..." />
    </Rotate>
  </div>
);

export default Loader;
