/* eslint-disable */
import React from 'react';

import * as S from './ProfileCreationForm.style';
import LeftColumn from './LeftColumn';
import ProgressBar from '../../../../components/ProgressBar/ProgressBar';

const Step2 = ({ setForm, formData, navigation }) => {
  const { company, secondaryEmail, mobilePhone, website } = formData;
  const { previous, next } = navigation;
  return (
    <form>
      <S.Grid>
        <LeftColumn progress={66} />
        <S.RightColumn>
          {/* <S.InputLabel htmlFor="companyName">The Brief</S.InputLabel> */}
          <S.Input
            id="company"
            name="company"
            type="text"
            placeholder="Your company name"
            value={company} // Prop: The company name S.Input data
            onChange={setForm}
            // Prop: Puts data into state
          />
          {/* <S.Input
            id="role"
            name="role"
            type="text"
            placeholder="Your main role at the company"
            value={role} // Prop: The company name S.Input data
            onChange={setForm}
            // Prop: Puts data into state
          /> */}
          <S.Input
            id="website"
            name="website"
            type="text"
            placeholder="Your website address"
            value={website} // Prop: The company name S.Input data
            onChange={setForm}
            // Prop: Puts data into state
          />

          <S.Input
            id="mobilePhone"
            name="mobilePhone"
            type="text"
            placeholder="Your mobile number "
            value={mobilePhone} // Prop: The company name S.Input data
            onChange={setForm}
            // Prop: Puts data into state
          />
        </S.RightColumn>
      </S.Grid>
      <S.Footer>
        <S.Button onClick={previous}>Previous</S.Button>
        <S.NextButton onClick={next}>Next</S.NextButton>
      </S.Footer>
    </form>
  );
};

export default Step2;
