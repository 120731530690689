/* eslint-disable */
import React from 'react';
import { Link } from 'react-router-dom';
import * as S from './Access.style';
import MenuBar from '../../components/MenuBar/MenuBar';
import Dots1Img from '../../assets/images/dots1.png';
import Dots2Img from '../../assets/images/dots2.png';
import Dots3Img from '../../assets/images/dots3.png';
import PersonImg from '../../assets/images/girl-logo-1.png';
import Logo from '../../assets/images/logo1.svg';

const MENU_HEIGHT = '62px';

function Access({ children }) {
  return (
    <S.Wrapper>
      <MenuBar containerHeight={MENU_HEIGHT} />
      <S.BackgroundUnderlay heightReduction={MENU_HEIGHT} />
      <S.TextureUnderlay heightReduction={MENU_HEIGHT}>
        <S.DotsPlacement1 src={Dots1Img} />
        <S.DotsPlacement2 src={Dots2Img} />
        <S.DotsPlacement3 src={Dots3Img} />
      </S.TextureUnderlay>
      <S.PersonUnderlay>
        <S.PersonImage src={PersonImg} />
      </S.PersonUnderlay>
      <S.LogoUnderlay>
        <S.LogoContainer>
          <S.Logo href="http://www.blueavo.com">
            <img src={Logo} />{' '}
          </S.Logo>
          <S.Tagline>Africa's Creative Talent Platform</S.Tagline>
        </S.LogoContainer>
      </S.LogoUnderlay>
      <S.Content heightReduction={MENU_HEIGHT}>{children}</S.Content>
    </S.Wrapper>
  );
}

export default Access;
