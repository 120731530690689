/* eslint-disable */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import * as S from './BriefCreationForm.style';
import LeftColumn from './LeftColumn';
import {
  briefFormSelector,
  setTitle,
  setClient,
  setLocation,
  setCurrency,
  setBudget,
  setType,
  setUserID,
  setStrapline,
  setClientDetails,
  setBriefDetails,
  setObjectives,
  setTargetAudience,
  setCreativeDirection,
  setDeliverables,
  setURLS,
  setTreatmentRequirements,
  setAdditionalNotes,
  setDueDateUtc,
  setCommissionDateUtc,
  setDeliveryDateUtc,
} from './briefFormSlice';
import { userSelector } from '../../../User/UserSlice';
import { storeToSessionStorage } from '../../../helpers/SessionController';

export const Step1 = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { type, title, client, currency, budget, location } = useSelector(
    briefFormSelector,
  );
  const { register, required, errors, handleSubmit } = useForm({
    defaultValues: {
      type,
      title,
      client,
      currency,
      budget,
      location,
    },
  });

  const { user } = useSelector(userSelector);
  setUserID(user.sub);

  const onSubmit = (data) => {
    dispatch(setType(data.type));
    dispatch(setTitle(data.title));
    dispatch(setClient(data.client));
    dispatch(setCurrency(data.currency));
    dispatch(setBudget(data.budget));
    dispatch(setLocation(data.location));
    storeToSessionStorage(data);

    history.push('/briefs/my/new/step2');
  };

  const onCancel = () => {
    sessionStorage.clear();
    dispatch(setType(''));
    dispatch(setTitle(''));
    dispatch(setClient(''));
    dispatch(setCurrency(''));
    dispatch(setBudget(''));
    dispatch(setLocation(''));
    dispatch(setStrapline(''));
    dispatch(setClientDetails(''));
    dispatch(setBriefDetails(''));
    dispatch(setObjectives(''));
    dispatch(setTargetAudience(''));
    dispatch(setCreativeDirection(''));
    dispatch(setDeliverables([]));
    dispatch(setURLS([]));
    dispatch(setTreatmentRequirements(''));
    dispatch(setAdditionalNotes(''));
    dispatch(setDueDateUtc(''));
    dispatch(setCommissionDateUtc(''));
    dispatch(setDeliveryDateUtc(''));

    history.push('/dashboard');
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <S.Wrapper>
        <S.Card>
          <S.Heading> Create a brief</S.Heading>
          <S.Line />
          <S.Grid>
            <LeftColumn progress={10} width="450px" top="540px" />
            <S.RightColumn>
              {/* <BriefTypeDrop autoFocus name="type" type="text" ref={register} /> */}
              <S.FlexColumn>
                <S.SelectType
                  autoFocus
                  name="type"
                  ref={register({ required: true })}
                >
                  <option value="">Select the brief type</option>
                  <option value="Commercial">Commercial</option>
                  <option value="Short Film">Short Film</option>
                  <option value="Graphic Design">Graphic Design</option>
                  <option value="Visual Effects">Visual Effects</option>
                  <option value="Documentary">Documentary</option>
                  <option value="Other">Other</option>
                </S.SelectType>
                <S.Error>{errors.type && 'Brief type is required!'}</S.Error>
              </S.FlexColumn>
              <S.Input
                id="title"
                name="title"
                type="text"
                placeholder="Brief Title"
                ref={register({ required: true })}
              />
              <S.Error>{errors.title && 'Title is required!'}</S.Error>
              {/* <S.InputLabel htmlFor="fistName">First name</S.InputLabel> */}
              <S.Input
                name="client"
                type="text"
                placeholder="Client"
                ref={register({ required: true })}
              />
              <S.Error>{errors.client && 'Client name is required!'}</S.Error>
              {/* <S.InputLabel htmlFor="lastName">Last name</S.InputLabel> */}
              <S.InputGroup>
                <S.Select
                  name="currency"
                  type="text"
                  ref={register({ required: true })}
                >
                  <option value="">Currency</option>
                  <option value="ZAR">ZAR</option>
                  <option value="USD">USD</option>
                  <option value="GBP">GBP</option>
                  <option value="EUR">EUR</option>
                </S.Select>

                <S.Input2
                  name="budget"
                  type="number"
                  placeholder="Budget"
                  ref={register({ required: true })}
                />
                <S.Error>{errors.currency && 'Currency is required!'}</S.Error>
                <S.Error>{errors.budget && 'Amount is required!'}</S.Error>
              </S.InputGroup>
              <S.Input
                id="location"
                name="location"
                type="text"
                placeholder="Production location"
                ref={register({ required: true })}
              />
              <S.Error>
                {errors.location && 'Production location is required!'}
              </S.Error>
            </S.RightColumn>
          </S.Grid>
          <S.Footer>
            <S.Button onClick={onCancel}>Cancel</S.Button>
            <S.NextButton type="submit">Next</S.NextButton>
          </S.Footer>
        </S.Card>
      </S.Wrapper>
    </form>
  );
};
