import styled from 'styled-components';
import background from '../../img/home/home1.png';
import bottomBackground from '../../img/home/bottomBackground.png';

export const Wrapper = styled.div`
  height: 100vh;
  width: 100%;
`;

export const BackgroundImage = styled.div`
  height: 1796px;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background-image: url(${background});
  background-position: left center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
`;

export const NavBar = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: center;
  margin: 25px 25px;
  text-align: center;

  @media (min-width: 640px) {
    justify-content: space-between;
  }
`;

export const Logo = styled.img`
  position: relative;
  width: 213px;
  height: 38px;
  top: 0px;
  align-items: center;
  text-align: center;

  @media (min-width: 640px) {
    width: 213px;
    height: 38px;
    left: 0;
    top: 7px;
  }
  @media (min-width: 1280px) {
    left: 90px;
  }
`;

export const AccessButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 5px 53px;
  position: relative;
  @media (min-width: 640px) {
    top: -62px;
    justify-content: flex-end;
  }
`;

export const LoginButton = styled.button`
  /* position: absolute; */
  width: 93px;
  height: 28px;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -0.055em;
  border-radius: 10px;
  border: none;
  color: #033949;
  box-shadow: none;
  background: white;
  margin: auto 6px;
  display: inline-block;
  /* left: 0; */

  &:hover {
    transition: 0.05s linear;
    background: #ffaa00;
  }

  &:active {
    outline: none;
    border: 2px solid #d64127;
    box-sizing: border-box;
  }

  @media (min-width: 640px) {
    width: 114.11px;
    height: 44px;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
  }
`;

export const SignUpButtonTop = styled.button`
  /* position: absolute; */
  width: 93px;
  height: 28px;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -0.055em;
  border: none;
  border-radius: 10px;
  /* text-align: center; */
  color: #033949;
  box-shadow: none;
  background: white;
  margin: auto 6px;
  display: inline-block;
  /* right: 0; */

  &:hover {
    transition: 0.05s linear;
    background: #ffaa00;
  }
  &:active {
    outline: none;
    border: 2px solid #d64127;
    box-sizing: border-box;
  }

  @media (min-width: 640px) {
    width: 114.11px;
    height: 44px;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
  }
`;

export const RequestButton = styled(LoginButton)`
  width: auto;
  background: none;
  border: 1px solid ${(props) => props.theme.colors.orange};
  padding: 1px;

  &:hover {
    background-color: ${(props) => props.theme.colors.orange};
  }
`;

export const AnchorTAG = styled.a`
  width: 93px;
  height: 28px;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -0.055em;
  border: none;
  border-radius: 10px;
  /* text-align: center; */
  color: #033949;
  box-shadow: none;
  background: white;
  margin: auto 6px;
  display: inline-block;
  /* right: 0; */

  &:hover {
    background: #ffaa00;
  }
  &:active {
    outline: none;
    border: 2px solid #d64127;
    box-sizing: border-box;
  }

  @media (min-width: 640px) {
    width: 114.11px;
    height: 44px;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
  }
`;

export const TextureUnderlay = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  /* overflow: hidden; */
`;

export const Heading = styled.h1`
  position: absolute;
  width: 333px;
  height: 132x;
  left: 20px;
  top: 118px;
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 800;
  line-height: 44px;
  font-size: 44px;
  /* text-align: center; */

  /* or 115px */

  letter-spacing: -0.01em;
  text-transform: uppercase;
  z-index: 1;

  color: #d64127;

  @media (min-width: 640px) {
    font-size: 60px;
    line-height: 60px;
  }

  @media (min-width: 768px) {
    font-size: 90px;
    line-height: 90px;
    width: 360px;
    height: 159px;
  }

  @media (min-width: 1024px) {
  }

  @media (min-width: 1280px) {
    width: 1184px;
    font-size: 150px;
    line-height: 115px;
    left: 90px;
    top: 170px;

    /* or 115px */

    letter-spacing: -0.01em;
    text-transform: uppercase;
    z-index: 1;

    color: #d64127;
  }

  /* If device width is less than or equal to 1150px */
`;

export const Break = styled.div`
  @media (max-width: 365px) {
    display: block;
  }
  display: none;
`;

export const Hide = styled.span`
  @media (max-width: 365px) {
    display: none;
  }
`;

export const CloudRight = styled.img`
  position: absolute;
  display: flex;
  width: 215px;
  height: 135px;
  right: 0;
  top: 0;
  mix-blend-mode: screen;
  outline: none !important;
  border: none !important;
  z-index: 0;
  @media (min-width: 640px) {
    width: 583px;
    height: 366px;
  }

  @media (min-width: 768px) {
  }

  @media (min-width: 1024px) {
  }

  @media (min-width: 1280px) {
  }
`;

export const CloudBehind = styled.img`
  position: absolute;
  width: 1055px;
  height: 550.25px;
  left: -472px;
  top: 150.37px;
  mix-blend-mode: screen;
  mix-blend-mode: screen;
  z-index: 0;

  @media (max-width: ${(props) => props.theme.breakpoints.s}) {
    display: none;
  }
`;

export const CloudLeft = styled.img`
  position: absolute;
  width: 281px;
  height: 147px;
  left: -81px;
  top: 1400px;
  mix-blend-mode: screen;
  z-index: 1;

  @media (min-width: 640px) {
  }

  @media (min-width: 768px) {
  }

  @media (min-width: 1024px) {
  }

  @media (min-width: 1250px) {
    width: 752px;
    height: 394px;
    left: -138px;
    top: 1400px;
  }
`;

export const CloudFar = styled.img`
  position: absolute;
  width: 869px;
  height: 343px;
  right: 0;
  top: 600px;
  mix-blend-mode: screen;
  display: none;

  z-index: 0;

  @media (min-width: 1280px) {
    display: block;
  }
`;

export const WomanWithLaptop = styled.img`
  position: absolute;
  display: flex;
  width: 50px;
  height: 106px;
  right: 90px;
  top: 50px;
  z-index: 1;
  outline: none !important;
  border: none !important;

  @media (min-width: 640px) {
    width: 100px;
    height: 200px;
  }
  @media (min-width: 768px) {
    width: 136px;
    height: 286px;
    right: 130px;
    top: 90px;
  }
  @media (min-width: 1024px) {
  }
  @media (min-width: 1280px) {
  }
`;

export const ManWithLaptop = styled.img`
  position: absolute;
  width: 80px;
  height: 95px;
  left: 71px;

  /* width: 249px;
  height: 295px;
  left: 112px; */
  top: 1366px;
  z-index: 1;

  @media (min-width: 640px) {
    width: 160px;
    height: 200px;
    left: 60px;
    top: 1320px;
  }

  @media (min-width: 1250px) {
    width: 249px;
    height: 295px;
    left: 112px;
    top: 1366px;
  }
`;

export const MidSectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  position: relative;

  @media (min-width: 2370px) {
    justify-content: space-evenly;
  }
`;

export const RedCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 279px;
  max-width: 604px;
  height: 80px;
  margin: 50px 77px;
  top: 500px;
  background: #d64127;
  border-radius: 10px;
  /* text-align: center; */

  @media (min-width: 768px) {
    max-width: 550px;
  }

  @media (min-width: 1250px) {
    background: transparent;
    max-width: 1267px;
  }
`;

export const MiddleParagraph = styled.h2`
  position: relative;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 13px;
  text-align: center;
  color: #ffffff;
  margin: auto;
  padding: 10px;
  @media (min-width: 1250px) {
    top: 390px;
    font-size: 32px;
    line-height: 32px;
    text-align: center;
  }
`;

export const OrangeCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 279px;
  max-width: 604px;
  min-height: 80px;
  height: auto;
  margin: 25px 77px;
  top: 500px;
  background: #ffaa00;
  border-radius: 10px;
  padding: 0 23px;

  @media (min-width: 768px) {
    max-width: 550px;
  }

  @media (min-width: 1250px) {
    top: 880px;
    width: 604px;
    height: 155px;
    margin: 25px 25px;
    text-align: center;
  }
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: space-around;
  justify-content: center;
`;

export const CardTitle = styled.h2`
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  color: #d64127;
  @media (min-width: 1250px) {
    font-size: 36px;
    line-height: 36px;
  }
`;

export const CardText = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 13px;
  text-align: center;
  color: white;
  padding: 5px;

  @media (min-width: 1250px) {
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
  }
`;

export const PointOneWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (min-width: 1250px) {
    flex-direction: row;
    align-items: center;
    align-content: center;
  }
`;

export const PointOneGrid = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 1250px) {
    display: grid;
    grid-template-columns: 0.6fr 1.4fr;
    grid-template-rows: 1fr;
    gap: 0px;
  }
`;
export const PointOneEllipse = styled.div`
  /* position: relative; */
  width: 92px;
  height: 92px;
  /* top: 0; */
  margin-top: 200px;
  margin-left: 50px;
  border-radius: 100px;
  background-color: #5ec3ea;
  z-index: 0;
  /* margin: auto; */
  @media (min-width: 640px) {
    width: 114px;
    height: 114px;
  }
`;

export const PointOneIcon = styled.img`
  position: absolute;
  width: 91px;
  height: 103px;
  /* left: 19px; */
  /* top: -20px; */
  text-align: center;
  z-index: 1;

  @media (min-width: 640px) {
    width: 112px;
    height: 100px;
  }
`;

export const PointOneHeading = styled.h2`
  width: auto;
  height: 40px;
  top: 15px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 53px;
  color: #ffaa00;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;

  @media (min-width: 640px) {
    width: auto;
    height: 55.56px;
    top: 5px;
    font-size: 36px;
    line-height: 148.5%;
  }
`;

export const PointOneText = styled.p`
  width: 170px;
  height: 51px;
  top: 65px;
  color: white;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 13px;
  margin-top: 0;
  text-align: center;

  @media (max-width: 321px) {
    width: 200px;
  }

  @media (min-width: 640px) {
    width: 400px;
    font-size: 18px;
    line-height: 24px;
    top: 65px;
  }

  @media (min-width: 1250px) {
    width: 549px;
  }
`;

export const PointTwoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (min-width: 1250px) {
    flex-direction: row;
    align-items: center;
    align-content: center;
  }
`;

export const PointTwoGrid = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 1250px) {
    display: grid;
    grid-template-columns: 0.6fr 1.4fr;
    grid-template-rows: 1fr;
    gap: 0px;
  }
`;
export const PointTwoEllipse = styled.div`
  /* position: relative; */
  width: 92px;
  height: 92px;
  /* top: 0; */
  margin-top: 200px;
  margin-left: 50px;
  border-radius: 100px;
  background-color: #5ec3ea;
  z-index: 0;
  /* margin: auto; */
  @media (min-width: 640px) {
    width: 114px;
    height: 114px;
  }
`;

export const PointTwoIcon = styled.img`
  position: absolute;
  width: 91px;
  height: 103px;
  /* left: 19px; */
  /* top: -20px; */
  text-align: center;
  z-index: 1;

  @media (min-width: 640px) {
    width: 112px;
    height: 100px;
  }
`;

export const PointTwoHeading = styled.h2`
  width: auto;
  height: 40px;
  top: 15px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 53px;
  color: #ffaa00;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;

  @media (min-width: 640px) {
    width: auto;
    height: 55.56px;
    top: 5px;
    font-size: 36px;
    line-height: 148.5%;
  }
`;

export const PointTwoText = styled.p`
  width: 170px;
  height: 51px;
  top: 65px;
  color: white;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 13px;
  margin-top: 0;
  text-align: center;

  @media (max-width: 321px) {
    width: 200px;
  }

  @media (min-width: 640px) {
    width: 400px;
    font-size: 18px;
    line-height: 24px;
    top: 65px;
  }

  @media (min-width: 1250px) {
    width: 549px;
  }
`;

export const PointThreeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (min-width: 1250px) {
    flex-direction: row;
    align-items: center;
    align-content: center;
  }
`;

export const PointThreeGrid = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 1250px) {
    display: grid;
    grid-template-columns: 0.6fr 1.4fr;
    grid-template-rows: 1fr;
    gap: 0px;
  }
`;
export const PointThreeEllipse = styled.div`
  /* position: relative; */
  width: 92px;
  height: 92px;
  /* top: 0; */
  margin-top: 200px;
  margin-left: 50px;
  border-radius: 100px;
  background-color: #5ec3ea;
  z-index: 0;
  /* margin: auto; */
  @media (min-width: 640px) {
    width: 114px;
    height: 114px;
  }
`;

export const PointThreeIcon = styled.img`
  position: absolute;
  width: 91px;
  height: 103px;
  /* left: 19px; */
  /* top: -20px; */
  text-align: center;
  z-index: 1;

  @media (min-width: 640px) {
    width: 112px;
    height: 100px;
  }
`;

export const PointThreeHeading = styled.h2`
  width: auto;
  height: 40px;
  top: 15px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 53px;
  color: #ffaa00;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;

  @media (min-width: 640px) {
    width: auto;
    height: 55.56px;
    top: 5px;
    font-size: 36px;
    line-height: 148.5%;
  }
`;

export const PointThreeText = styled.p`
  width: 170px;
  height: 51px;
  top: 65px;
  color: white;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 13px;
  margin-top: 0;
  text-align: center;

  @media (max-width: 321px) {
    width: 200px;
  }

  @media (min-width: 640px) {
    width: 400px;
    font-size: 18px;
    line-height: 24px;
    top: 65px;
  }

  @media (min-width: 1250px) {
    width: 549px;
  }
`;

export const CreateWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  margin-left: 54px;
  margin-bottom: 25px;
`;

export const CreateEllipse = styled.div`
  position: absolute;
  width: 92px;
  height: 92px;
  top: -150px;
  border-radius: 100px;
  background-color: #02686d;
  z-index: 0;
  @media (min-width: 640px) {
    width: 114px;
    height: 114px;
  }
`;
export const CreateIcon = styled.img`
  position: absolute;
  width: 91px;
  height: 70px;
  margin-left: 25px;
  top: -150px;

  @media (min-width: 640px) {
    width: 112px;
    height: 86px;
  }
`;

export const CreateHeading = styled.h2`
  position: absolute;
  width: auto;
  height: 27px;
  margin-left: 125px;
  top: -160px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 53px;
  color: #ffaa00;

  @media (min-width: 640px) {
    height: 55.56px;
    font-size: 36px;
    top: -185px;
    line-height: 148.5%;
    margin-left: 185px;
  }
`;

export const CreateText = styled.p`
  position: absolute;
  width: 170px;
  height: 75.48px;
  margin-left: 125px;
  top: -115px;
  color: white;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 13px;

  @media (max-width: 321px) {
    width: 100px;
  }

  @media (min-width: 640px) {
    width: 400px;
    font-size: 24px;
    line-height: 24px;
    top: -125px;
    margin-left: 185px;
  }

  @media (min-width: 1250px) {
    width: 549px;
  }
`;

export const BottomBackground = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 600px;
  left: 0px;
  top: 2600px;
  background-image: url(${bottomBackground});
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;

  @media (min-width: 368px) {
    top: 2541px;
  }
  @media (min-width: 640px) {
    top: 2641px;
  }
  @media (min-width: 1250px) {
    top: 2309px;
    height: 830px;
  }
`;

export const BottomSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: auto;
`;

export const GreenCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 279px;
  max-width: 1266px;
  height: 262px;
  margin: auto 77px;
  top: -30px;
  background: #02686d;
  border-radius: 10px;

  @media (min-width: 1250px) {
    width: 100%;
    max-width: 90%;
  }
`;

export const GreenSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-content: space-around;
  background: #033949;
  position: absolute;
  width: 100%;
  height: auto;
  padding-bottom: 25px;
  left: 0;
  top: 1795px;
  z-index: -1;
`;

export const GreenDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto 5px auto;
`;

export const GreenCardTitle = styled.h2`
  font-style: normal;
  font-weight: bold;
  font-size: 50px;
  line-height: 50px;
  color: ${(props) => props.theme.colors.orange};
  text-align: center;
  margin: 25px 25px 0 25px;

  @media (min-width: 640px) {
    font-size: 70px;
    line-height: 70px;
  }
`;

export const GreenCardText = styled.p`
  color: white;
  width: 234px;
  height: 116px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 13px;
  text-align: center;
  padding: 0 24px;

  @media (min-width: 1250px) {
    font-size: 24px;
    line-height: 24px;
  }
`;

export const CallToActionParagraph = styled.div`
  width: auto;
  max-width: 42%;
  height: auto;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  color: white;
  text-align: center;
  margin: 10px auto;

  /* @media (min-width: 640px) {
    margin: auto;
  } */

  @media (min-width: 1250px) {
    font-size: 24px;
    line-height: 24px;
  }
`;

export const SignUpButtonBottom = styled.button`
  width: 114.11px;
  height: 44px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: -0.055em;
  border: none;
  border-radius: 10px;
  color: #033949;
  box-shadow: none;
  background: white;
  margin: auto auto;

  &:hover {
    background: #ffaa00;
  }

  &:active {
    outline: none;
    border: 2px solid #d64127;
    box-sizing: border-box;
  }
`;

export const Footer = styled.div`
  position: absolute;
  width: 100%;
  height: 432px;
  top: 3138px;
  background: ${(props) => props.theme.colors.blueberry};
`;

export const Grid = styled.div`
  display: flex;
  justify-content: center;
  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    display: grid;
    grid-template-columns: 2fr 2fr;
    grid-gap: 16px;
  }
`;

export const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: space-around;
  margin: auto;
  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    margin-left: 91px;
  }
`;

export const StayConnected = styled.p`
  margin-top: 81px;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: white;
  text-align: center;
  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    text-align: left;
  }
`;

export const Form = styled.form`
  display: flex;
  margin-top: 31px;
  flex-direction: row;
  justify-content: center;
  background: white;
  max-width: 335px;
  height: 41px;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 321px) {
    width: auto;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    margin-left: 0;
    margin-right: 0;
  }
`;

export const InputControl = styled.input`
  display: flex;
  justify-content: center;
  max-width: 335px;
  height: 41px;
  background: white;
  border: none;
  outline: none;
  border-radius: 10px;
  margin-bottom: 14px;

  @media (max-width: 321px) {
    width: auto;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    /* justify-content: flex-start; */
  }
`;

export const SubscribeButton = styled.button`
  width: 148px;
  height: 41px;
  border: none;
  border-radius: 0 10px 10px 0;
  color: white;
  background: ${(props) => props.theme.colors.orange};

  &:hover {
    background: ${(props) => props.theme.colors.carrot};
  }
`;

export const FooterText = styled.p`
  display: flex;
  justify-content: center;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
  color: ${(props) => props.theme.colors.kale};
  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    justify-content: flex-start;
  }
`;

export const RightColumn = styled.div`
  display: none;
  flex-direction: column;
  justify-content: flex-start;
  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    display: flex;
  }
`;

export const RightColumnRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 91px;
  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    display: flex;
  }
`;

export const InnerColumn = styled.div`
  display: flex;
  margin-top: 45px;
`;

export const InnerColumnHeading = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  margin-bottom: 0px;
  color: ${(props) => props.theme.colors.kale};
`;

export const ListLink = styled.li`
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  margin: 5px auto;
  color: white;
  list-style: none;
`;

export const A = styled.a`
  color: white;
  font-size: 14px;
  line-height: 14px;
`;

export const Copyright = styled.p`
  display: flex;
  justify-content: center;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  color: ${(props) => props.theme.colors.kale};
  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    justify-content: flex-start;
    margin-left: 91px;
  }
`;

export const Line = styled.div`
  width: 100%;
  border: 1px solid #02686d;
  justify-content: center;
`;

export const SocialMedia = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 91px;
`;

export const FooterLinksLeftWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;

  margin-top: 40px;
  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    justify-content: flex-start;
    margin-left: 91px;
  }
`;

export const FooterLinksRightWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  justify-content: flex-end;
  font-weight: 800;
  font-size: 14px;
  line-height: 93%;
  margin: 20px;
  color: #033949;
`;
