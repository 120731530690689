/* eslint-disable */
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useSelector, Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { IconContext } from 'react-icons';
import { FaClipboardList, FaHome } from 'react-icons/fa';

import jwt_decode from 'jwt-decode';
import {
  userSelector,
  setCurrentUser,
  logoutUser,
} from '../../features/User/UserSlice';

import 'react-toastify/dist/ReactToastify.css';

import setAuthToken from '../../utils/setAuthToken';
// import { setCurrentUser, logoutUser } from '../../redux/action';

// COMPONENTS
// layouts - new
import Access from '../../layouts/Access';
import DashboardLayout from '../../layouts/Dashboard/dashboardLayout';
import BrandBriefCards from '../../features/brands/briefs/Cards/BrandBriefCards';
import CreativeBriefCards from '../../features/creatives/briefs/Cards/CreativeBriefCards';

// pages
import AccountVerified from '../../pages/Account Verified/AccountVerified';
import Home from '../../pages/Home';
import About from '../../pages/About';
// Start Password Recovery
import ChangePassword from '../../pages/ForgotPassword/ChangePassword';
import ForgotPassword from '../../pages/ForgotPassword/ForgotPassword';
import EmailSentNotification from '../../pages/ForgotPassword/EmailSentNotification';
// End Password Recovery

import Contact from '../../pages/Contact';
import Dashboard from '../user/Dashboard';
import BrandDashboard from '../../features/brands/dashboard/BrandDashboard';
import CreativeDashboard from '../../features/creatives/dashboard/CreativeDashboard';
import Treatments from '../../features/creatives/treatments/TreatmentSubmission_temp';
import Demo from '../../pages/Demo';
import Privacy from '../../pages/Privacy';
import Terms from '../../pages/Terms';
import ViewBrief from '../../features/creatives/briefs/ViewBrief/ViewBrief';

import ProfileWizardCreative from '../../features/creatives/profile/Profile Creation Wizard/CreativeProfileCreationForm';
import ProfileWizardBrand from '../../features/brands/profile/Profile Creation Wizard/BrandProfileCreationForm';
// import BriefCreationForm from '../../features/brands/briefs/wizard/BriefCreationForm';
import { Step1 } from '../../features/brands/briefs/FormWizard/Step1';
import { Step2 } from '../../features/brands/briefs/FormWizard/Step2';
import { Step3 } from '../../features/brands/briefs/FormWizard/Step3';
import { Step4 } from '../../features/brands/briefs/FormWizard/Step4';
import { Step5 } from '../../features/brands/briefs/FormWizard/Step5';
import { Step6 } from '../../features/brands/briefs/FormWizard/Step6';
import { Step7 } from '../../features/brands/briefs/FormWizard/Step7';
import { Step8 } from '../../features/brands/briefs/FormWizard/Step8_2';
import { Step9 } from '../../features/brands/briefs/FormWizard/Step9';
import { Step10 } from '../../features/brands/briefs/FormWizard/Step10';
import { Step11 } from '../../features/brands/briefs/FormWizard/Step11';
import BriefReview from '../../features/brands/briefs/Brief Review/BriefReview';

import CreativeProfile from '../../features/creatives/profile/Profile Dashboard/CreativeProfile';
import BrandProfile from '../../features/brands/profile/Profile Dashboard/BrandProfile';

// pages - new
// import Signup from '../../pages/Signup';
import Signup from '../../pages/Signup/Signup_Refactoring';
import EmailSent from '../../pages/Signup/Email Sent/EmailSent';
import ResendVerificationEmail from '../../pages/ResendVerificationEmail/ResendVerificationEmail';
// import Login from '../../pages/Login';
import Login from '../../pages/Login/Login_refactored';
import FileUpload from '../../pages/Login/FileUpload';

import Error404 from '../404 Page/Error404';

// guards

import IsAuth from '../guards/IsAuth';

import store from '../../redux/store';

// FONT AWESEOME LIBRARY
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import {
  faCookieBite,
  faHome,
  faInbox,
  faDollarSign,
  faPowerOff,
  faComments,
  faUserCircle,
  faBell,
  faQuestionCircle,
  faUsers,
  faClock,
  faGlobeAfrica,
  faTags,
  faUserTag,
  faStickyNote,
  faCheck,
  faCheckCircle,
  faPlay,
  faUpload,
  faTrash,
  faClipboard,
  faPlus,
  faFile,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  far,
  fab,
  faCookieBite,
  faHome,
  faInbox,
  faDollarSign,
  faUserCircle,
  faPowerOff,
  faComments,
  faBell,
  faQuestionCircle,
  faUsers,
  faClock,
  faGlobeAfrica,
  faTags,
  faUserTag,
  faStickyNote,
  faCheck,
  faCheckCircle,
  faPlay,
  faUpload,
  faTrash,
  faClipboard,
  faPlus,
  faFile,
);

// TOKEN CHECKER
// Checks the local storage if there's a token, and if it still valid
if (localStorage.jwtToken) {
  setAuthToken(localStorage.getItem('jwtToken'));
  const decoded = jwt_decode(localStorage.jwtToken);

  // set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));

  // check for expired token
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    // logout user
    store.dispatch(logoutUser());
    // Redirect to login
    window.location.href = './login';
    console.log('You are logged out');
  }
}

const ForgotPasswordEmailSentNotificationPage = () => (
  <Access>
    <EmailSentNotification />
  </Access>
);

const ContactUs = () => (
  <Access>
    <Contact />
  </Access>
);

const VerifiedAccount = () => (
  <Access>
    <AccountVerified />
  </Access>
);

const ResetPassword = () => (
  <Access>
    <ForgotPassword />
  </Access>
);

const UpdatePassword = () => (
  <Access>
    <ChangePassword />
  </Access>
);

const Dash = () => (
  <DashboardLayout>
    <Dashboard />
  </DashboardLayout>
);

const TreatmentSub = () => (
  <DashboardLayout
    icon={
      <IconContext.Provider
        value={{
          title: 'Treatment submission',
          size: '2em',
          style: {
            marginRight: '15px',
            paddingLeft: '25px',
            verticalAlign: '-40%',
          },
        }}
      >
        <FaClipboardList />
      </IconContext.Provider>
    }
    title="Treatment Submission"
  >
    <Treatments />
  </DashboardLayout>
);

const BriefView = () => (
  <DashboardLayout
    icon={
      <IconContext.Provider
        value={{
          title: 'View brief',
          size: '2em',
          style: {
            marginRight: '15px',
            paddingLeft: '25px',
            verticalAlign: '-40%',
          },
        }}
      >
        <FaClipboardList />
      </IconContext.Provider>
    }
    title="View brief"
  >
    <ViewBrief />
  </DashboardLayout>
);

const briefCreationWizardSteps = [
  { id: 'step1', component: <Step1 /> },
  { id: 'step2', component: <Step2 /> },
  { id: 'step3', component: <Step3 /> },
  { id: 'step4', component: <Step4 /> },
  { id: 'step5', component: <Step5 /> },
  { id: 'step6', component: <Step6 /> },
  { id: 'step7', component: <Step7 /> },
  { id: 'step8', component: <Step8 /> },
  { id: 'step9', component: <Step9 /> },
  { id: 'step10', component: <Step10 /> },
  { id: 'step11', component: <Step11 /> },
];

const BriefCreationWizard = ({ match }) => {
  const steps = briefCreationWizardSteps.find(
    (steps) => match.params.step === steps.id,
  );
  return (
    <>
      {match.isExact && (
        <DashboardLayout
          icon={
            <i
              className="fa fa-plus"
              style={{
                marginRight: '15px',
                paddingLeft: '25px',
              }}
            ></i>
          }
          title="New brief"
        >
          {steps.component}
        </DashboardLayout>
      )}
    </>
  );
  return (
    <Switch>
      <Route
        path={`${match.path}/briefs/my/new/:step`}
        component={BriefCreationWizard}
      />
    </Switch>
  );
};

const BrandCards = () => (
  <DashboardLayout
    icon={
      <IconContext.Provider
        value={{
          title: 'My briefs',
          size: '2em',
          style: {
            marginRight: '15px',
            paddingLeft: '25px',
            verticalAlign: '-45%',
          },
        }}
      >
        <FaClipboardList />
      </IconContext.Provider>
    }
    title="My briefs"
  >
    <BrandBriefCards />
  </DashboardLayout>
);

const CreativeCards = () => (
  <DashboardLayout
    icon={
      <IconContext.Provider
        value={{
          title: 'Briefs',
          size: '2em',
          style: {
            marginRight: '15px',
            paddingLeft: '25px',
            verticalAlign: '-40%',
          },
        }}
      >
        <FaClipboardList />
      </IconContext.Provider>
    }
    title="Briefs"
  >
    <CreativeBriefCards />
  </DashboardLayout>
);

const CreativeProfileForm = () => (
  <DashboardLayout>
    <ProfileWizardCreative />
  </DashboardLayout>
);

const BrandProfileForm = () => (
  <DashboardLayout>
    <ProfileWizardBrand />
  </DashboardLayout>
);

const CreativeAccount = () => (
  <DashboardLayout title="My profile">
    <CreativeProfile />
  </DashboardLayout>
);

const BrandAccount = () => (
  <DashboardLayout title="My profile">
    <BrandProfile />
  </DashboardLayout>
);

const BrandDash = () => (
  <DashboardLayout
    icon={
      <IconContext.Provider
        value={{
          title: 'Dashboard',
          size: '2em',
          style: {
            marginRight: '15px',
            paddingLeft: '25px',
            verticalAlign: '-45%',
          },
        }}
      >
        <FaHome />
      </IconContext.Provider>
    }
    title="Dashboard"
  >
    <BrandDashboard />
  </DashboardLayout>
);

const CreativeDash = () => (
  <DashboardLayout
    icon={
      <IconContext.Provider
        value={{
          title: 'Dashboard',
          size: '2em',
          style: {
            marginRight: '15px',
            paddingLeft: '25px',
            verticalAlign: '-45%',
          },
        }}
      >
        <FaHome />
      </IconContext.Provider>
    }
    title="Dashboard"
  >
    <CreativeDashboard />
  </DashboardLayout>
);

const DashboardRouter = () => {
  const { user, isFetching, isSuccess } = useSelector(userSelector);
  if (user) {
    if (user.roles[0] === 'Brand') {
      return <IsAuth exact path="/dashboard/" component={BrandDash} />;
    }
    if (user.roles[0] === 'Creative') {
      return <IsAuth exact path="/dashboard/" component={CreativeDash} />;
    }
  }
  return (
    <Access>
      <Route exact path="/login" component={Login} />
    </Access>
  );
};

function App() {
  const { user, isAuthenticated } = useSelector(userSelector);
  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <ToastContainer />
      <Router>
        <div className="App">
          <Switch>
            <Route
              exact
              path="/confirmation/:email/:token"
              component={VerifiedAccount}
            />
            <Route
              path="/api/auth/forgot-password/change/:email/:token"
              component={UpdatePassword}
            />
            <IsAuth
              exact
              path="/dashboard"
              component={() => DashboardRouter()}
            />

            <IsAuth exact path="/briefs/my" component={BrandCards} />
            <IsAuth exact path="/briefs" component={CreativeCards} />
            <IsAuth exact path="/briefs/:id" component={BriefView} />
            <IsAuth
              exact
              path="/briefs/my/new/brief-review"
              component={BriefReview}
            />
            <IsAuth
              path="/briefs/my/new/:step"
              component={BriefCreationWizard}
            />

            <IsAuth exact path="/briefs/:id/apply" component={TreatmentSub} />
            <IsAuth exact path="/brands/:id/profile" component={BrandAccount} />
            <IsAuth
              exact
              path="/creatives/:id/profile"
              component={CreativeAccount}
            />
            <IsAuth
              exact
              path="/creatives/:id/profile/edit"
              component={CreativeProfileForm}
            />
            <IsAuth
              exact
              path="/brands/:id/profile/edit"
              component={BrandProfileForm}
            />
            <Access>
              <Route exact path="/" component={Login} />
              <Route exact path="/signup" component={Signup} />
              <Route exact path="/signup/email-sent" component={EmailSent} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/upload" component={FileUpload} />
              <Route exact path="/forgot-password" component={ForgotPassword} />

              <Route
                exact
                path="/forgot-password/email-sent"
                component={EmailSentNotification}
              />
              <Route
                exact
                path="/resend-confirmation-email"
                component={ResendVerificationEmail}
              />
            </Access>
            <Route component={Error404} />
          </Switch>
        </div>
      </Router>
    </div>
  );
}

const AppWrapper = () => (
  <Provider store={store}>
    <App />
  </Provider>
);

export default AppWrapper;
