import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import 'normalize.css';
import './index.css';
import AppWrapper from './components/App';
import Theme from './styles/theme';
import GlobalStyle from './styles/globalStyles';

axios.defaults.baseURL = process.env.REACT_APP_SERVER_HOST;

ReactDOM.render(
  <Theme>
    <GlobalStyle />
    <AppWrapper />
  </Theme>,
  document.getElementById('root'),
);
