/* eslint-disable */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { toast } from 'react-toastify';
import setAuthToken from '../../utils/setAuthToken';

export const loginUser = createAsyncThunk(
  'user/login',
  async (userData, thunkAPI) => {
    try {
      const response = await axios({
        method: 'post',
        url: '/api/auth/login',
        data: userData,
      });

      if (response.status === 200) {
        const { token } = response.data;
        localStorage.setItem('jwtToken', token);
        setAuthToken(token);
        const decoded = jwt_decode(token);
        return decoded;
      }
      return thunkAPI.rejectWithValue(response);
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
      toast.error(e.response.data.message);
    }
  }
);

export const signupUser = createAsyncThunk(
  'signup/signupUser',
  async (userData, thunkAPI) => {
    try {
      const response = await axios({
        method: 'post',
        url: '/api/auth/register',
        data: {
          email: userData.data.email,
          password: userData.data.password,
          confirmPassword: userData.data.confirmPassword,
          role: userData.role,
        },
      });

      if (response.status === 200) {
        window.location.href = '/signup/email-sent';
      }
      return thunkAPI.rejectWithValue(response);
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const forgotPassword = createAsyncThunk(
  'forgotpassword/forgotPassword',
  async (data, thunkAPI) => {
    try {
      const response = await axios({
        method: 'post',
        url: '/api/auth/forgot-password',
        data,
      });
      if (response.status === 200) {
        window.location.href = '/forgot-password/email-sent';
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
      toast.error(e.response.data.message);
    }
  }
);

export const changePassword = createAsyncThunk(
  'changepassword/changePassword',
  async (data, thunkAPI) => {
    try {
      const response = await axios({
        method: 'put',
        url: `/api/auth/forgot-password/${data.email}/${data.token}`,
        data,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
        },
      });
      if (response.status === 200) {
        toast.success(response.data.message);
        window.location.href = '/login';
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
      toast.error(e.response.data.message);
    }
  }
);

export const uploadFile = createAsyncThunk(
  'upload/uploadFile',
  async (file) => {
    try {
      const response = await axios({
        method: 'post',
        url: '/api/briefs/upload',
        data: file,
        'Content-Type': 'multipart/form-data',
      });
    } catch (e) {
      console.log(e);
    }
  }
);

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    email: '',
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
    isAuthenticated: false,
    user: null,
    token: localStorage.getItem('jwtToken'),
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      return state;
    },
    setCurrentUser: (state, { payload }) => {
      state.isAuthenticated = true;
      state.user = payload;
      state.token = localStorage.getItem('jwtToken');
    },
    logoutUser: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      state.token = localStorage.clear();
      console.log('Testing');
    },
  },
  extraReducers: {
    [signupUser.pending]: (state) => {
      state.isFetching = true;
    },
    [signupUser.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.email = payload.user.email;
      state.password = payload.user.password;
    },
    [signupUser.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
    },

    [loginUser.fulfilled]: (state, { payload }) => {
      state.user = payload;
      state.isFetching = false;
      state.isSuccess = true;
      state.isAuthenticated = true;
      return state;
    },
    [loginUser.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    },
    [loginUser.pending]: (state) => {
      state.isFetching = true;
    },
  },
});

export const { clearState, setCurrentUser, logoutUser } = userSlice.actions;

export const userSelector = (state) => state.user;
