import React from 'react';
import * as S from './NotFound.style';
import {
  NavBar,
  Logo,
  LoginButton,
  SignUpButtonTop,
} from '../../pages/Home/Home.style';
import logo from '../../img/home/logo.png';

function Error404() {
  return (
    <S.Wrapper>
      <NavBar>
        <Logo src={logo} />
        <LoginButton href="/login">Login</LoginButton>
        <SignUpButtonTop href="sign-up">Sign Up</SignUpButtonTop>
      </NavBar>
    </S.Wrapper>
  );
}

export default Error404;
