/* eslint-disable */
import React from 'react';
import { Link } from 'react-router-dom';
import * as S from './About.style';
import logo from '../../img/home/logo.png';
import sittingOnAvo from '../../img/about/sittingOnAvo.png';
import BlueAvoLogo from '../../img/about/avoIcon.png';
import { A } from '../Home/Home.style';

function About() {
  return (
    <S.Wrapper>
      <A href="/">
        <S.NavBar>
          <S.Logo src={logo} alt="BlueAvo Logo" />
        </S.NavBar>
      </A>

      <S.AccessButtons>
        <S.LoginButton type="button">
          <Link to="/login">Login</Link>
        </S.LoginButton>
        <S.SignUpButtonTop type="button">
          <Link to="/signup">Sign Up</Link>
        </S.SignUpButtonTop>
      </S.AccessButtons>

      <S.Heading>About Us</S.Heading>
      <S.GreenCard>
        <S.GreenCardTitle>
          Our Mission: To Use Technology To Empower Africa's Boldest Creatives
        </S.GreenCardTitle>
        <S.CardText>
          Our mission is what drives us to create opportunities for our network
          across the continent. We create groundbreaking software tools that
          change the way people create content: be it for brands, entrepreneurs
          and agencies. We connect content seekers to creators by making
          collaboration simple, across borders. <br />
          We are BlueAvo: Africa’s Creative Marketplace.
        </S.CardText>
      </S.GreenCard>
      <S.BlueAvoLogo src={BlueAvoLogo} />
      <S.PersonSitting src={sittingOnAvo} />

      <S.GreenCard2>
        <S.GreenCardTitle>
          Our Story: <br /> Revolutionizing Media Since 2019
        </S.GreenCardTitle>
        <S.CardText2>
          Our team of creatives, engineers and entrepreneurs have a wealth of
          experience in the media and technology sector. We are passionate about
          technology, and excited about the power of media and content as a tool
          of enterprise on the African continent.
        </S.CardText2>
      </S.GreenCard2>
    </S.Wrapper>
  );
}

export default About;
