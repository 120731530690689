/* eslint-disable */
import React, { useRef } from 'react';
import * as S from '../Signup/Signup.style';
import * as L from '../Login/loginStyle';
import { Container } from '../Login/loginStyle';
import FormLabel from '../../components/FormLabel';
import { Link, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { changePassword } from '../../features/User/UserSlice';

export default function ChangePassword() {
  const { email, token } = useParams();
  const dispatch = useDispatch();
  const { register, errors, watch, handleSubmit } = useForm();
  const password = useRef({});
  password.current = watch('password', '');

  const onSubmit = (data) => {
    const { password } = data;
    const userData = {
      email,
      token,
      password,
    };
    dispatch(changePassword(userData));
  };

  return (
    <>
      <Container onSubmit={handleSubmit(onSubmit)}>
        <S.Heading>Change Password</S.Heading>
        <FormLabel>Password</FormLabel>
        <L.Input
          type="password"
          name="password"
          ref={register({
            required: 'You must specify a password',
            minLength: {
              value: 8,
              message: 'Password must have at least 8 characters',
            },
          })}
        />
        <S.Error>{errors.password && errors.password.message}</S.Error>
        <FormLabel>Password Again</FormLabel>
        <L.Input
          type="password"
          name="password1"
          ref={register({
            validate: (value) =>
              value === password.current || 'The passwords do not match',
          })}
        />
        <S.Error>{errors.password1 && errors.password1.message}</S.Error>

        <L.Button style={{ width: 'auto' }} type="submit">
          Change Password
        </L.Button>

        <S.TermsContainer>
          <S.Terms
            style={{
              position: 'absolute',
              marginTop: '5px',
              marginLeft: '0',
            }}
          >
            Don't have an account?{' '}
            <Link to="/signup" style={{ fontSize: '10px' }}>
              {' '}
              Sign Up
            </Link>
          </S.Terms>
        </S.TermsContainer>
      </Container>
    </>
  );
}
