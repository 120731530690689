/* eslint-disable */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import * as S from './BriefCreationForm.style';
import LeftColumn from './LeftColumn';
import { briefFormSelector, setObjectives } from './briefFormSlice';

import { storeToSessionStorage } from '../../../helpers/SessionController';

export const Step5 = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { objectives, keyDetailsProgressIndicator } = useSelector(
    briefFormSelector,
  );
  const { register, required, errors, handleSubmit } = useForm({
    defaultValues: { objectives },
  });

  const onSubmit = (data) => {
    dispatch(setObjectives(data.objectives));
    storeToSessionStorage(data);
    history.push('/briefs/my/new/step6');
  };

  const previous = () => {
    history.push('/briefs/my/new/step4');
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <S.Wrapper>
        <S.Card>
          <S.Heading> Create a brief</S.Heading>
          <S.Line />
          <S.Grid>
            <LeftColumn
              progress={40}
              keyDetailsCompleted={keyDetailsProgressIndicator}
            />
            <S.RightColumn>
              <S.InputLabel htmlFor="objectives">
                Goals and Objectives
              </S.InputLabel>
              <S.TextArea
                autoFocus
                name="objectives"
                type="textarea"
                row="10"
                cols="50"
                placeholder="What are the project end goals"
                ref={register({ required: true })}
                // Prop: Puts data into state
              />
              <S.Error>{errors.objectives && 'Field is required!'}</S.Error>
            </S.RightColumn>
          </S.Grid>
          <S.Footer>
            <S.Button onClick={previous}>Previous</S.Button>
            <S.NextButton type="submit">Next</S.NextButton>
          </S.Footer>
        </S.Card>
      </S.Wrapper>
    </form>
  );
};
