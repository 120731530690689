/* eslint-disable */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as S from './ProfileCreationForm.style';
import LeftColumn from './LeftColumn';
import { useParams, useHistory } from 'react-router-dom';

import { updateBrand } from '../../../../redux/action';

const Step3 = ({ setForm, formData, navigation }) => {
  const {
    socialMediaHandles = [
      { name: 'facebook', url: '' },
      { name: 'instagram', url: '' },
      { name: 'twitter', url: '' },
      { name: 'youtube', url: '' },
      { name: 'linkedin', url: '' },
      { name: 'vimeo', url: '' },
      { name: 'behance', url: '' },
    ],
  } = formData;

  const dispatch = useDispatch();
  let history = useHistory();

  const { isAuthenticated, user } = useSelector((state) => ({
    isAuthenticated: state.states.isAuthenticated,
    user: state.states.user,
  }));
  const { previous, go } = navigation;

  function onSubmit(formData) {
    dispatch(updateBrand(user.sub, JSON.stringify(formData)), []);
    setTimeout(() => {}, 2000);
    history.push(`/brands/${user.sub}/profile`);
  }
  return (
    <form>
      <S.Grid>
        <LeftColumn progress={100} />
        <S.RightColumn>
          <S.Input
            id="facebookHandle"
            name="facebookHandle"
            type="text"
            placeholder="Facebook"
            value={socialMediaHandles[0].url}
            onChange={setForm}
          />
          <S.Input
            id="twitterHandle"
            name="twitterHandle"
            type="text"
            placeholder="Twitter"
            value={socialMediaHandles[2].url} // Prop: The company name S.Input data
            onChange={setForm}
            // Prop: Puts data into state
          />
          <S.Input
            id="instagramHandle"
            name="instagramHandle"
            type="text"
            placeholder="Instagram"
            value={socialMediaHandles[1].url} // Prop: The company name S.Input data
            onChange={setForm}
            // Prop: Puts data into state
          />
          <S.Input
            id="youtubeHandle"
            name="youtubeHandle"
            type="text"
            placeholder="YouTube"
            value={socialMediaHandles[3].url} // Prop: The company name S.Input data
            onChange={setForm}
            // Prop: Puts data into state
          />

          <S.Input
            id="linkedinHandle"
            name="linkedinHandle"
            type="text"
            placeholder="LinkedIn"
            value={socialMediaHandles[4].url} // Prop: The company name S.Input data
            onChange={setForm}
            // Prop: Puts data into state
          />
        </S.RightColumn>
      </S.Grid>
      <S.Footer>
        <S.Button onClick={previous}>Previous</S.Button>
        <S.NextButton onClick={() => onSubmit(formData)} type="submit">
          Submit
        </S.NextButton>
      </S.Footer>
    </form>
  );
};

export default Step3;
