/* eslint-disable */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { userSelector } from '../../features/User/UserSlice';

export default function IsAuth({ component: Component, path, ...rest }) {
  const { user, isAuthenticated } = useSelector(userSelector);

  const userIsAuth =
    user &&
    isAuthenticated &&
    (user.roles[0] === 'Creative' || user.roles[0] === 'Brand');

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <Route
      {...rest}
      render={(props) =>
        userIsAuth ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
}
