/* eslint-disable */
import {
  SET_CURRENT_USER,
  LOGOUT,
  GET_USER_PROFILE,
  GET_USER_PROFILE_PENDING,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_ERROR,
  USER_PROFILE_UPDATED,
  CHANGE_VIEW,
  SEARCH_BRIEF,
  GET_MY_BRIEF,
  GET_MY_BRIEF_PENDING,
  GET_MY_BRIEF_SUCCESS,
  GET_MY_BRIEF_ERROR,
  POST_BRIEF,
  UPLOAD_FILE,
  VERIFY_ACCOUNT,
  GET_TREATMENTS,
  GET_ERRORS,
  PENDING,
} from './constant';

const initialState = {
  isAuthenticated: false,
  user: {},
  uuid: null,
  userProfileData: null,
  hasProfile: false,
  view: '',
  briefData: null,
  treatments: null,
  filelink: '',
  errors: null,
  pending: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload,
      };
    case VERIFY_ACCOUNT:
      return {
        ...state,
        status: action.payload,
      };
    case LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    case USER_PROFILE_UPDATED:
      return {
        ...state,
        hasProfile: false,
      };
    case PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_USER_PROFILE_SUCCESS:
      if (action.payload.length >= 1) {
        return {
          ...state,
          hasProfile: true,
          userProfileData: action.payload,
        };
      }

    case GET_USER_PROFILE_ERROR:
      return {
        ...state,
        hasProfile: false,
        pending: false,
        error: action.payload,
      };
    case GET_USER_PROFILE:
      if (action.payload.length === 0) {
        return {
          ...state,
          hasProfile: false,
        };
      }
      if (action.payload.length >= 1) {
        return {
          ...state,
          userProfileData: action.payload,
          hasProfile: true,
        };
      }
    case GET_MY_BRIEF:
      return {
        ...state,
        briefData: action.payload,
        pending: action.pending,
      };
    case GET_MY_BRIEF_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case GET_MY_BRIEF_SUCCESS:
      return {
        ...state,
        briefData: action.payload,
      };
    case GET_TREATMENTS:
      return {
        ...state,
        treatments: action.payload,
      };
    case CHANGE_VIEW:
      return {
        ...state,
        isAuthenticated: false,
        user: action.payload,
      };
    case SEARCH_BRIEF:
      return {
        ...state,
        briefdata: action.payload,
      };
    case POST_BRIEF:
      return {
        ...state,
        briefdata: action.payload,
      };
    case GET_ERRORS:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
}
