import styled from 'styled-components';

export const GrayCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  background-color: ${(props) => props.theme.colors.gray5};
  border-radius: 50px;
  border: none;
  margin-right: 20px;
`;

export const GrayTick = styled.div`
  margin-top: 2px;
  color: ${(props) => props.theme.colors.gray4};
`;

export const CompletedCircle = styled(GrayCircle)`
  background-color: ${(props) => props.theme.colors.carrot};
`;

export const CompletedTick = styled(GrayTick)`
  color: ${(props) => props.theme.colors.orange};
`;
