/* eslint-disable */
import styled from 'styled-components';
import Bg1 from '../../assets/images/bg-red-noise.png';
import PersonImg from '../../assets/images/girl-logo-1.png';

const MENU_MARGIN_XS = 62;
const MENU_MARGIN_M = 123;

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
`;

export const BackgroundUnderlay = styled.div`
  position: absolute;
  z-index: -1;
  /* height: ${(props) => `calc(100% - ${props.heightReduction})`}; */
  height: 100%;
  width: 100%;
  background: ${(props) => props.theme.colors.carrot};
  @media (max-width: ${(props) => props.theme.breakpoints.xs}) {
  }
  @media (min-width: ${(props) =>
      props.theme.breakpoints.xs}) and (max-width: ${(props) =>
      props.theme.breakpoints.s}) {
  }
  @media (min-width: ${(props) =>
      props.theme.breakpoints.s}) and (max-width: ${(props) =>
      props.theme.breakpoints.m}) {
  }
  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    height: 100%;
  }
`;

export const TextureUnderlay = styled.div`
  position: absolute;

  /* height: ${(props) => `calc(100% - ${props.heightReduction})`}; */
  height: 100%;
  width: 100%;
  overflow: hidden;
  @media (max-width: ${(props) => props.theme.breakpoints.s}) {
    margin-top: ${MENU_MARGIN_XS}px;
    height: calc(100% - ${MENU_MARGIN_XS}px);
  }
  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    /* margin-top: ${MENU_MARGIN_M}px; */
    /* height: calc(100% - ${MENU_MARGIN_M}px); */
  }
`;

export const DotsPlacement1 = styled.img`
  position: absolute;
  /* margin-top: 10px; */
  margin-left: -64px;
  z-index: 0;

  @media (min-width: ${(props) =>
      props.theme.breakpoints.s}) and (max-width: ${(props) =>
      props.theme.breakpoints.m}) {
    width: 71%;
  }
  @media (min-width: ${(props) =>
      props.theme.breakpoints.s}) and (max-width: ${(props) =>
      props.theme.breakpoints.m}) {
    margin-top: -2px;
    margin-left: -62px;
    width: 114%;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    top: 0;
    left: 0;
  }
`;

export const DotsPlacement2 = styled.img`
  position: absolute;
  top: 313px;
  @media (max-width: ${(props) => props.theme.breakpoints.xs}) {
    right: -59px;
    top: 281px;
    width: 420px;
  }
  @media (min-width: ${(props) =>
      props.theme.breakpoints.xs}) and (max-width: ${(props) =>
      props.theme.breakpoints.s}) {
    right: -59px;
    top: 281px;
    width: 420px;
  }
  @media (min-width: ${(props) =>
      props.theme.breakpoints.s}) and (max-width: ${(props) =>
      props.theme.breakpoints.m}) {
    /* width: 71%; */
    /* margin-left: 4.5%; */
    right: 0;
    top: 281px;
    width: 420px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    width: 60%;
    bottom: -59px;
    top: 281px;
    right: 0;
    /* width: 420px; */
  }
`;

export const DotsPlacement3 = styled.img`
  position: absolute;
  /* left: -200px; */
  bottom: -190px; */
  width: 30%;
`;

export const PersonUnderlay = styled.div`
  position: absolute;
  display: flex;
  bottom: 0;
  right: 0;
  padding: none;
  margin: 0;
  max-width: 450px;
  @media (min-width: ${(props) =>
      props.theme.breakpoints.s}) and (max-width: ${(props) =>
      props.theme.breakpoints.m}) {
    max-width: 550px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    max-width: 770px;
  }
`;

export const PersonImage = styled.img`
  height: 100%;
  width: 100%;
  padding-left: 10px;
  bottom: 0;
`;

export const LogoUnderlay = styled.div`
  display: none;
  justify-content: center;
  position: absolute;
  top: 0;
  margin-top: 50px;
  width: 100%;
  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    display: flex;
    flex-direction: row;
    text-align: center;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
`;

export const Logo = styled.a`
  height: 64px;
  z-index: 999;
`;

export const Tagline = styled.span`
  color: ${(props) => props.theme.colors.orange};
  /* margin-top: 16px; */
  font-size: 29px;
  font-weight: 800;
  letter-spacing: -0.01em;
`;

export const Content = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  padding-bottom: 20px;
  @media (max-width: ${(props) => props.theme.breakpoints.s}) {
    margin-top: ${MENU_MARGIN_XS}px;
    height: calc(100% - ${MENU_MARGIN_XS}px);
  }
  @media (min-width: ${(props) =>
      props.theme.breakpoints.s}) and (max-width: ${(props) =>
      props.theme.breakpoints.m}) {
    margin-top: ${MENU_MARGIN_M}px;
    height: calc(100% - ${MENU_MARGIN_M}px);
  }
`;
