import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100vh;
  width: 100%;
`;

export const Span = styled.span`
  font-family: 'Metropolis', sans-serif;
  font-weight: ${(props) => props.weight};
  font-style: ${(props) => (props.italic ? 'italic' : '')};
`;
