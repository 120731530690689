/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import * as S from './Demo.style';

const fontWeights = [
  '100',
  '200',
  '300',
  '400',
  '500',
  '600',
  '700',
  '800',
  '900',
];

function Demo() {
  return (
    <S.Wrapper>
      <h1>h1 header</h1>
      <h2>h2 header</h2>
      <h3>h3 header</h3>
      <h4>h4 header</h4>
      <h5>h5 header</h5>
      <h6>h6 header</h6>
      <div>
        {fontWeights.map((weight) => (
          <div>
            <S.Span weight={weight}>{`Weight: ${weight}`}</S.Span>
            {' - '}
            <S.Span weight={weight} italic>{`Italic: ${weight}`}</S.Span>
          </div>
        ))}
      </div>
    </S.Wrapper>
  );
}

export default Demo;
