/* eslint-disable */
import React from 'react';
import * as S from './BriefCreationForm.style';
import CheckCircle from '../../../../components/CheckCircle/CheckCircle';
import ProgressBar from '../../../../components/ProgressBar/ProgressBar';

function LeftColumn({
  keyDetailsCompleted,
  theBriefCompleted,
  additionalNotesCompleted,
  progress,
  length,
  gap,
}) {
  return (
    <S.LeftColumn>
      <S.UL>
        <S.LI>
          <S.ListGroup>
            <CheckCircle completed={keyDetailsCompleted} />
            Key Details
          </S.ListGroup>
        </S.LI>
        <S.LI>
          <S.ListGroup>
            <CheckCircle completed={theBriefCompleted} />
            The Brief
          </S.ListGroup>
        </S.LI>
        <S.LI>
          <S.ListGroup>
            <CheckCircle completed={additionalNotesCompleted} />
            Additional Notes
          </S.ListGroup>
        </S.LI>
      </S.UL>
      <ProgressBar value={progress} width={length} top={gap} />
    </S.LeftColumn>
  );
}

export default LeftColumn;
