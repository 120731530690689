/* eslint-disable */
import styled from 'styled-components';

import Dots from '../../../assets/images/dots_small.png';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100vh;
  padding-bottom: 100px;
  padding-top: 20px;

  @media (min-width: 992px) {
    display: grid;
    grid-template-columns: 0.4fr 0.7fr;
    grid-template-rows: 1fr;
    gap: 15px;
    margin: 0 0 0 25px;
  }
`;
export const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 25px 0 25px;
  /* @media(min-width: 992px){
    border-right: 1.5px solid rgba(74,74,74,.2);
  } */

  }
`;

export const Row = styled.div`
  display: flex;
`;

export const Grid = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 20vh;
  @media (min-width: 992px) {
    padding-bottom: 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, 300px);
    grid-template-rows: 1fr 1fr 1fr;
    gap: 15px 15px;
  }
`;
export const Container = styled.div`
  /* margin: 0 auto 45px auto; */
  /* width: 100%; */
  margin-bottom: 45px;
`;

export const Title = styled.h4`
  color: ${(props) => props.theme.colors.blueberry};
  font-weight: 600;
  text-align: center;
  margin: 5px auto 10px auto;
  @media (min-width: 992px) {
    text-align: center;
  }
`;

export const Button = styled.button`
  display: flex;
  width: auto;
  height: 20px;
  color: white;
  font-size: 12px;
  background: #bdbdbd;
  box-shadow: 0px 4px 4px rgba(189, 189, 189, 0.1);
  border-radius: 10px;
  border: none;
  float: right;
  align-items: center;
  justify-content: center;

  &:hover {
    background: ${(props) => props.theme.colors.gray1};
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 800px;
  height: 420px;
  background-color: #ffffff;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.1);
  margin: 0 25px auto 25px;
  align-self: flex-start;
  @media (min-width: 992px) {
    margin: auto;
    width: 400px;
  } ;
`;

export const CreateBriefCard = styled(Card)`
  height: 72px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const CreateBriefButton = styled(Button)`
  background-color: ${(props) => props.theme.colors.orange};
  width: 164px;
  height: 37px;
  margin-left: 6px;
`;
export const BriefButtonGroup = styled.div`
  display: flex;
  align-items: center;
  &:hover ${CreateBriefButton} {
    background-color: ${(props) => props.theme.colors.carrot};
  }
`;

export const RoundButton = styled(CreateBriefButton)`
  border-radius: 100px;
  width: 37px;
  height: 37px;
  margin-right: 6px;
  font-style: normal;
  font-weight: 200;
  font-size: 36px;
  line-height: 36px;
  text-align: center;
`;

export const AdditionalHelpCard = styled.div`
  max-width: 800px;
  margin: auto 25px auto 25px;
  height: 323px;
  padding: 15px 15px 15px 15px;
  background: #ffffff;
  box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  @media (min-width: 992px) {
    margin: auto;
    width: 400px;
  } ;
`;

export const CardGrid = styled.div`
  display: grid;
  align-items: center;
  grid-gap: 0;
  margin-top: 12px;
  padding: 0 16px;
`;

export const Item = styled.div`
  display: grid;
  height: 51px;
  align-items: center;
  /* grid-template-columns: -webkit-min-content auto auto 96px;
  grid-template-columns: min-content auto auto 96px;
  grid-template-rows: 1fr 1fr; */
  grid-gap: 5px 8px;
  border-bottom: 1px solid #dddedf;
  padding: 3px 0;
`;

export const Span = styled.span`
  color: ${(props) => props.theme.colors.gray1};
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
align-self: center;
    grid-row: 1 / span 2;
}
`;

export const ButtonA = styled.a`
  display: flex;
  width: auto;
  height: 20px;
  color: white;
  font-size: 12px;
  background: #bdbdbd;
  box-shadow: 0px 4px 4px rgba(189, 189, 189, 0.1);
  border-radius: 10px;
  border: none;
  float: right;
  align-items: center;
  justify-content: center;
  text-decoration: none;

  &:hover {
    background: ${(props) => props.theme.colors.gray1};
  }
`;
