/* eslint-disable */
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 80%;
  padding-right: 15px;
  padding-left: 40px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 94px;

  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    padding-top: 160px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    padding-top: 50px;
  }
`;

export const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-evenly;

  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    flex-direction: row;
  }
  @media (min-width: 768px) and (max-width: 992px) {
    margin-top: 53px;
  }

  @media (max-width: 1360px) {
    flex-direction: row;
  }
`;

export const WrapperGrid = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-gap: 16px;
`;

export const PageGrid = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-gap: 16px;
`;

export const Row = styled.div`
  display: flex;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 2fr;
  grid-gap: 10px;
`;

export const Grid1 = styled(Grid)`
  grid-template-columns: 2fr 2fr;
  grid-gap: 118px;
`;

export const Avatar = styled.img`
  width: 75px;
  height: 75px;
  border-radius: 100px;
`;

export const Showreel = styled.img`
  width: 292px;
  height: 206px;
  border-style: none;
  border-radius: 10px;
`;

export const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  /* @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    display: flex;
    flex-direction: column;
    margin: auto;
    justify-content: space-between;
  } */
`;

export const MiddleColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  /* @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    display: flex;
    flex-direction: column;
    margin: -10px 0;
    justify-content: flex-start;
  } */
`;

export const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  /* @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    margin: -10px 0;
  } */
`;

export const Breadcrumb = styled.h3`
  color: ${(props) => props.theme.colors.gray1};
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  top: 0;
`;

export const Cards = styled.div`
  position: relative;
  display: '-webkit-flex';
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 15px;
  /* padding: 1.5%; */
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  /* &:nth-child(odd) {
    justify-content: flex-start;
  } */

  /* &:nth-child(even) { 
    justify-content: flex-start;
  } */
  @media (min-width: ${(props) =>
      props.theme.breakpoints.xs}) and (max-width: ${(props) =>
      props.theme.breakpoints.l}) {
    margin-top: 80px;
  }
`;

export const Column = styled.div`
  padding: 20px;
`;

export const CardTitle = styled.h3`
  margin-top: 18px;
  margin-bottom: 10px;
  margin-left: 38px;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  color: ${(props) => props.theme.colors.gray1};
`;

export const Field = styled.h4`
  font-size: 14px;
  line-height: 14px;
  font-weight: bold;
  color: #333333;
  margin: 5px 0;
`;

export const FieldValue = styled.p`
  font-size: 14px;
  line-height: 14px;
  font-weight: normal;
  margin: 5px auto;
  display: inline;
`;

export const FieldValue2 = styled.p`
  font-size: 14px;
  line-height: 14px;
  font-weight: normal;
  /* margin: 5px auto; */
  display: inline;
  padding: 10px;
`;

export const Bio = styled.div`
  font-size: 14px;
  /* line-height: 14px; */
  font-weight: normal;
  /* margin: 5px auto; */
  padding: 25px;
`;

export const ButtonEdit = styled.button`
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  width: 100px;
  height: 37.37px;
  font-style: normal;
  float: right;
  margin: 5px;
  border: none;
  border-radius: 10px;
  background-color: #ffaa00;
  color: white;
  mix-blend-mode: normal;
  letter-spacing: -0.055em;
  margin-bottom: 5px;

  &:hover {
    background: ${(props) => props.theme.colors.carrot};
  }
`;

export const CardMenuItem = styled.p`
  font-size: 14px;
  line-height: 14px;
  margin: 0;
  padding: 5px;
`;

export const BasicInfoCard = styled.div`
  width: auto;
  min-width: 318px;
  height: 268px;
  margin: 0 10px 20px 10px;
  /* margin-right: auto;
  margin-left: auto; */
  padding-bottom: 30px;
  background: white;
  border-radius: 10px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  box-sizing: border-box;
  order: 1;

  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    width: 96%;
    height: 146px;
    margin-right: 20px;
    order: 1;
  }
`;

export const TopSkillsCard = styled.div`
  width: auto;
  min-width: 318px;
  height: 268px;
  margin: 0 10px 20px 10px;
  /* margin-right: auto;
  margin-left: auto; */
  padding-bottom: 30px;
  background: white;
  border-radius: 10px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  box-sizing: border-box;
  order: 3;

  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    width: 31%;
    height: 146px;
    margin-right: 20px;
    order: 1;
  }
`;

export const PortfolioCard = styled.div`
  width: auto;
  min-width: 318px;
  height: 400px;
  margin: 0 10px 20px 10px;
  /* margin-right: auto;
  margin-left: auto; */
  padding-bottom: 30px;
  background: white;
  border-radius: 10px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  box-sizing: border-box;
  order: 4;
  display: none;

  @media (min-width: 768px) and (max-width: 1330px) {
    order: 2;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    width: 63.333%;
    order: 1;
    display: block;
  }
`;

export const SocialContainer = styled.div`
  background: white;
  margin: 5px;
  border-radius: 10px;
`;

export const SocialRow = styled.div`
  display: flex;
  align-items: center;
`;

export const UL = styled.ul`
  list-style: none;
`;

export const SocialHandle = styled.p`
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
`;

export const VideoContainer = styled.div`
  border-radius: 10px;
  width: 292px;
  height: 136px;
  /* padding-top: 56.25%; */
`;

export const VideoStyle = styled.div`
  border-radius: 10px;
`;

export const InputSocial = styled.input`
  border-radius: 10px;
  background-color: ${(props) => props.theme.colors.gray6};
  color: grey;
  border: none;
  height: 30px;
  margin-top: 10px;
`;
export const ShortBioCard = styled.div`
  width: auto;
  min-width: 318px;
  height: 268px;
  margin: 0 10px 20px 10px;
  padding-bottom: 30px;
  background: white;
  border-radius: 10px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  box-sizing: border-box;
  order: 2;

  @media (min-width: 768px) and (max-width: 1330px) {
    order: 3;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    width: 31%;
    margin-right: 20px;
    order: 1;
    height: 400px;
  }
`;

export const Content = styled.div`
  overflow-y: auto;
`;

export const BioText = styled.p`
  font-size: 14px;
  line-height: 14px;
  font-style: normal;
  color: ${(props) => props.theme.colors.gray1};
  margin: 14px 40px 0 40px;

  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    font-size: 16px;
    line-height: 176.5%;
  }
`;

export const SecurityCard = styled.div`
  width: auto;
  min-width: 318px;
  height: 183px;
  margin: 0 10px 20px 10px;
  padding-bottom: 30px;
  background: white;
  border-radius: 10px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  box-sizing: border-box;
  order: 5;

  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    width: 31%;
    order: 1;
  }
`;
export const SecurityIconContainer = styled.div`
  font-size: 15px;
  display: flex;
  align-items: baseline;
  margin: 0px;
  padding: 0;
`;

export const PaymentsCard = styled.div`
  width: auto;
  min-width: 318px;
  height: 183px;
  margin: 0 10px 20px 10px;
  /* margin-right: auto;
  margin-left: auto; */
  padding-bottom: 30px;
  background: white;
  border-radius: 10px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  box-sizing: border-box;
  order: 6;

  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    width: 31%;
    order: 1;
  }
`;

export const AccountBalance = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  margin-left: 38px;
  color: ${(props) => props.theme.colors.kale};
  margin-bottom: 1px;
`;

export const KaleColorText = styled.p`
  font-weight: 300;
  font-size: 14px;
  line-height: 14px;
  margin-left: 38px;
  color: ${(props) => props.theme.colors.kale};
`;

export const ButtonPayout = styled.button`
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  width: 100px;
  height: 37.37px;
  font-style: normal;
  float: left;
  margin: 5px;
  border: none;
  border-radius: 10px;
  background-color: #ffaa00;
  color: white;
  mix-blend-mode: normal;
  letter-spacing: -0.055em;
  margin-bottom: 5px;

  &:hover {
    background: ${(props) => props.theme.colors.carrot};
  }
`;

export const AccountStatusCard = styled.div`
  width: auto;
  min-width: 318px;
  height: 183px;
  margin: 0 10px 20px 10px;
  /* margin-right: auto;
  margin-left: auto; */
  padding-bottom: 30px;
  background: white;
  border-radius: 10px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  box-sizing: border-box;
  order: 7;

  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    width: 31%;
    order: 1;
  }
`;

export const AccountStatusBackground = styled.div`
  width: 132px;
  height: 26px;
  border-radius: 10px;
  background-color: #e0e0e0;
  padding: 10px;
  margin-left: 25px;
`;

export const CurrentAccountStatus = styled.p`
  display: flex;
  font-size: 14px;
  line-height: 14px;
  color: ${(props) => props.theme.colors.gray1};
  background-color: #e0e0e0;
  border-radius: 10px;
  width: 132px;
  height: 26px;
  margin-left: 38px;
  justify-content: center;
  align-items: center;
`;
