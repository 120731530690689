import styled from 'styled-components';
import background from '../../img/about/background.png';
import Dots from '../../assets/images/dots_small_light.png';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 100vh;
  width: 100%;
  background: ${(props) => props.theme.colors.blueberry};
  background-image: url(${Dots});
  background-repeat: repeat;
  justify-content: center;
`;

export const NavBar = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: center;
  margin: 25px 25px;
  text-align: center;

  @media (min-width: 640px) {
    justify-content: space-between;
  }
`;

export const Logo = styled.img`
  position: relative;
  width: 213px;
  height: 38px;
  top: 0px;
  align-items: center;
  text-align: center;

  @media (min-width: 640px) {
    width: 213px;
    height: 38px;
    left: 0;
    top: 7px;
  }
  @media (min-width: 1280px) {
    left: 90px;
  }
`;

export const LoginButton = styled.button`
  /* position: absolute; */
  width: 93px;
  height: 28px;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -0.055em;
  border-radius: 10px;
  border: none;
  color: #033949;
  box-shadow: none;
  background: white;
  margin: auto 6px;
  display: inline-block;
  /* left: 0; */

  &:hover {
    background: #ffaa00;
  }

  &:active {
    outline: none;
    border: 2px solid #d64127;
    box-sizing: border-box;
  }

  @media (min-width: 640px) {
    width: 114.11px;
    height: 44px;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
  }
`;

export const SignUpButtonTop = styled.button`
  /* position: absolute; */
  width: 93px;
  height: 28px;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -0.055em;
  border: none;
  border-radius: 10px;
  /* text-align: center; */
  color: #033949;
  box-shadow: none;
  background: white;
  margin: auto 6px;
  display: inline-block;
  /* right: 0; */

  &:hover {
    background: #ffaa00;
  }
  &:active {
    outline: none;
    border: 2px solid #d64127;
    box-sizing: border-box;
  }

  @media (min-width: 640px) {
    width: 114.11px;
    height: 44px;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
  }
`;

export const AccessButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 5px 53px;
  position: relative;

  @media (min-width: 640px) {
    top: -62px;
    justify-content: flex-end;
  }
`;

export const BackgroundImage = styled.div`
  height: 1796px;
  width: 100%;
  position: absolute;
  background-image: url(${background});
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  left: 0px;
  top: 0px;
  z-index: -1;
`;

export const Heading = styled.h2`
  display: none;

  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    margin-left: 10%;
    display: block;
    width: auto;
    height: 44px;
    font-style: normal;
    font-weight: 800;
    font-size: 44px;
    line-height: 44px;
    text-transform: uppercase;
    color: white;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    width: auto;
    height: 72px;
    font-size: 72px;
    line-height: 72px;
    margin-left: 5%;
  }
`;

export const Title = styled.h3`
  font-size: 24px;
  line-height: 24px;
  color: white;
`;

export const UL = styled.ul`
  list-style-type: none;
`;

export const LI = styled.li`
  list-style-type: none;
  margin-bottom: 5px;
`;

export const BlueAvoLogo = styled.img`
  display: none;
  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    display: block;
    position: absolute;
    right: 4.5%;
    bottom: -20px;
    z-index: 0;
  }
`;

export const PersonSitting = styled.img`
  display: none;
  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    display: block;
    position: absolute;
    width: 355.75px;
    height: 605.4px;
    /* left: 880.19px; */
    right: 280px;
    bottom: -205px;
    transform: rotate(7.64deg);
    z-index: 1;
  }
`;

export const GreenCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  min-width: 279px;
  max-width: 80%;
  min-height: 396;
  height: auto;
  background: #02686d;
  border-radius: 10px;
  margin: 25px auto 25px auto;

  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    width: 100%;
    max-width: 90%;
  }
`;

export const GreenCard2 = styled(GreenCard)`
  height: auto;
  position: relative;
  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    width: 65%;
    align-self: flex-start;
    margin: 25px auto 100px 5%;
  }
`;

export const GreenCardTitle = styled.h2`
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  color: #ffaa00;
  text-align: center;
  padding: 0 40px;

  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    max-width: 638px;
    padding: 0;
    margin-left: 39px;
    font-size: 36px;
    line-height: 36px;
    text-align: left;
  }
`;

export const GreenCardText = styled.p`
  color: white;
  width: 234px;
  height: 116px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 13px;
  text-align: center;
  padding: 0 24px;

  @media (min-width: 1250px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const CardText = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 8px;
  line-height: 8px;
  text-align: left;
  color: white;
  padding-left: 39px;
  padding-right: 39px;
  padding-bottom: 48px;

  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    font-weight: 600;
    font-size: 12px;
    line-height: 17px;
    /* padding-right: 633px; */
    padding-bottom: 74px;
  }
`;

export const CardText2 = styled(CardText)`
  padding-bottom: 49px;
  color: red;
  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    padding-left: 39px;
    padding-right: 58px;
  }
`;
