/* eslint-disable */
import React from 'react';
import { useForm } from 'react-hook-form';
import * as S from './Switch.style';

function Toggle({ margin, isOn, handleToggle }) {
  const { register } = useForm();
  return (
    <S.Label margin={margin}>
      <input
        checked={isOn}
        onChange={handleToggle}
        className="react-switch-checkbox"
        id="react-switch-new"
        type="checkbox"
        ref={register}
      />
      <S.Slider />
    </S.Label>
  );
}

export default Toggle;
