/* eslint-disable */
import React from 'react';
import { Link } from 'react-router-dom';
import { IconContext } from 'react-icons';
import {
  FaFacebook,
  FaInstagram,
  FaTwitter,
  FaYoutube,
  FaLinkedinIn,
} from 'react-icons/fa';
import * as S from './Home.style';
import logo from '../../img/home/logo.png';
import cloudRight from '../../img/home/cloudRight.png';
import cloudBehind from '../../img/home/cloudBehind.png';
import cloudLeft from '../../img/home/cloudLeft.png';
import cloudFar from '../../img/home/cloudFar.png';
import womanWithLaptop from '../../img/home/sittingWomanLaptop.png';
import manWithLaptop from '../../img/home/manWithLaptop.png';
import point1icon from '../../img/home/Point 1 Icon.svg';
import point2icon from '../../img/home/Point 3 Icon.svg';
import point3icon from '../../img/home/Point 2 Icon.svg';
import MailchimpSubscribe from './Mailchimp/MailchimpSubscribe';

function Home() {
  const onHandleSignUpClick = () => {
    window.location.href = '/signup';
  };

  const onHandleLoginClick = () => {
    window.location.href = '/login';
  };

  const handleRequestDemo = () => {
    window.location.href = '/contact-us?subject=Requesting demo';
  };

  return (
    <S.Wrapper>
      <S.BackgroundImage />
      <S.NavBar>
        <S.Logo src={logo} alt="BlueAvo Logo" />
      </S.NavBar>
      <S.AccessButtons>
        <S.RequestButton onClick={handleRequestDemo} type="button">
          Request Demo
        </S.RequestButton>
        <S.LoginButton onClick={onHandleLoginClick} type="button">
          Login
        </S.LoginButton>
        <S.SignUpButtonTop onClick={onHandleSignUpClick} type="button">
          Sign Up
        </S.SignUpButtonTop>
      </S.AccessButtons>
      <div>
        <S.Heading>
          Africa&apos;s Creative Talent <S.Hide>Platform</S.Hide>
          <S.Break>Platform</S.Break>
        </S.Heading>
      </div>
      <S.TextureUnderlay>
        <S.CloudRight src={cloudRight} alt="Cloud on the right" />
        <S.CloudLeft src={cloudLeft} alt="Cloud on the left" />
        <S.CloudBehind src={cloudBehind} alt="Cloud in the background" />
        <S.CloudFar src={cloudFar} alt="Cloud on the far right" />
        <S.WomanWithLaptop
          src={womanWithLaptop}
          alt="Woman sitting with laptop"
        />
        <S.ManWithLaptop src={manWithLaptop} alt="Man sitting with laptop" />
      </S.TextureUnderlay>

      <S.MidSectionWrapper>
        <S.RedCard>
          <S.CardContent>
            <S.MiddleParagraph>
              A Marketer’s Dream:
              <br />
              Get access to the largest diverse supply chain of quality and
              vetted creatives to deliver fast, quality, creative work.
            </S.MiddleParagraph>
          </S.CardContent>
        </S.RedCard>
        <S.OrangeCard>
          <S.CardContent>
            <S.CardText>
              Access creative talent in every corner of the African continent at
              a click away. Effortlessly scale your resources, without adding
              headcount or overheads.
            </S.CardText>
          </S.CardContent>
        </S.OrangeCard>
        <S.OrangeCard>
          <S.CardContent>
            <S.CardText>
              Technology tools to help you efficiently manage each brief and
              co-work digitally, from creative ideation through to payment.
            </S.CardText>
          </S.CardContent>
        </S.OrangeCard>
      </S.MidSectionWrapper>

      <S.GreenSectionWrapper>
        <S.GreenDiv>
          <S.GreenCardTitle>How it works</S.GreenCardTitle>
        </S.GreenDiv>
        <S.PointOneWrapper>
          <S.PointOneGrid>
            <S.GreenDiv style={{ height: '100px', alignItems: 'center' }}>
              <S.PointOneIcon src={point1icon} />
            </S.GreenDiv>
            <S.GreenDiv>
              <S.PointOneHeading>1. Post a Brief</S.PointOneHeading>
              <S.PointOneText>
                You receive a shortlist of the best creatives to work with.
              </S.PointOneText>
            </S.GreenDiv>
          </S.PointOneGrid>
        </S.PointOneWrapper>

        <S.PointTwoWrapper>
          <S.PointTwoGrid>
            <S.GreenDiv style={{ height: '100px', alignItems: 'center' }}>
              <S.PointTwoIcon src={point2icon} />
            </S.GreenDiv>
            <S.GreenDiv>
              <S.PointTwoHeading>2. Pay your Budget</S.PointTwoHeading>
              <S.PointTwoText>
                Posting a brief is FREE, always. No hidden fees. BlueAvo takes
                20% commission from the budget you are willing to spend.
              </S.PointTwoText>
            </S.GreenDiv>
          </S.PointTwoGrid>
        </S.PointTwoWrapper>

        <S.PointThreeWrapper>
          <S.PointThreeGrid>
            <S.GreenDiv
              style={{
                height: '100px',
                alignItems: 'center',
                marginTop: '20px',
              }}
            >
              <S.PointThreeIcon src={point3icon} />
            </S.GreenDiv>
            <S.GreenDiv>
              <S.PointThreeHeading>3. Cruise to Delivery</S.PointThreeHeading>
              <S.PointThreeText>
                BlueAvo manages agreements, IP and paying creatives when you are
                happy with the work.
              </S.PointThreeText>
            </S.GreenDiv>
          </S.PointThreeGrid>
        </S.PointThreeWrapper>
      </S.GreenSectionWrapper>

      <S.BottomBackground>
        <S.BottomSectionWrapper>
          <S.GreenDiv>
            <S.GreenCardTitle>
              AGILITY DESIGNED FOR
              <br />
              SCALE ON THE CONTINENT
            </S.GreenCardTitle>
            <S.CallToActionParagraph>
              Take the first step today.
            </S.CallToActionParagraph>
            <S.SignUpButtonBottom onClick={onHandleSignUpClick}>
              Get Started
            </S.SignUpButtonBottom>
          </S.GreenDiv>
        </S.BottomSectionWrapper>
      </S.BottomBackground>

      <S.Footer>
        <S.Grid>
          <S.LeftColumn>
            <S.StayConnected>
              Stay connected and receive weekly tips and updates from
              Africa&apos;s Creative Marketplace.
              <MailchimpSubscribe />
            </S.StayConnected>
            <S.FooterText>
              <strong style={{ color: 'white' }}>BlueAvo&nbsp;•</strong>
              &nbsp;Cape Town, South Africa
            </S.FooterText>
          </S.LeftColumn>
          <S.RightColumn>
            <S.RightColumnRow>
              <S.InnerColumn>
                <ul>
                  <S.InnerColumnHeading>Navigate</S.InnerColumnHeading>
                  <S.ListLink>
                    <S.A href="/about">About Us</S.A>
                  </S.ListLink>
                  <S.ListLink>
                    {' '}
                    <S.A href="/contact-us">Contact Us</S.A>
                  </S.ListLink>
                </ul>
              </S.InnerColumn>
              <S.InnerColumn>
                <ul>
                  <S.InnerColumnHeading>Brands</S.InnerColumnHeading>
                  <S.ListLink>
                    <S.A href="/signup">Create Profile</S.A>
                  </S.ListLink>
                  <S.ListLink>
                    <S.A href="/login">Create Brief</S.A>
                  </S.ListLink>
                </ul>
              </S.InnerColumn>
              <S.InnerColumn>
                <ul>
                  <S.InnerColumnHeading>Creatives</S.InnerColumnHeading>
                  <S.ListLink>
                    <S.A href="/dashboard">Browse Jobs</S.A>
                  </S.ListLink>
                  <S.ListLink>Premium Creatives</S.ListLink>
                </ul>
              </S.InnerColumn>
            </S.RightColumnRow>

            <S.SocialMedia>
              <S.A
                href="https://www.instagram.com/officialblueavo/"
                target="_blank"
              >
                <IconContext.Provider
                  value={{
                    title: 'Instagram',
                    size: '1.5em',
                    color: '#02686D',
                    style: {
                      marginRight: '9px',
                      marginLeft: '9px',
                    },
                  }}
                >
                  <FaInstagram />
                </IconContext.Provider>
              </S.A>
              <S.A
                href="https://www.twitter.com/officialblueavo"
                target="_blank"
              >
                <IconContext.Provider
                  value={{
                    title: 'Twitter',
                    size: '1.5em',
                    color: '#02686D',
                    style: {
                      marginRight: '9px',
                      marginLeft: '9px',
                    },
                  }}
                >
                  <FaTwitter />
                </IconContext.Provider>
              </S.A>
              <S.A
                href="https://www.facebook.com/officialblueavo"
                target="_blank"
              >
                <IconContext.Provider
                  value={{
                    title: 'Facebook',
                    size: '1.5em',
                    color: '#02686D',
                    style: {
                      marginRight: '9px',
                      marginLeft: '9px',
                    },
                  }}
                >
                  <FaFacebook />
                </IconContext.Provider>
              </S.A>
              <S.A
                href="https://www.youtube.com/channel/UCHgNGMmzoxWK31I9XfaRhiw"
                target="_blank"
              >
                <IconContext.Provider
                  value={{
                    title: 'YouTube',
                    size: '1.5em',
                    color: '#02686D',
                    style: {
                      marginRight: '9px',
                      marginLeft: '9px',
                    },
                  }}
                >
                  <FaYoutube />
                </IconContext.Provider>
              </S.A>
              <S.A
                href="https://www.linkedin.com/company/blueavo/"
                target="_blank"
              >
                <IconContext.Provider
                  value={{
                    title: 'LinkedIn',
                    size: '1.5em',
                    color: '#02686D',
                    style: {
                      marginRight: '9px',
                      marginLeft: '9px',
                    },
                  }}
                >
                  <FaLinkedinIn />
                </IconContext.Provider>
              </S.A>
            </S.SocialMedia>
          </S.RightColumn>
        </S.Grid>
        <S.Line />
        <S.Copyright>© 2021 BlueAvo. All Rights Reserved</S.Copyright>
        <S.FooterLinksLeftWrapper>
          <Link to="/terms" style={{ marginRight: '28px', color: 'white' }}>
            Terms and Conditions
          </Link>
          <Link to="/privacy" style={{ color: 'white' }}>
            Privacy Policy
          </Link>
        </S.FooterLinksLeftWrapper>
      </S.Footer>
    </S.Wrapper>
  );
}

export default Home;
