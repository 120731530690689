/* eslint-disable */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import * as S from './BriefCreationForm.style';
import LeftColumn from './LeftColumn';
import {
  briefFormSelector,
  setTreatmentRequirements,
  setTheBriefProgressIndicator,
} from './briefFormSlice';

import { storeToSessionStorage } from '../../../helpers/SessionController';

export const Step10 = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { treatmentRequirements, keyDetailsProgressIndicator } = useSelector(
    briefFormSelector,
  );
  const { register, required, errors, handleSubmit } = useForm({
    defaultValues: { treatmentRequirements },
  });

  const onSubmit = (data) => {
    dispatch(setTreatmentRequirements(data.treatmentRequirements));
    dispatch(setTheBriefProgressIndicator(true));
    storeToSessionStorage(data);

    history.push('/briefs/my/new/step11');
  };

  const previous = () => {
    history.push('/briefs/my/new/step9');
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <S.Wrapper>
        <S.Card>
          <S.Heading> Create a brief</S.Heading>
          <S.Line />
          <S.Grid>
            <LeftColumn
              progress={90}
              keyDetailsCompleted={keyDetailsProgressIndicator}
            />
            <S.RightColumn>
              <S.InputLabel htmlFor="treatmentRequirements">
                Treatment Requirements
              </S.InputLabel>
              <S.TextArea
                autoFocus
                name="treatmentRequirements"
                type="textarea"
                row="10"
                cols="50"
                placeholder="What are the treatment requirements"
                ref={register({ required: true })}
              />
              <S.Error>
                {errors.treatmentRequirements && 'Field is required!'}
              </S.Error>
            </S.RightColumn>
          </S.Grid>
          <S.Footer>
            <S.Button onClick={previous}>Previous</S.Button>
            <S.NextButton type="submit">Next</S.NextButton>
          </S.Footer>
        </S.Card>
      </S.Wrapper>
    </form>
  );
};
