/* eslint-disable */
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const UL = styled.ul`
  list-style: none;
`;

// const markdown = `Platform Terms
function TermsConditions() {
  return (
    <div>
      <p>
        These Website Terms were last amended on <strong>29/09/2020. </strong>
      </p>
      <UL>
        1. DEFINITIONS.
        <li>
          1.1 In these Terms, unless the context clearly indicates otherwise,
          the following terms have the following meanings:
        </li>
        <li>
          1.1.1 <strong>Additional Terms</strong> means any additional
          requirements that a Client includes in a Brief, clearly expressed
          under the single heading of “Additional Terms” which are intended to
          bind a Creator to a series of supplementary and overarching
          obligations.
        </li>
        <li>
          1.1.2 <strong>Assigned Materials</strong> means the Productions
          excluding any deliverables or parts of them which are Third-Party
          Materials, which will be licensed to the Client.
        </li>
        <li>
          1.1.3 <strong>BlueAvo Content</strong> has the meaning given to it at
          clause 7 of these Terms.
        </li>
        <li>
          1.1.4 <strong>BlueAvo Fee</strong> means the amount payable by the
          Client to us as specified via the Platform and/or our invoice (being
          the total Budget minus the Creator Payment).
        </li>
        <li>
          1.1.5 <strong>Brief</strong> means a creative brief setting out a
          Client's Production requirements posted on or via the Platform.
        </li>
        <li>
          1.1.6 <strong>Broadcast Media</strong> means all broadcast media
          including television advertising, sponsorship indents, cinema, news
          programmes and new media (including any other broadcast media not now
          known or to be created in the future).
        </li>
        <li>
          1.1.7 <strong>Budget</strong> means the total budget for a Production
          and includes the BlueAvo Fee and the Creator Payment.
        </li>
        <li>
          1.1.8 <strong>Client</strong> means a party registered through the
          Platform that wishes to commission a Creator to complete a Production
          using the Platform.
        </li>
        <li>
          1.1.9 <strong>Client Assets</strong> means images, audio/audio-visual
          content, scripts, screenplay, advertising or campaign materials,
          publications, music, documents, software, information, literary
          material, any logo, trade mark or indicia, and/or other materials
          provided by the Client, (including materials which may be or are
          ordinarily subject to Intellectual Property rights, or which
          constitute a trade secret or form of know how and any information
          which may constitute Confidential Information), for inclusion in
          Responses and/or use in the fulfilment of Briefs.
        </li>{' '}
        <li>
          1.1.10 <strong>Communication</strong> has the meaning given to it at
          clause 8.6 of these Terms.
        </li>
        <li>
          1.1.11 <strong>Confidential Information</strong> means, without
          limiting the generality of the term, any and all proprietary
          information, know-how, business processes or trade secrets of a
          technical, scientific, commercial, financial and/or confidential
          nature (however recorded or preserved) and all other information in
          whatever form, whether subject to or protected by common law or
          statutory laws relating to copyright, patent, trade marks, registered
          or unregistered, or otherwise, disclosed or communicated, directly or
          indirectly, by a Disclosing Party to a Recipient Party or acquired by
          the Recipient Party from the Disclosing Party orally or in writing,
          including but not limited to any Intellectual Property or information
          that would be regarded as confidential by a reasonable business person
          unless the information: (i) is public knowledge; or (ii) is already
          known to the recipient party at the time of disclosure; or (iii)
          subsequently becomes public knowledge other than by breach of these
          Terms or a Contract; or (iv) subsequently comes lawfully into the
          possession of the recipient party from a third party; or (v) is
          required to be disclosed under any applicable law.
        </li>
        <li>
          1.1.12 <strong>Content</strong> means any textual, visual or aural
          content, including photographs, videos, information, materials,
          opinions and/or statements, that is encountered and/or used as part of
          any user’s experience of the Platform, including (without limitation)
          any BlueAvo Content or content uploaded by any user.
        </li>
        <li>
          1.1.13 <strong>Content Standards</strong> means the content standards
          set out at clauses 8.2, 8.3 and 8.4 of these Terms.
        </li>
        <li>
          1.1.14 <strong>Contract</strong> means the contract formed between a
          Client, a Creator and us in accordance with the terms and conditions
          set out in these Terms.
        </li>{' '}
        <li>
          1.1.15 <strong>Creator</strong> means any person registered through
          the Platform who wishes to submit Responses and produce Productions
          through the Platform, including, but not limited to, filmmakers,
          photographers, directors, editors, designers, animators, production
          companies or producers.
        </li>
        <li>
          1.1.16 <strong>Creator Payment</strong> means the amount specified in
          a Brief payable to a Creator (being the total Budget minus the BlueAvo
          Fee).
        </li>
        <li>
          1.1.17 <strong>Digital Media</strong> means all forms of digital files
          delivered on or via the global computer network known as the Internet
          or any other digital delivery system (including but not limited to
          mobile device telecommunications networks), whether streamed or
          downloaded, including without limitation websites, email, banner ads,
          online content and Client's own and third party Internet and/or social
          media websites and digital websites and communication channels,
          including streaming services and any other digital media not now known
          or to be created in the future, but excludes Broadcast Media and Print
          Media as defined in these Terms.
        </li>
        <li>
          1.1.18 <strong>Force Majeure Event</strong> means any event or
          circumstance in each case which is beyond the reasonable control of
          the affected party, including (without limitation), strike, lockout or
          other industrial action, act of God (including severe weather events),
          act of terrorism, war, act of government or political or civil
          disturbance, epidemic, pandemic or lockdown related to an epidemic or
          pandemic.
        </li>
        <li>
          1.1.19 <strong>Intellectual Property</strong> means all and any
          intellectual property of whatsoever nature, and in whatsoever form,
          arising anywhere in the world, including, without limitation, all and
          any creations of the mind that are recognised and/or capable of being
          protected by law from use by any other person, and all rights
          resulting from or attributable to such intellectual activity, whether
          acquired or protected by statute or common law and whether in terms of
          applicable laws in any jurisdiction, and including (without
          limitation): trade marks, service marks, logos, trade and business
          names, rights in trade dress or get-up, rights in goodwill or to sue
          for passing off, unfair competition rights, copyright (including,
          without limitation, copyright in computer software, computer programs
          and data bases) and related rights, domain names, designs (including
          registered designs), patents, petty patents, utility models,
          topography rights and like rights (including applications for the
          grant of any of the aforementioned) inventions, know-how, Confidential
          Information, business information and trade secrets, data base rights,
          applications for registration, and the right to apply for
          registration, for any of these intellectual property rights, and any
          other intellectual property rights, in each case whether registered or
          unregistered and including all applications for and renewals or
          extensions of such rights, and all rights or forms of protection
          having similar or equivalent or similar effect to any of the
          aforementioned, which may subsist in any country in the world.
        </li>
        <li>
          1.1.20 <strong>Licence</strong> has the meaning given to it in clause
          26.1of these Terms.
        </li>
        <li>
          1.1.21 <strong>Overages</strong> means any additional costs and fees
          incurred by a Creator on top of the original Creator Payment, where
          such costs were incurred with the Client's prior written approval.
        </li>
        <li>
          1.1.22 <strong>Platform</strong> means our platform located at
          BlueAvo.com including our mobile application.
        </li>
        <li>
          1.1.23 <strong>Print Media</strong> means all printed PR, press,
          magazines, newspapers, textbooks, out of home (OOH), digital out of
          home (DOOH), below the line (BTL), point of sale (POS), packaging,
          direct mail, trade shows and kiosks.
        </li>
        <li>
          1.1.24 <strong>Privacy & Cookies Policy</strong> means our privacy and
          cookies policies located at{' '}
          <Link
            to="/blueavo.com/privacy"
            style={{ color: 'blue', fontSize: '12px' }}
          >
            <strong>blueavo.com/privacy.</strong>
          </Link>
        </li>{' '}
        <li>
          1.1.25 <strong>Production</strong> means the production to be produced
          in accordance with a Brief, including but not limited to, graphic,
          audio or audio-visual productions.
        </li>
        <li>
          1.1.26 <strong>Production Contracts</strong> has the meaning given to
          it in clause 27.16.
        </li>
        <li>
          1.1.27 <strong>Raw Files</strong> means rushes or raw, unedited video
          footage or images captured during a Production.
        </li>
        <li>
          1.1.28 <strong>Response</strong> means a Creator's creative response
          submitted via the Platform to a Brief.
        </li>
        <li>
          1.1.29 <strong>Response Deadline</strong> means the deadline for the
          receipt of Responses specified in the relevant Brief.
        </li>
        <li>
          1.1.30 <strong>Taxes</strong> means any value-added tax, sales tax,
          goods and services tax, withholding tax, or other tax, levy, duty,
          impost, charge or fee.
        </li>
        <li>
          1.1.31 <strong>Third-Party Materials</strong> means any work or
          materials authored, created or performed by a third party and either
          commissioned for, or used in relation to, the Production, including
          library images, and any pre-existing Intellectual Property rights of
          the Creator.
        </li>
        <li>
          1.1.32 <strong>Terms</strong> means these terms of use, together with
          the documents referred to in them.
        </li>
        <li>
          1.1.33 <strong>Union</strong> has the meaning given to it in clause
          27.22 of these Terms.
        </li>
        <li>
          1.1.34 <strong>you</strong> or <strong>user</strong> means any user of
          the Platform, and includes (without limitation) any Client or Creator.
        </li>
        <li>
          1.2{' '}
          <strong>
            Important. Where these terms have been translated to help with local
            language understanding, the English version of BlueAvo's terms
            available at blueavo.com/terms shall always prevail.
          </strong>
        </li>{' '}
      </UL>
      <UL>
        2. THESE TERMS.{' '}
        <li>
          These Terms govern your relationship with BlueAvo Pty Ltd,
          Registration No. 2019/622928/07, whose registered office is at Clock
          Tower, V&A Waterfront, Cape Town, South Africa, 8000, (we, our, us,
          BlueAvo) when you use the Platform and apply to your use of the
          Platform. Use of the Platform includes, amongst others, accessing or
          browsing the Platform as well as posting Briefs and Responses and
          receiving or providing Productions.{' '}
          <strong>
            Please refer carefully to the relevant sections of these Terms
            before you start to use the Platform. By using the Platform, you
            (including, where applicable, the organisation you represent) will
            be deemed to have understood these Terms, and constitutes your
            (including, where applicable, the organisation you represent)
            acceptance of the Terms, which form a binding agreement between you
            and us and/or may form a binding agreement between Creators, Clients
            and us.
          </strong>
        </li>{' '}
        <li>
          2.1 Please read these Terms carefully before using the Platform.
        </li>{' '}
        <li>
          2.2{' '}
          <strong>
            THE USER’S ATTENTION IS EXPRESSLY DRAWN TO THE PROVISIONS IN THE
            TERMS OF USE INDICATED IN BOLD. PLEASE PAY SPECIAL ATTENTION TO
            THESE PROVISIONS AND ENSURE THAT YOU UNDERSTAND THESE PROVISONS.
          </strong>
        </li>{' '}
        <li>
          2.3 Where we are obliged under applicable law to provide the user with
          a cooling-off period, or any other right, and such right is not
          expressly included in these Terms, the user shall be entitled to
          exercise such right in accordance with applicable law, notwithstanding
          anything to the contrary in these Terms (subject to any conditions
          that we are permitted to impose under applicable law).
        </li>
      </UL>
      <UL>
        3. <strong>INFORMATION ABOUT US.</strong>
        <li>
          3.1 www.blueavo.com is a website operated by us. We are registered in
          the Republic of South Africa under the name BlueAvo Proprietary
          Limited, with company registration number 2019/622928/07 and with our
          registered office at 1 Dunbar Flats, 22 Athens Road, Cape Town.
        </li>
        <li>
          3.2 If you have questions concerning the Terms, please contact us via
          email at hello@blueavo.com.
        </li>
      </UL>
      <UL>
        4. <strong>CHANGES TO THESE TERMS.</strong>
        <li>
          4.1 We may revise all or part of these Terms (including by way of
          Additional Terms or policies) at any time by amending the relevant
          pages of the Platform. If you continue to use the Platform following
          receipt of such notice, you will be deemed to have accepted our
          revision of these Terms. You are expected to check the pages from time
          to time in order to take notice of any changes we make, as they are
          legally binding on you from date of posting.{' '}
          <strong>
            It is your responsibility to check this page on a regular basis
          </strong>{' '}
          to ensure that you are aware of these Terms, as updated from time to
          time.
        </li>{' '}
        <li>
          4.2 4.2 The Terms constitute the entire agreement between BlueAvo and
          you in respect of your use of the Platform, and supersedes and
          extinguishes all previous agreements, promises, assurances,
          warranties, representations and understandings between BlueAvo and you
          in respect of your use of the Platform, whether written or oral,
          relating to its subject matter, including but not limited to any and
          all prior versions of the Terms that may have been published on the
          Platform.
        </li>
      </UL>
      <UL>
        5. <strong>CHANGES TO THE PLATFORM.</strong>
        <li>
          We may update and modify the Platform from time to time, and may
          suspend, withdraw, discontinue, or change all or any part of the
          Platform, the BlueAvo Content on the Platform, including that we may
          remove any Content from the Platform, at any time. Please note that
          any of the Content on the Platform may be out of date at any given
          time and at our sole discretion, and we are under no obligation to
          update it.{' '}
          <strong>
            We do not warrant or guarantee that the Platform or any Content will
            be free from errors or omissions.
          </strong>
        </li>
      </UL>
      <UL>
        6. <strong>ACCESSING THE PLATFORM AND REGISTRATION.</strong>
        <li>
          6.1 You may use elements of the Platform by simply visiting our
          website, but access to most of the Platform is available only to
          registered users.
        </li>{' '}
        <li>
          6.2 You must be over the age of 18 to use the Platform.{' '}
          <strong>
            By using the Platform, you represent and undertake that you are over
            the age of 18.
          </strong>
        </li>
        <li>
          6.3 In order to register to use the Platform you must be at least 18
          years of age.{' '}
          <strong>
            By registering to use the Platform, you represent and warrant that
            this is the case.
          </strong>{' '}
          Without prejudice to the foregoing, if reasonably requested by us or
          if required by a Client in relation to use of a particular aspect of
          the Platform, you agree to promptly provide us and/or the relevant
          Client with a certified copy of government issued ID verifying your
          identity including but not limited to your age, photo and address.
        </li>
        <li>
          6.4 You agree that you will provide truthful and accurate information
          when registering to use the Platform. The decision to accept a
          registration is at our discretion and we may revoke a registration at
          any time.
        </li>
        <li>
          6.5 You may not register to use the Platform if you are already
          registered or if you were previously a registered user and we
          cancelled your account as a result of you being in breach of these
          Terms.
        </li>
        <li>
          6.6 To register to use the Platform, you must provide us a unique
          username and password and provide us with certain personal
          information.{' '}
          <strong>
            You are responsible for maintaining the confidentiality of your
            username, password and account information and you must not disclose
            same to any third party. You are solely responsible for all
            activities that occur under your password or account and for any
            access to or use of the Platform by you or any person or entity
            using your password, whether or not such access or use has been
            authorised by you.
          </strong>
        </li>
        <li>
          6.7 We have the right to disable any username or password at any time,
          if in our reasonable opinion you have failed to comply with any of the
          provisions of these Terms.
        </li>
        <li>
          6.8 You must immediately notify us of any unauthorised use of your
          password or account or any other breach of security.{' '}
          <strong>
            We will not be liable for any loss or damage whatsoever resulting
            from the disclosure of your username and/or password contrary to
            these Terms.
          </strong>
        </li>
        <li>
          6.9{' '}
          <strong>
            You are responsible for ensuring that the information we hold about
            you is up to date.
          </strong>{' '}
          Please amend your details via the Platform as appropriate from time to
          time or contact us if you require assistance.
        </li>
        <li>
          6.10{' '}
          <strong>
            Please refer to our Privacy & Cookies Policy for further explanation
            as to how we use the personal information we collect from you.
          </strong>
        </li>
        <li>
          6.11 The Platform is made available to users.{' '}
          <strong>
            We do not guarantee or warrant that the Platform, (including any
            Content), will always be available or be uninterrupted.{' '}
          </strong>{' '}
          Access to the Platform, is permitted on a temporary basis. We may
          suspend, withdraw, discontinue or change all or any part of the
          Platform, or Content without notice.{' '}
          <strong>
            {' '}
            We will not be liable to you if for any reason the Platform, or any
            Content is unavailable at any time or for any period.
          </strong>
        </li>
      </UL>{' '}
      <UL>
        7. <strong>BLUEAVO INTELLECTUAL PROPERTY AND CONTENT.</strong>
        <li>
          7.1{' '}
          <strong>
            You acknowledge that all Intellectual Property rights in and to the
            Platform and all material published or displayed on the Platform, or
            is encountered and/or used as part of any user’s experience of the
            Platform, including but not limited to text, graphics, photos,
            logos, button icons, images, trade marks, audio and audio visual
            clips, databases, data compilations, data and software (but
            excluding any content uploaded by users of the Platform) (together
            the “BlueAvo Content”) are owned by or licensed to us.
          </strong>{' '}
          Those works are protected by Intellectual Property rights laws and
          treaties around the world. All such rights are reserved.
        </li>
        <li>
          7.2 You may not copy, adapt, display, communicate to the public,
          share, modify, download, print or otherwise use any Content except as
          enabled and permitted by the Platform from time to time.
        </li>{' '}
        <li>
          7.3 Unless otherwise expressly provided to the contrary in these Terms
          or any Contract:
        </li>{' '}
        <li>
          7.3.1 You may not use or register any trade marks, trade names or
          other devices which are or incorporate marks which are substantially
          identical or deceptively similar to our or any other user’s trade
          marks or which marks are likely to be associated with the trade marks
          or where such use would take unfair advantage of or be detrimental to
          the distinctive character or the repute of our or any other Client’s
          or Creator’s trade marks.
        </li>
        <li>
          7.3.2 You may not at any time, directly or indirectly, do or cause to
          be done any act or thing in any way impairing or tending to impair,
          weaken, damage or be detrimental to any part of our, or any other
          user’s, rights, title and interest in and to the relevant Intellectual
          Property owner’s Intellectual Property, or the reputation and goodwill
          associated therewith.
        </li>
        <li>
          7.3.3 You may not in any way, directly or indirectly, make
          unauthorised use of our Intellectual Property, or any other user’s
          Intellectual Property or represent that you have any rights of any
          nature in such Intellectual Property or any registrations thereof.
        </li>
        <li>
          7.3.4 You must not directly or indirectly, copy, republish,
          distribute, adapt, modify, alter, decompile, reverse engineer, or
          attempt to derive the source code of or create a derivative of works
          or otherwise attempt to reproduce the paper or digital copies of any
          Content or other materials you have printed off, obtained or
          downloaded from our Platform in any way, and you must not use, share
          or edit any illustrations, photographs, video or audio sequences or
          any graphics separately from any accompanying text, or without
          obtaining the required consents or licenses and acknowledging the
          source of such Content.
        </li>
        <li>
          7.3.5 The status of the contributors of Content as the authors of
          Content must always be acknowledged.
        </li>{' '}
        <li>
          7.3.6 You must not use any part of the Content for commercial purposes
          without obtaining a licence to do so from us or our licensors.
        </li>{' '}
        <li>
          7.4 Without limiting the generality of the provisions of clause 7.3.
          above you undertake not to make any unauthorised use, reproductions or
          copies of any Content, work or material, displayed or made available
          on the Platform, whether directly or indirectly, and to adhere to and
          comply with all policies, conditions of use and rules that may apply
          to the use of such work or material.
        </li>
        <li>
          7.5 If you share, modify, edit, copy, republish, distribute, adapt,
          modify, alter, decompile, reverse engineer, download, print off, or
          use in any other way, any part of the Platform or Content in breach of
          the Terms, your right to use the Platform will cease immediately and
          you must return or, if so instructed, destroy any copies of the
          materials, the Platform or Content that you have made.
        </li>{' '}
        <li>
          7.6{' '}
          <strong>
            You acknowledge that “Africa’s Creative Marketplace” is our pending
            trade mark application in South Africa.
          </strong>
        </li>
      </UL>{' '}
      <UL>
        8. <strong>YOUR USE OF THE PLATFORM AND CONTENT STANDARDS</strong>
        <li>
          8.1 You are responsible for making all arrangements necessary to
          access the Platform. You are also responsible for ensuring that all
          persons who access the Platform through your internet connection are
          aware of these Terms and that they comply with them.
        </li>
        <li>
          8.2 The following standards (“
          <strong>Content Standards</strong>
          ”) apply to your use of the Platform (including any linking to the
          Platform, posting of Briefs, responses or other communications and use
          of any review/feedback/grievance submission mechanism on the
          Platform).
        </li>
        <li>
          8.3 Without limitation, you may not use the Platform, directly or
          indirectly:
        </li>
        <li>
          8.3.1 in any way that is unlawful or fraudulent, or has any unlawful
          or fraudulent purpose or effect;
        </li>
        <li>
          8.3.2 for the purpose of harming or attempting to harm any natural or
          juristic person, and in particular any minors, in any way;
        </li>
        <li>
          8.3.3 to send, knowingly receive, upload, download, publish, use or
          re-use any material which does not comply with the Terms;
        </li>
        <li>
          8.3.4 to send, or procure the sending of, any unsolicited or
          unauthorised electronic communication, advertising or promotional
          material or any other form of similar solicitation (spam);or
        </li>
        <li>
          8.3.5 to knowingly transmit any data or send or upload any material
          that contains viruses, Trojan horses, worms, time-bombs, keystroke
          loggers, spyware, adware or any other harmful programs or similar
          computer code designed to adversely affect the operation of the
          Platform or any computer software or hardware.
        </li>
        <li>8.4 You also agree not to, directly or indirectly:</li>
        <li>
          8.4.1 make unauthorised use of, adapt, reproduce, duplicate, copy,
          modify, alter, decompile, reverse engineer, or attempt to derive the
          source code of or create a derivative of works or otherwise attempt to
          reproduce the Platform or its Contents or re-sell any part of the
          Platform (or any Content within it) in contravention of these Terms;
          and
        </li>
        <li>
          8.4.2 access without authority, interfere with, damage or disrupt: (A)
          any part of the Platform or Content; (B) any equipment or network on
          which the Platform is stored; (C) any software used in the provision
          of the Platform; or (D) any equipment or network or software owned or
          used by any third party.
        </li>
        <li>
          8.5 The Platform may include information and materials uploaded by
          other users, including (without limitation) Briefs, Responses or posts
          to comment boards and discussion forums. This information and these
          materials have not been verified or approved by us. The views
          expressed by other users on the Platform do not represent our views or
          values.{' '}
          <strong>
            To the maximum extent permitted by applicable law, you acknowledge
            that you may be exposed to Content that you may find offensive,
            indecent or objectionable and that, in this respect, you use the
            Platform at your own risk.
          </strong>{' '}
        </li>
        <li>
          8.6 Whenever you make use of the Platform to allow you to upload
          Content to the Platform or make contact with other users of the
          Platform (each a “<strong>Communication</strong>
          ”) you grant us a non-exclusive, royalty-free, worldwide, perpetual
          licence to host, copy, reproduce, store and make available such
          Content and Communication and agree to comply with the Content
          Standards. Unless otherwise expressly stated in these Terms, any
          Content you upload to the Platform will be considered non-confidential
          and non-proprietary, unless otherwise specified by you, and you retain
          all of your ownership rights in your Content,
        </li>
        <li>
          8.7 Subject to applicable law, we have the right to disclose your
          identity to any third party who is claiming that any Content or
          Communication posted or uploaded by you to the Platform constitutes a
          violation of their Intellectual Property rights, or of their right to
          privacy. We have the right to remove any posting you make on the
          Platform if, in our sole opinion, your post does not comply with the
          Content Standards. You are solely responsible for securing and backing
          up your Content.
        </li>
        <li>
          8.8 You must comply with the Content Standards. The Content Standards
          apply to each part of any Content uploaded to the Platform and
          Communication, as well as to its whole.
        </li>
        <li>
          8.9 Communications must be accurate (where they state facts), be
          genuinely held (where they state opinions), and comply with applicable
          law.
        </li>
        <li>
          8.10 Communications must not: contain any material which is defamatory
          of any person; contain any material which is obscene, offensive,
          hateful or inflammatory; promote or contain sexually explicit
          material; promote or contain violence; include gang signs or symbols;
          promote or contain discrimination based on race, sex, religion,
          nationality, disability, sexual orientation or age; infringe any
          copyright, trade secret/know how, database right or trademark of any
          other person; be likely to deceive any person; be made in breach of
          any legal duty owed to a third party, such as a contractual duty or a
          duty of confidence; promote any illegal activity; be threatening, be
          abusive, harmful, vulgar, seditious or otherwise objectionable; abuse
          or invade another's privacy, or cause annoyance, inconvenience or
          needless anxiety; be likely to harass, vilify, upset, embarrass, alarm
          or annoy any other person; be used to impersonate any person, or to
          misrepresent your identity or affiliation with any person; give the
          impression that they emanate from us or are endorsed by us, if this is
          not the case; breach any applicable law; or advocate, promote or
          assist any unlawful act such as (by way of example only) copyright
          infringement or computer misuse.
        </li>
        <li>
          8.11{' '}
          <strong>
            To the maximum extent permitted by applicable law, you warrant and
            undertake that any such Communication complies with the Content
            Standards, and you will be liable to us and indemnify us for any
            breach of that warranty. This means you will be responsible for any
            loss or damage we suffer as a result of your breach of such
            warranty. We will not be responsible, or liable to any third party,
            in respect of the content of any Communication.
          </strong>
        </li>
        <li>
          8.12{' '}
          <strong>
            To the maximum extent permitted by applicable law, you acknowledge
            that your Communications (including but not limited to any Briefs,
            Responses and Productions) may be subject to criticism and/or review
            by the public and as such may receive positive or negative comments
            and reactions, and we are not responsible for any such review or
            comment or any consequences relating to the same.
          </strong>
        </li>
        <li>
          8.13{' '}
          <strong>
            To the maximum extent permitted by applicable law, you acknowledge
            that:
          </strong>
        </li>
        <li>
          8.13.1{' '}
          <strong>
            you are responsible for your interactions with other users;
          </strong>
        </li>
        <li>
          8.13.2{' '}
          <strong>
            we make no representations or warranties as to the conduct of other
            users; and
          </strong>
        </li>
        <li>
          8.13.3{' '}
          <strong>
            we shall not be in any way liable for the conduct of you or any
            other users. You indemnify us against any and all losses, damages,
            costs or expenses (whether direct or indirect) which we may suffer
            or incur and all and any claims which may be brought against us by
            any third party in respect of any loss, liability (whether actual,
            contingent, or otherwise), damage, costs and expenses of any nature
            whatsoever as a consequence of or which may arise from or be
            attributable to your breach of any of the provisions of any Content
            Standard, these Terms or any act or omission by you.
          </strong>
        </li>
        <li>
          8.14 You agree to take reasonable precautions in all interactions with
          other users. Notwithstanding any other provision of these Terms{' '}
          <strong>
            and to the maximum extent permitted by applicable law, the parties
            acknowledge and agree that:
          </strong>
        </li>
        <li>
          8.14.1{' '}
          <strong>
            any information published on the Platform (including, without
            limitation, Briefs and Responses) (the “Platform Information”) will
            be available to users of the Platform;
          </strong>
        </li>
        <li>
          8.14.2{' '}
          <strong>
            the publication of Platform Information by BlueAvo will not amount
            to a breach of any of BlueAvo's obligations under these Terms; and
          </strong>
        </li>
        <li>
          8.14.3{' '}
          <strong>
            if you disclose your Confidential Information to another party on or
            via the Platform/BlueAvo and you wish to impose obligations of
            confidence on the other party in relation to such information, you
            should enter into a separate confidentiality agreement with the
            other party.
          </strong>
        </li>
        <li>
          8.14.4 If you wish to register a complaint concerning a user of the
          Platform, please contact us (or, in respect of Intellectual Property
          infringement, pursuant to the section below).
        </li>
        <li>
          8.15{' '}
          <strong>
            To the maximum extent permitted by applicable law, you warrant that,
            prior to uploading any Communications whatsoever, all necessary
            consents and/or approvals have been obtained from the person in
            respect of whom the Communication relates and/or any governmental
            authority required to approve the disclosure of such Communication.
          </strong>
        </li>
        <li>
          8.16 Failure to comply with the Content Standards, constitutes a
          material breach of the Terms upon which you are permitted to use the
          Platform, and may result in immediate, temporary or permanent
          withdrawal of your right to use the Platform and our taking further
          legal action against you.
        </li>
      </UL>
      <UL>
        9. <strong>COPYRIGHT INFRINGEMENT - TAKEDOWN PROCEDURES</strong>
        <li>
          If you believe that any materials on the Platform infringe your
          copyright or other Intellectual Property right, you may request that
          such materials be removed. This request must bear a signature (or
          electronic equivalent) of the copyright or Intellectual Property
          holder or an authorised representative, and must:
        </li>
        <li>9.1 identify the allegedly infringing materials;</li>{' '}
        <li>
          9.2 indicate where on the Platform the infringing materials are
          located;
        </li>{' '}
        <li>9.3 provide your name and contact information;</li>{' '}
        <li>
          9.4 state that you have a good faith belief that the materials are
          infringing;
        </li>{' '}
        <li>9.5 state that the information in your claim is accurate; and</li>{' '}
        <li>
          9.6 indicate that you are the lawful copyright or Intellectual
          Property owner or are authorised to act on the owner's behalf.
        </li>{' '}
        <li>
          9.7 We may still consider the request if the above information has not
          been provided, but we may contact you for additional information
          before deciding to remove any reportedly infringing materials.
        </li>{' '}
        <li>
          9.8 If you believe that someone has wrongly filed a notice of
          copyright or Intellectual Property infringement against you, you may
          send us a counter-notice in accordance with equivalent requirements.
        </li>{' '}
        <li>
          9.9 Our contact details for copyright or Intellectual Property issues
          relating to the Platform (including, but not limited to, the notices
          and counter-notices) is hello@blueavo.com.
        </li>{' '}
        <li>
          9.10 Please note that in many jurisdictions it is an offence to issue
          a false or misleading request or notice to take down allegedly
          infringing materials. Penalties and/or civil remedies under applicable
          law may apply.
        </li>
      </UL>{' '}
      <UL>
        10. <strong>AVAILABILITY OF THE PLATFORM</strong>{' '}
        <li>
          10.1{' '}
          <strong>
            To the maximum extent permitted by law, the Platform is provided on
            an "as is" basis without any warranties of any kind. We make no
            representations, warranties or guarantees of any kind regarding the
            availability or operation of the Platform or that the Platform will
            be secure, uninterrupted or free of defects.
          </strong>
        </li>{' '}
        <li>
          10.2 Your access to the Platform may be suspended or restricted
          occasionally to allow for maintenance, repairs, upgrades, or the
          introduction of new facilities or services. We will always try to
          limit the frequency and duration of any planned disruption but
          <strong>
            {' '}
            we will to the maximum extent permitted by law not be liable to you
            if for any reason the Platform is unavailable at any time or for any
            period.
          </strong>
        </li>
      </UL>
      <UL>
        11. <strong>NO RELIANCE ON INFORMATION</strong>
        <li>
          11.1 The Content on the Platform is provided for general information
          only. It is not intended to amount to advice upon which you should
          rely. You must obtain professional or specialist advice before taking
          or refraining from, any action on the basis of any Content on the
          Platform.
        </li>{' '}
        <li>
          11.2 Although we make reasonable efforts to update the information on
          the Platform,{' '}
          <strong>
            to the maximum extent permitted by applicable law we make no
            representations, warranties or guarantees, whether express or
            implied, that the Content on the Platform is accurate, complete or
            up-to-date.
          </strong>
        </li>{' '}
        <li>
          11.3 The Platform may contain content provided by persons or entities
          other than us or to which the Platform provides a link, and which
          content we may incorporate into the Content in whole or in part, or
          otherwise make available on or through the Platform and which we will
          identify on the Platform as being provided by third parties.{' '}
          <strong>
            To the maximum extent permitted by applicable law, you acknowledge
            that we have no control over, and accordingly take no responsibility
            and accept no liability for, any inaccuracy, incompleteness,
            insufficiency, unavailability or unreliability of any such Content
            received by us from such entities or Content which we may provide in
            whole or in part.
          </strong>{' '}
          We do not represent or endorse the accuracy or reliability of any such
          Content provided by such other persons or entities or provided by us.{' '}
          <strong>
            To the maximum extent permitted by applicable law, you acknowledge
            that any reliance upon any such content or services shall be at your
            sole risk.
          </strong>
        </li>{' '}
        <li>
          11.4 We may publish Content on or through the Platform, and the
          presentation or publishing of this Content does not create any
          obligations, undertakings, warranties or guarantees of any kind on us.
          We are not obliged and disclaim any obligation to verify that any
          Content is accurate, or complete.{' '}
          <strong>
            Therefore, to the extent permitted by applicable law, we cannot be
            held liable for the quality or accuracy of the Content published on
            or through the Platform or any further Content created therefrom, to
            the maximum extent permitted by applicable law.
          </strong>
        </li>{' '}
        <li>
          11.5{' '}
          <strong>
            To the maximum extent permitted by applicable law, the Content on or
            accessed through the Platform is provided "as is", without any
            conditions, warranties or other terms of any kind, to the maximum
            extent permitted by applicable law. In particular, We make no
            representations, warranties or guarantees, whether express or
            implied as to merchantability, title, non-infringement,
            compatibility, security, accuracy, completeness, sufficiency,
            availability, adequacy, quality, reliability or fitness for any
            particular purpose of the Content available or created through,
            published or incorporated or made available, on or through the
            Platform, to the maximum extent permitted by applicable law.
          </strong>
        </li>{' '}
        <li>
          11.6{' '}
          <strong>
            Accordingly, to the maximum extent permitted by applicable law, we
            provide you with the Platform on the basis that we exclude all
            representations, warranties, conditions and other terms (including,
            without limitation, the conditions implied by applicable law of
            satisfactory quality, fitness for purpose and the use of reasonable
            care and skill) which, but for this legal notice, might have effect
            in relation to the Platform and the Content.
          </strong>
        </li>
      </UL>{' '}
      <UL>
        12. <strong>LINKS TO OTHER PLATFORMS OR THE PLATFORM</strong>{' '}
        <li>
          12.1 The Platform may from time to time offer links to other websites
          or resources from which third party services can be obtained.{' '}
          <strong>
            However, to the maximum extent permitted by applicable law, you
            acknowledge that these other websites and resources are independent
            from us and we make no representations or warranties as to the
            legitimacy, accuracy or quality of such third party services, and we
            do not accept any responsibility for their content, safety,
            practices or privacy policies.
          </strong>{' '}
          These links are provided for your information only. We have no control
          over the contents of third party sites (or any amendments thereto) or
          resources, and{' '}
          <strong>
            to the maximum extent permitted by applicable law, we cannot be held
            liable for any loss caused, or liability incurred by you, as a
            result of your use of such sites and resources, or any outdated,
            inaccurate or incomplete versions of the content contained on such
            sites.
          </strong>{' '}
          The appearance of links to third party sites does not constitute an
          endorsement, sponsorship, affiliation or recommendation of those sites
          by us.
        </li>{' '}
        <li>
          12.2 You may link to our home page, provided you do so in a way that
          is fair and legal and does not damage our reputation or take advantage
          of it. You must not establish a link in such a way as to suggest any
          form of association, approval or endorsement on our part where none
          exists. You must not establish a link to the Platform in any website
          or application that is not owned by you or to a website that you are
          not duly authorised to represent (save where expressly authorised
          under these Terms). The Platform must not be framed on any other
          website or application, nor may you create a link to any part of the
          Platform other than the home page (save where expressly authorised
          under these Terms).
        </li>{' '}
        <li>
          12.3 The website in which you are linking must comply in all respects
          with the Content Standards.
        </li>{' '}
        <li>
          12.4 We reserve the right to withdraw linking permission without
          notice.
        </li>
      </UL>{' '}
      <UL>
        13. <strong>COMPUTER VIRUSES</strong>
        <li>
          13.1 We do not guarantee that the Platform will be secure or free from
          bugs, viruses or any other harmful or potentially harmful destructive
          code.
        </li>{' '}
        <li>
          13.2 We will use reasonable endeavours to ensure that no part of the
          Platform will contain or spread any bugs, viruses or other malicious
          code. However, you are responsible to ensure that computers and other
          devices used to access the Platform run up-to-date anti-virus software
          as a precaution, and you are advised to virus-check any materials
          downloaded from the Platform and regularly check for the presence of
          viruses and other malicious code. You should use your own virus
          protection software. You are responsible for configuring your
          information technology, computer programmes and platform in order to
          access the Platform.
        </li>{' '}
        <li>
          13.3{' '}
          <strong>
            To the maximum extent permitted by applicable law, we will not be
            liable for any loss or damage caused by a virus or other
            technologically harmful material that may infect your computer
            equipment, computer programs, data or other proprietary material due
            to your use of the Platform or to your downloading of any content
            from it, or from any website linked to it.
          </strong>
        </li>
        <li>
          13.4 You must not misuse the Platform by knowingly or negligently
          introducing viruses, trojans, worms, logic bombs, time-bombs,
          keystroke loggers, spyware, adware or other material which is
          malicious or technologically harmful to the Platform, our server, or
          our service provider’s servers, computers or database connected to the
          Platform (“
          <strong>Our system</strong>
          ”). You must not attempt to gain unauthorised access to the Platform
          or Our system. You must not attack the Platform or Our system via a
          denial-of-service attack, a distributed denial-of service attack or
          otherwise. Should you commit a breach of this provision we will report
          such breach to the relevant law enforcement authorities, and we will
          co-operate with those authorities by disclosing your identity to them.
          In the event of such a breach, your right to use the Platform will
          cease immediately.
        </li>
      </UL>{' '}
      <UL>
        14.{' '}
        <strong>
          LIMITATION OF LIABILITY - PLEASE READ THIS SECTION CAREFULLY
        </strong>
        <li>
          14.1{' '}
          <strong>
            To the maximum extent permitted by applicable law, nothing in these
            Terms excludes or limits our liability for death or personal injury
            arising from our negligence, our fraud or fraudulent
            misrepresentation, or any other liability that cannot be excluded or
            limited under applicable law.
          </strong>
        </li>
        <li>
          14.2{' '}
          <strong>
            Without limitation to the generality of the foregoing, and to the
            maximum extent permitted by applicable law, it is a condition of
            accessing the Platform that you accept and agree we shall not be
            liable for any harmful effect that accessing the Platform may have
            on you, and you agree that you access and use the Platform entirely
            at your own risk. Without limitation to the generality of the
            foregoing, we, all other parties involved in creating, producing,
            maintaining or delivering the Platform or any Content, and all of
            our group companies and the officers, directors, employees,
            shareholders or agents of any of them, exclude all liability and
            responsibility for any amount or kind of loss or damage that may
            result to you or a third party (including without limitation, any
            direct, indirect, special, punitive or consequential loss or
            damages, or any loss of use, income, profits, goodwill, data,
            contracts, use of money, or loss or damages arising from or
            connected in any way to business interruption, and whether in
            delict, tort contract, breach of statutory duty or otherwise) in
            connection with the Platform or any Content in any way or in
            connection with the use, inability to use or the results of use of
            the Platform or any Content, any websites linked to the Platform or
            the material on such websites, including but not limited to loss or
            damage due to viruses that may infect your computer equipment,
            software, data or other property on account of your access to, use
            of, or browsing the Platform or any BlueAvo Content or your
            downloading of any material from the Platform or any websites linked
            to the Platform.
          </strong>
        </li>{' '}
        <li>
          14.3{' '}
          <strong>
            In particular, without limitation to the generality of the
            foregoing, and to the maximum extent permitted by applicable law,
            whether in contract, delict, tort (including, without limitation,
            negligence), breach of statutory duty, or otherwise:
          </strong>
        </li>{' '}
        <UL>
          <li>
            14.3.1{' '}
            <strong>
              we will not be liable to you for any loss or damage arising under
              or in connection with:
            </strong>
          </li>{' '}
          <li>
            14.3.1.1{' '}
            <strong>
              use of, or inability to use, the Platform or any linked website
            </strong>
          </li>{' '}
          <li>
            14.3.1.2{' '}
            <strong>
              use of or reliance on any content displayed on or produced via the
              Platform or any linked website;
            </strong>
          </li>{' '}
          <li>
            14.3.1.3 <strong>business interruption;</strong>
          </li>{' '}
          <li>
            14.3.1.4 <strong>corruption of content or data;</strong>
          </li>{' '}
          <li>
            14.3.1.5{' '}
            <strong>
              a virus, distributed denial-of-service attack, or other
              technologically harmful material that may infect your computer
              equipment, computer programs, data or other proprietary material
              due to your use of the Platform or to your downloading of any
              content on or through it or on any website linked to it; or
            </strong>
          </li>{' '}
          <li>
            14.3.1.6{' '}
            <strong>
              the acts or omissions of any other user of the Platform, including
              (without limitation) infringement of Intellectual Property or
              disclosure of Confidential Information;
            </strong>
          </li>{' '}
        </UL>
        <li>
          14.3.2{' '}
          <strong>
            To the maximum extent permitted by applicable law, we will have no
            liability to you for any indirect or consequential loss or damage of
            any kind or for any loss of revenue, profit, sales, business,
            revenue, anticipated savings, business opportunity, contracts, data,
            reputation or goodwill (whether direct or indirect) arising out of
            or in connection with these Terms and/or the Platform; and
          </strong>
        </li>{' '}
        <li>
          14.4 <strong>In any event and subject to</strong>
        </li>{' '}
        <li>
          14.1
          <strong>
            , if notwithstanding the aforegoring and to the maximum extent
            permitted by applicable law, we have any liability to you in respect
            of any and all loss and/or damage arising out of or in connection
            with these Terms and/or the Platform, our total liability in respect
            thereof shall in no circumstances exceed the greater of: (1) in
            relation to an applicable Contract, the BlueAvo Fee received by us
            which is directly related to your use of the Platform giving rise to
            your claim; and (2) ZAR 1 000 (one thousand Rand).
          </strong>
        </li>{' '}
        <li>
          14.5{' '}
          <strong>
            Except as expressly set out in these Terms and to the maximum extent
            permitted by law, we make no representations, warranties or
            guarantees of any kind in respect of the Platform or any content
            available through the Platform and all conditions, warranties and
            representations express or implied are hereby excluded to the full
            extent permitted by law.
          </strong>
        </li>{' '}
        <li>
          14.6{' '}
          <strong>
            If you are a consumer under any applicable law and to the maximum
            extent permitted by applicable law (including without limitation the
            South African Consumer Protection Act, 2008), nothing in these Terms
            is intended to remove your rights under such laws, including to
            statutory guarantees or warranties that may apply to your use of the
            Platform. If we are entitled to limit the remedies available to you
            for breach of such guarantees or warranties, we expressly limit our
            liability as set out in this section to the maximum extent permitted
            by applicable law. If you are a consumer (as for the purposes of
            such laws) and any of the services supplied by us are not services
            of a kind ordinarily acquired for personal, domestic or household
            use or consumption, our liability for a failure to comply with a
            guarantee or warranty under the such laws in relation to those
            services is limited to, at our option, to the extent permitted under
            applicable law: (i) the supply of the services again; or (ii) the
            payment of the cost of having the services supplied again. In other
            jurisdictions, warranties, guarantees and conditions may apply that
            we cannot legally exclude. If that is true in your jurisdiction,
            then to the extent permitted by such laws, we limit our liability
            for any claims under those warranties, guarantees or conditions to
            either supplying you the services again or paying the cost of having
            the services supplied again.
          </strong>
        </li>
      </UL>{' '}
      <UL>
        15. <strong>TERMINATION OR SUSPENSION</strong>{' '}
        <li>
          In addition to our other remedies under these Terms or applicable law,
          we may, in our sole discretion, terminate or temporarily suspend your
          password, account (or any part thereof) or use of the Platform without
          notice if you are in breach of these Terms or if we believe in our
          reasonable discretion that your use of the Platform is unsuitable in
          any way.
        </li>
      </UL>
      <UL>
        16. <strong>ASSIGNMENT</strong>{' '}
        <li>
          16.1{' '}
          <strong>
            To the maximum extent permitted by applicable law, we may at any
            time assign, cede, delegate, mortgage, charge, subcontract,
            sublicense or otherwise transfer any or all of our rights and
            obligations under these Terms or any Contract without your prior
            written consent, to the greatest extent permitted under applicable
            law.
          </strong>
        </li>
        <li>
          16.2 You may not assign, cede, delegate, mortgage, charge,
          subcontract, sublicense or otherwise transfer any of your rights or
          obligations under these Terms or any Contract without our prior
          written consent.
        </li>
      </UL>
      <UL>
        17. <strong>MISCELLANEOUS</strong>{' '}
        <li>
          17.1 If any provision or part of a provision of these Terms is held to
          be illegal, invalid, unenforceable or against public policy pursuant
          to a final adjudication by a court of competent jurisdiction, such
          provision will be deemed severed from these Terms and the remainder of
          these Terms will remain in full force and effect.
        </li>{' '}
        <li>
          17.2 These Terms, and the documents referred to in them, constitute
          the entire agreement between you and us in relation to the subject
          matter of these Terms and supersede and extinguish any prior agreement
          in respect of the same.
        </li>{' '}
        <li>
          17.3 Subject to clause 17.4 below, you agree that these Terms (and
          where applicable, any Contract), the subject matter and formation, are
          governed by the laws of South Africa. You and we hereby both consent
          and submit to the exclusive jurisdiction of the High Court of South
          Africa, Cape Town, having exclusive jurisdiction over any disputes
          arising in respect of or in relation to these Terms and your use of
          the Platform.
        </li>
        <li>
          17.4 Nothing in clause 17.3 above shall (or shall be construed so as
          to) limit our right to bring a suit, action or proceeding (“
          <strong>Proceeding</strong>
          ”) against you in the courts of any country in which you have assets
          or in any other court of competent jurisdiction nor shall Proceedings
          we institute in any one or more jurisdictions preclude us from
          instituting Proceedings in any other jurisdiction (whether
          concurrently or not) if and to the extent permitted by applicable law.
        </li>{' '}
        <li>
          17.5{' '}
          <strong>
            If there are any provisions in these Terms which may qualify as a
            penalty in terms of the Conventional Penalties Act, 1962 (as
            amended) in relation to any act or omission by you, to the maximum
            extent permitted by applicable law, such provisions shall not be
            deemed to preclude us from recovering damages in lieu of the
            relevant penalty.
          </strong>
        </li>{' '}
        <li>
          17.6 <strong>Interpretation</strong> In addition to the definitions in
          clause 1, for the purposes of These Terms, the following rules of
          interpretation shall apply, unless the context clearly requires
          otherwise:
        </li>{' '}
        <li>17.6.1 a reference to:</li>{' '}
        <li>
          17.6.1.1 any one gender, whether masculine, feminine or neuter,
          includes the other two;
        </li>{' '}
        <li>17.6.1.2 a party includes its successors or permitted assigns;</li>{' '}
        <li>
          17.6.1.3 any number of days prescribed shall be determined by
          excluding the first and including the last day or, where the last day
          is a day that is not a business day, the next business day;
        </li>{' '}
        <li>
          17.6.1.4 a person includes, without being limited to, any individual,
          body corporate, unincorporated association or other entity recognised
          under any law as having a separate legal existence or personality;
        </li>{' '}
        <li>
          17.6.1.5 a statute or statutory provision include any subordinate
          legislation made from time to time under that statute or provision and
          references to a statute or statutory provision include that statute or
          provision as from time to time modified or re-enacted as far as such
          modification or re-enactment applies, or is capable of applying, to
          these Terms or any transaction entered into in accordance with these
          Terms;
        </li>{' '}
        <li>
          17.6.2 any word or expression defined in, and for the purposes of,
          these Terms shall, if expressed in the singular, include the plural
          and vice versa, and a cognate word or expression shall have a
          corresponding meaning;
        </li>{' '}
        <li>
          17.6.3 if a definition confers substantive rights or imposes
          substantive obligations on a party, such rights and obligations shall
          be given effect to and are enforceable as substantive provisions of
          these Terms, notwithstanding that they are contained in that
          definition;
        </li>{' '}
        <li>
          17.6.4 no rule of construction shall be applied to the disadvantage of
          a party to these Terms because that party was responsible for or
          participated in the preparation of these Terms or any part of them;
        </li>{' '}
        <li>
          17.6.5 all the headings and sub-headings in these Terms are for
          convenience only and are not to be taken into account for the purposes
          of interpreting them;
        </li>{' '}
        <li>
          17.7 where the words <strong>include(s)</strong>,{' '}
          <strong>including</strong> or
          <strong>in particular</strong> followed by specific examples shall be
          construed by way of example or emphasis only and shall not be
          construed, nor shall it take effect, as limiting the generality of any
          preceding words, and the eiusdem generis rule is not to be applied in
          the interpretation of such specific examples or general words and
          where the context permits, the words <strong>other</strong> and
          <strong>otherwise</strong> are illustrative and shall not be construed
          eiusdem generis with or limit the sense of the words preceding them
          where a wider construction is possible; and
        </li>{' '}
        <li>
          17.8 a term defined in a particular clause, unless it is clear from
          the clause in question that application of the term is to be limited
          to the relevant clause, bears the meaning ascribed to it for all
          purposes of these Terms, notwithstanding that that term has not been
          defined in clause 1 and, where there is any inconsistency between any
          term defined in clause 1 and any term defined in any other clause in
          these Terms, then, for the purposes of construing such clause, the
          term as defined in such clause prevails.
        </li>
        <strong>
          THE CLAUSES BELOW APPLY SPECIFICALLY TO POSTING BRIEFS AND RESPONSES
          AND TO CONTRACTS
        </strong>{' '}
      </UL>
      <UL>
        18. <strong>POSTING BRIEFS AND EXCLUSIVITY.</strong>{' '}
        <li>
          18.1{' '}
          <strong>
            By creating and posting a Brief, a Client warrants to BlueAvo that,
            to the maximum extent permitted by applicable law:
          </strong>
        </li>{' '}
        <li>
          18.1.1 <strong>it intends in good faith to select a Response;</strong>
        </li>{' '}
        <li>
          18.1.2{' '}
          <strong>
            the Brief describes its requirements as accurately as possible and
            in sufficient detail for the Creator to determine whether the
            Creator Payment will be adequate remuneration;
          </strong>
        </li>
        <li>
          18.1.3{' '}
          <strong>
            it has the authority and funds to pay the Budget and any Taxes; and
          </strong>
        </li>{' '}
        <li>
          18.1.4{' '}
          <strong>
            where it posts materials as part of a Brief that would ordinarily be
            classified as a Client's Intellectual Property, trade secrets/know
            how, product specifications or anything else a Client would seek to
            classify as its Confidential Information, the Client has reviewed
            all such materials and acknowledges that posting the materials to a
            Brief may make them publicly available to Creators.
          </strong>
        </li>{' '}
        <li>
          18.2 If a Client posts a Brief using the Platform the Client agrees
          that up to and including the Response Deadline that:
        </li>{' '}
        <li>
          18.2.1 the Brief shall be exclusive to the Platform and the Client
          will only solicit responses to the Brief via the Platform.
        </li>{' '}
        <li>
          18.2.2 without limiting the clause 18.2.1 above, the Brief shall not
          appear on or be communicated via any other websites, platforms or
          third party services save on a solely promotional basis;
        </li>{' '}
        <li>
          18.2.3 any promotion referred to in clause 18.2.2 above will include a
          link to the Platform as the exclusive point of contact for responses
          to the Brief.
        </li>{' '}
        <li>
          18.3 If a Client does not accept any Responses received to a Brief
          even though the Responses satisfied the selection criteria, then the
          Client shall nonetheless pay the BlueAvo Fee to BlueAvo.
        </li>{' '}
        <li>
          18.4{' '}
          <strong>
            In preparing and posting any Brief, the Client acknowledges that it
            has read and understood these Terms, and in particular, that it is
            aware of and understands clauses 23.18, 27.2 and 27.5. Please
            contact us at hello@blueavo.com should you require additional
            clarity regarding such provisions.
          </strong>
        </li>
      </UL>{' '}
      <UL>
        19. <strong>RESPONDING TO BRIEFS.</strong>{' '}
        <li>
          19.1 Some Briefs may be limited in their availability to certain
          Creators only (e.g. based on location, quality, or due to
          confidentiality).
        </li>{' '}
        <li>
          19.2 A Creator must post its Response prior to the Response Deadline.
          However, we reserve the right, at our sole discretion, to accept or
          refuse to accept late Responses. All dates and times are Central
          African Time (CAT) unless otherwise stated.{' '}
          <strong>
            It is the Creator's responsibility to convert the time and date to
            its local time zone.
          </strong>
        </li>{' '}
        <li>
          19.3 By posting a Response, a Creator offers to contract with the
          relevant Client and us (to the extent applicable) for the production
          and delivery of the Production on the terms and conditions set out in
          the Brief and the terms and conditions set out herein.
        </li>{' '}
        <li>
          19.4 Creator must not disclose its Response to any third party unless
          such third party is directly involved in the Creator's production.
        </li>{' '}
        <li>
          19.5 Creator must not include any materials in a Response which
          incorporate work from any previously commissioned BlueAvo Briefs that
          are yet to be form part of the public domain or which Creator does not
          otherwise have the necessary licence, rights, consents, approvals and
          permissions to use.
        </li>{' '}
        <li>
          19.6 Creator must keep all Briefs confidential and shall not use or
          disclose any Brief for any purpose other than to provide a Response,
          and to disclose the Brief to Creator’s personnel and contractors
          involved in the Response (on a need-to-know and confidential basis
          only). For certain Briefs, the Client may require each Creator that
          wishes to view the Brief to enter into a non-disclosure and
          confidentiality agreement or other form of agreement protecting the
          Confidential Information and Intellectual Property of the Client
          directly with the Client through the Platform.{' '}
          <strong>
            The Creator acknowledges that if it chooses to enter into any such
            non-disclosure agreement, the relevant Client will be able to
            enforce the provisions thereof against the Creator directly.
          </strong>
        </li>
      </UL>{' '}
      <UL>
        20. <strong>SELECTION OF BRIEFS AND CONTRACTING.</strong>{' '}
        <li>
          20.1 Except as set out in the Brief, BlueAvo will notify a Creator via
          the Platform within 30 days of the Response Deadline if that Creator's
          Response was successful or not. If any Creator has not received a
          response within 30 days of the Response Deadline, the Creator may
          request in writing that its Response be removed from consideration.
        </li>{' '}
        <li>
          20.2 If we or the Client are unable to communicate with the Creator
          (e.g. to select or commission them) within 24 hours of such
          notification, the Client may withdraw its selection of the Creator's
          Response and commission another Creator{' '}
          <strong>without liability of us or the Client to the Creator.</strong>
        </li>{' '}
        <li>
          20.3{' '}
          <strong>
            To the maximum extent permitted by applicable law, neither we nor
            the Client will have any liability to a Creator in respect of a
            failure for any reason to select any Response.
          </strong>
        </li>{' '}
        <li>
          20.4 By notifying a Creator that Client has selected the Creator's
          Response, a Client accepts the Creator’s offer to contract referred to
          in clause 19.3 above with the effect that a binding contract arises
          between the Creator, the Client and us (to the extent applicable) on
          the terms and conditions set out in the Brief, the Response and these
          Terms (including without limitation clauses 18 to 32 (both inclusive).
        </li>
        <li>
          20.5 Clients must keep all Responses confidential and shall not use or
          disclose any Responses for any purpose other than to select a
          Response, or to disclose the Responses to parties associated with the
          Brief and the Client's professional advisors and related entities,
          agents and personnel and professional representatives (on a
          need-to-know and confidential basis only). For certain Responses, the
          Creator may require the Client to enter into a non-disclosure
          agreement or other form of agreement protecting the Confidential
          Information and Intellectual Property of the Creator directly with the
          Creator through the Platform.
          <strong>
            The Client acknowledges that if it chooses to enter into any such
            non-disclosure agreement, the relevant Creator will be able to
            enforce the provisions thereof against the Client directly.
          </strong>
        </li>{' '}
        <li>
          20.6 Once a Response has been accepted, Client and Creator consent to
          us providing each of Client and Creator's contact details to each
          other.
        </li>{' '}
        <li>
          20.7 Notwithstanding clause 20.6 above, Client and Creator must ensure
          that all ongoing communication related to any Brief, Response or
          Production is completed through BlueAvo via the messaging and other
          production management tools provided, so that we have a record of this
          communication for Client and Creator's protection.
        </li>{' '}
        <li>
          20.8{' '}
          <strong>
            To the maximum extent permitted by applicable law, the Client
            warrants that:
          </strong>
        </li>{' '}
        <li>
          20.8.1{' '}
          <strong>
            Client has all necessary rights, licences, permissions and consents
            to grant the Licence;
          </strong>
        </li>
        <li>
          20.8.2{' '}
          <strong>
            the Client Assets and the Licence, and our exercise of the Licence,
            shall not infringe the rights, including (without limitation) the
            Intellectual Property rights, of any third party; and
          </strong>
        </li>{' '}
        <li>
          20.8.3{' '}
          <strong>
            the Client Assets shall not include anything which is or may
            reasonably be regarded as offensive, blasphemous, obscene,
            defamatory or otherwise unlawful.
          </strong>
        </li>{' '}
      </UL>
      <UL>
        21. <strong>CONTRACT FORMATION.</strong>{' '}
        <li>
          21.1 Upon the notification by a Client that it has selected a Response
          in accordance with the Terms, the Client and Creator and us (to the
          extent applicable) will automatically enter into a contract on the
          terms of the Brief, the Response and these Terms (including without
          limitation clauses 18 to 32 (both inclusive)) (
          <strong>Contract</strong>
          ).
        </li>{' '}
        <li>
          21.2{' '}
          <strong>
            If you do not wish to enter into a binding legal agreement, you must
            not post a Brief or a Response.
          </strong>
        </li>{' '}
        <li>
          21.3 We are not the service provider or the customer in relation to
          the Production and,{' '}
          <strong>
            to the maximum extent permitted by applicable law, our
            responsibility and liability under the Contract is limited to
            facilitating the transaction, including certain payments.
          </strong>{' '}
          The Client hereby appoints us to facilitate the transaction, including
          certain payments in consideration for which the Client will pay us the
          BlueAvo Fee.
        </li>{' '}
        <li>
          21.4 Unless the contrary intention is expressed, if there is any
          conflict or inconsistency between any of one or more of:
        </li>{' '}
        <li>
          21.4.1 any provision of the Brief identified as an Additional Term
          (provided such term is located under the designated heading of
          “Additional Terms”), except for any provision that impacts any right
          or obligation of BlueAvo and BlueAvo’s prior written consent was not
          obtained;
        </li>{' '}
        <li>21.4.2 the remaining provisions of the Contract;</li>{' '}
        <li>21.4.3 the remaining provisions of the Brief;</li>{' '}
        <li>
          21.4.4 the Response, then to the extent of such conflict or
          inconsistence, the order of precedence among them will be the order
          listed above. However, any provision of the Response expressly stated
          in the Response to amend any provision of the Brief located under the
          designated heading of “Additional Terms” in the Brief will have the
          highest level of precedence except where such provision of the
          Response impacts any right or obligation of BlueAvo and BlueAvo’s
          prior written consent was not obtained.
        </li>{' '}
        <li>
          21.5 The Contract will endure for the period specified in the Brief,
          or if no period is specified, then until all obligations in respect of
          delivery and acceptance of the Production and payment of all costs,
          fees and expenses have been complied with.
        </li>{' '}
        <li>
          21.6 Notwithstanding the formation of the Contract, each Client and
          Creator will nonetheless be separately bound by these Terms (except to
          the extent this would result in duplication of any obligations e.g.
          payment obligations).
        </li>{' '}
        <li>
          21.7{' '}
          <strong>
            Please note that you may be entitled to a cooling off period under
            applicable law in which case you may terminate the Contract in this
            period.
          </strong>
        </li>
      </UL>{' '}
      <UL>
        22. <strong>PRODUCTION.</strong>
        <li>
          22.1 Subject to confirmation by us that we have received the Budget in
          accordance with clause 23, the Creator must produce and deliver the
          Production in accordance with the Contract (including, the budget and
          production schedule).
        </li>{' '}
        <li>
          22.2 Without limiting clause 6.1 above, the Creator agrees to provide
          each Production with reasonable skill, care and diligence and in
          accordance with:
        </li>{' '}
        <li>22.2.1 good industry practice;</li>{' '}
        <li>22.2.2 all applicable laws;</li> <li>22.2.3 the relevant Brief;</li>{' '}
        <li>22.2.4 the Creator's Response;</li> <li>22.2.5 these Terms; and</li>{' '}
        <li>22.2.6 the Client's reasonable instructions and specifications.</li>{' '}
        <li>
          22.3 The Creator must provide all facilities, assets, personnel and
          other resources necessary to provide each Production unless otherwise
          agreed with the Client.
        </li>{' '}
        <li>
          22.4 The Creator agrees to provide, at no additional cost to the
          Client, up to 3 rounds of changes for offline video edits, up to 3
          rounds of changes for online video edits, and up to 2 rounds of
          changes for photography edits, as requested by the Client, in respect
          of each Production, unless otherwise agreed in writing or stated in
          the Brief.
        </li>
        <li>
          22.5 The Creator will supply digitally uploaded files of the
          Production in accordance with the deliverables set out in the Brief,
          within the timeframe specified in the Brief.
        </li>{' '}
        <li>
          22.6{' '}
          <strong>
            The Creator understands and acknowledges that where a production
            involves domestic or international travel, that this must be
            factored into the Creator Payment (for both crew and equipment) and
            that the Creator is responsible for obtaining any required visas and
            local filming permits.
          </strong>
        </li>
      </UL>{' '}
      <UL>
        23. <strong>FEES, PAYMENT AND ACCEPTANCE.</strong>{' '}
        <li>
          23.1 In consideration for the provision of the Production, the Client
          shall pay the Creator Fees, any Overages and any other applicable
          costs and expenses payable to the Creator.
        </li>{' '}
        <li>
          23.2 The Creator Fees are inclusive of all costs of production work to
          produce the Production, including any film production, artwork,
          design, photography, graphics, recording, editing and printing; all
          materials used by or on behalf of the Creator to produce the
          Production and/or provide the services; and all overheads or
          administration costs of the Creator, including all costs of telephone
          calls, printing and stationery, delivery charges and postage costs,
          except to the extent stated otherwise in the Brief, the Response or
          agreed otherwise between the Client and the Creator in writing.
        </li>{' '}
        <li>
          23.3 In consideration for facilitating the transaction between the
          Client and the Creator, the Client will pay to us the BlueAvo Fees.
        </li>{' '}
        <li>
          23.4 Overages that are an increase in the original scope of the Brief,
          (e.g. additional deliverables, additional edits), will attract BlueAvo
          Fees at the same rate and service level as the original Brief and
          Creator Payment. Overages which are not an increase in original scope
          of the Brief (e.g. incidentals, usage extensions) will attract BlueAvo
          Fees at 10% for processing and payment.
        </li>{' '}
        <li>
          23.5 Client must pay the Budget, any Overages and any other amounts
          payable by Client directly to us in accordance with the Contract.
        </li>{' '}
        <li>23.6 Client must pay to us:</li>{' '}
        <li>
          23.6.1 the Budget by the due date as specified via the Platform.
          BlueAvo reserves the right to determine (on a case by case basis)
          whether new Clients are required to pay a percentage of or the full
          Budget prior to the publication of any Brief;
        </li>{' '}
        <li>
          23.6.2 any Overages and the applicable additional BlueAvo Fee, on
          agreement of such Overages between the Client and the Creator; and
        </li>
        <li>
          23.6.3 any costs and expenses set out in the Brief in accordance with
          the Brief (including, without limitation, costs of hard drive and
          courier in relation to rushes where specified in the Brief), using the
          payment method and currency stipulated via the Platform or applicable
          invoice (which may be updated from time to time).
        </li>{' '}
        <li>
          23.7{' '}
          <strong>
            Client and Creator acknowledge that we will retain the BlueAvo Fee
            and following instruction from Client (via the Platform) will
            release the applicable Creator Payment (which comprises the Budget
            less the BlueAvo Fee) to the relevant Creator:
          </strong>
        </li>{' '}
        <li>
          23.7.1{' '}
          <strong>
            on final acceptance of the Production (or where acceptance is deemed
            to have occurred - see clause 23.10 below); or
          </strong>
        </li>
        <li>
          23.7.2{' '}
          <strong>
            if the Client is eligible, via progress payments, in accordance with
            the Brief and the prescribed payment schedule using the Platform, in
            each case provided such amounts have been received by us in our bank
            account in full and clear funds.
          </strong>
        </li>{' '}
        <li>
          23.8{' '}
          <strong>
            In relation to payments referred to in clause 23.7.2 above, and to
            the maximum extent permitted by applicable law, the Client and
            Creator acknowledge that the Client will be responsible for the
            release of payments,
          </strong>{' '}
          including progress payments, based on the Creator's achievement of the
          agreed milestones and/or on final acceptance (as applicable) by
          marking the Brief as complete on the Platform (or where acceptance is
          deemed to have occurred - see clause 23.10 below). Any costs incurred
          by the Creator prior to release of payments are solely at the
          Creator's own risk.
        </li>{' '}
        <li>
          23.9{' '}
          <strong>
            In relation to any Overages and other costs and expenses that the
            Client has agreed to pay to the Creator, Client and Creator
            acknowledge that we will retain the BlueAvo Fee (in relation to the
            Overages)
          </strong>{' '}
          and following instruction from Client (via the Platform) will release
          the applicable amounts to the relevant Creator, provided such amounts
          have been received by us in our bank account in full and clear funds.
        </li>{' '}
        <li>
          23.10{' '}
          <strong>
            To the extent permitted under applicable law, Client warrants that
            it will acknowledge final acceptance of the Production (acting
            reasonably and in good faith) by marking the relevant Brief as
            complete via the Platform.
          </strong>{' '}
          If the Client fails to do so, Client agrees that final acceptance
          shall have been deemed to occur: (i) if Client does not reject the
          Production within 21 days of receipt of the final online edit of
          Production; or (ii) on any use by Client of the Production (other than
          considering whether the Production meets the relevant acceptance
          criteria).{' '}
          <strong>
            Client acknowledges that it will only be entitled to reject the
            Production in the event of breach of the Contract by the Creator or
            if the Client acting reasonably is of the opinion that the
            Production does not substantially accord with the Brief and
            subsequent directions given in respect of the Production in
            accordance with the Contract.
          </strong>{' '}
          If the Client rejects the Production, it shall do so in writing (via
          the Platform) within the time period specified above, and give
          sufficiently detailed reasons for the rejection. Unless otherwise
          required under applicable law, the Creator shall make all necessary
          changes to the Production to render same acceptable, in consultation
          with the Client, and shall submit to the Client the revised Production
          by the end of such period. The provisions of this clause 23.10 will
          apply mutatis mutandis until such time as the Production is accepted
          or deemed accepted, unless otherwise required by applicable law.
        </li>{' '}
        <li>
          23.11{' '}
          <strong>
            To the maximum extent permitted by applicable law, Client is solely
            responsible for conducting appropriate due diligence and ensuring
            the suitability of the Creator prior to their engagement,
          </strong>{' '}
          and a lack of experience or skill on behalf of the relevant Creator
          will not (by itself) entitle Client to reject a Production.
        </li>{' '}
        <li>
          23.12 If Client fails to make any payment due under the Contract by
          the due date for payment, then, without limiting our remedies, Client
          agrees to pay interest on the overdue amount at the rate of 2% per
          annum above our primary bank's standard overdraft rate. Such interest
          shall accrue on a daily basis from the due date until actual payment
          of the overdue amount, whether before or after judgment. Client agrees
          to pay the interest together with the overdue amount.
        </li>{' '}
        <li>
          23.13{' '}
          <strong>
            Client agrees to make all payments under the Contract without
            set-off, withholding or deduction of any amounts including in
            respect of any Taxes.
          </strong>{' '}
          If any such withholding or deduction is required by law, Client agrees
          to, when making the payment to which the withholding or deduction
          relates, pay to us such additional amount as will ensure that we
          receive the same total amount that we would have received if no such
          withholding or deduction had been required. In addition, where
          required to be paid by law, the Client must provide to us a copy of
          the certificate of withholding (or analogous document).
        </li>{' '}
        <li>
          23.14{' '}
          <strong>
            To the maximum extent permitted by applicable law, Client and
            Creator acknowledge that:
          </strong>
        </li>{' '}
        <li>
          23.14.1{' '}
          <strong>
            the payments under the Contract of all amounts payable to BlueAvo
            are exclusive of Taxes which, if applicable, shall be payable by
            Client;
          </strong>
        </li>{' '}
        <li>
          23.14.2{' '}
          <strong>
            the payments under the Contract of all amounts payable to the
            Creator are exclusive of Taxes which, if applicable, shall be
            payable by Client, unless otherwise stated in a Brief;
          </strong>
        </li>{' '}
        <li>
          23.14.3{' '}
          <strong>
            the Creator Payment shall be paid to Creator in the currency stated
            on the Brief (or as otherwise advised by us) by such method as
            stated on the Platform (which may be updated from time to time);
          </strong>
        </li>{' '}
        <li>
          23.14.4{' '}
          <strong>
            we have no responsibility to Creator whatsoever in relation to the
            impact of exchange rate variations, applicable Taxes or payment
            gateway fees and charges, on the amounts that the Creator is owed
            under the Contract.
          </strong>
        </li>{' '}
        <li>
          23.15 Notwithstanding clause 23.13, in the event a Client determines
          that it is required by law to withhold any Taxes from any sums payable
          to us, the Client is obligated to notify us in advance, setting out
          the legal basis for the withholding of these sums.
        </li>{' '}
        <li>
          23.16{' '}
          <strong>
            To the maximum extent permitted by applicable law, Client further
            acknowledges that, once the Creator Payment (or any portion of it)
            is released to the Creator, the Creator Payment is non-refundable
            other than as expressly set out in the Contract and we shall have no
            liability to Client in respect of the same.
          </strong>{' '}
          Should Client wish to pursue any claim for a refund, Client must
          institute a claim against Creator directly.{' '}
          <strong>
            To the maximum extent permitted by applicable law, Client is solely
            responsible for undertaking due diligence in respect of the relevant
            Creator and/or Production to ensure its compliance with the Contract
            prior to triggering progress payments and/or acknowledging final
            acceptance.
          </strong>
        </li>{' '}
        <li>
          23.17{' '}
          <strong>
            To the maximum extent permitted by applicable law, Creator warrants
            that it is entitled to be the sole recipient of the Creator Payment
            or any other payment in respect of a Production, and neither us nor
            any Client shall have additional liability to Creator or any other
            person in respect of any Production following payment in full of the
            Creator Payment.
          </strong>
        </li>{' '}
        <li>
          23.18{' '}
          <strong>
            The Client and the Creator acknowledge that certain jurisdictions
            (e.g. South Africa) impose restrictions on offshore payments from
            those jurisdictions, and that, under applicable law, certain
            approvals, permits or consents must be obtained from the relevant
            authorities before any payment can be made (e.g. where the Client,
            the Creator and/or BlueAvo are located in different countries).
            Accordingly, such requirements may delay the ability of BlueAvo, the
            Client and/or the Creator to make any payments under the Contract.
            To the extent permitted under applicable law, the Client and the
            Creator are responsible for obtaining such approvals, permits or
            consents and BlueAvo will not be liable for a failure to obtain
            same.
          </strong>
        </li>
      </UL>{' '}
      <UL>
        24. <strong>BREACH OF THE CONTRACT.</strong>
        <li>
          24.1 If the Creator commits a material breach of any term of the
          Contract that cannot be remedied or, if capable of remedy, has not
          been remedied within 7 (seven) days of written notice from Client
          requiring such remedy, Client may cancel the Contract with immediate
          effect on provision of written notice to the Creator and us.{' '}
          <strong>
            To the maximum extent permitted by applicable law, such termination
            will not affect the Client’s obligation to pay the BlueAvo Fee.
          </strong>
        </li>{' '}
        <li>
          24.2 If the Client commits a material breach of any term of the
          Contract that cannot be remedied or, if capable of remedy, has not
          been remedied within 7 (seven) days of written notice from Creator
          requiring such remedy, Creator may cancel the Contract with immediate
          effect on provision of written notice to the Client and us.{' '}
          <strong>
            To the maximum extent permitted by applicable law, such termination
            will not affect the Client’s obligation to pay the BlueAvo Fee.
          </strong>
        </li>{' '}
        <li>
          24.3 If the Creator or the Client commits a material breach of any
          term of the Contract that impacts BlueAvo and that cannot be remedied
          or, if capable of remedy, has not been remedied within 7 (seven) days
          of written notice from BlueAvo requiring such remedy, BlueAvo may
          cancel the Contract with immediate effect on provision of written
          notice to the Creator and the Client.{' '}
          <strong>
            To the maximum extent permitted by applicable law, such termination
            will not affect the Client’s obligation to pay the BlueAvo Fee.
          </strong>
        </li>
      </UL>{' '}
      <UL>
        25.{' '}
        <strong>
          FEES PAYABLE BY CLIENT IN RELATION TO CANCELLING BRIEFS.
        </strong>{' '}
        <li>
          25.1 Client may terminate a Brief (and accordingly, if applicable, the
          Contract) for convenience at any time on written notice to the Creator
          and BlueAvo provided that it pays the cancellation fees set out below.
        </li>{' '}
        <li>
          25.2 If Client cancels a Brief 72 hours or more before the Response
          Deadline, Client will not incur cancellation fees.
        </li>{' '}
        <li>
          25.3{' '}
          <strong>
            To the maximum extent permitted by applicable law, if Client cancels
            a Brief less than 72 hours before the Response Deadline or after the
            Response Deadline, but before selection of a successful Response,
            Client will be liable for 50% of the BlueAvo Fee. We may, in our
            absolute discretion, agree to waive some or all of the BlueAvo Fee
            if we determine that the Responses received were not satisfactory to
            Client's Brief.
          </strong>
        </li>{' '}
        <li>
          25.4{' '}
          <strong>
            To the maximum extent permitted by applicable law, if Client cancels
            a Brief (and accordingly, if applicable, the Contract) more than 7
            days prior to the first scheduled production day for the Production,
            Client will be liable for 100% of the BlueAvo Fee, and:
          </strong>
        </li>{' '}
        <li>
          25.4.1{' '}
          <strong>
            Client must pay the greater of 100% of the Creator's actual expenses
            incurred prior to cancellation and 15% of the Creator Payment.
          </strong>
        </li>{' '}
        <li>
          25.5{' '}
          <strong>
            To the maximum extent permitted by applicable law, If Client cancels
            a Brief (and accordingly, if applicable, the Contract) 7 days or
            less prior to the first scheduled production day for the Production,
            Client will be liable for 100% of the BlueAvo Fee, and:
          </strong>
        </li>{' '}
        <li>
          25.5.1{' '}
          <strong>
            Client must pay the greater of 100% of the Creator's actual expenses
            incurred prior to cancellation and 30% of the Creator Payment.
          </strong>
        </li>{' '}
        <li>
          25.6{' '}
          <strong>
            To the maximum extent permitted by applicable law, if Client cancels
            a Brief (and accordingly, if applicable, the Contract) after the
            first scheduled production day for the Production, Client will be
            liable for 100% of the BlueAvo Fee, and:
          </strong>
        </li>{' '}
        <li>
          25.6.1{' '}
          <strong>
            Client must pay the greater of 100% of the Creator's actual expenses
            incurred prior to cancellation and 60% of the Creator Payment.
          </strong>
        </li>
        <li>
          25.7 Creator's actual expenses referred to in the above clauses, must
          be evidenced by receipts provided by the Creator.
        </li>{' '}
        <li>
          25.8 If the amount of the Creator Payment released to the Creator as
          at the date of cancellation is more than the amount referred to in any
          of the scenarios outlined at clauses 25.4 to 25.6 (inclusive), the
          Creator will refund to Client an amount equal to the Creator Payment
          released to the Creator less the relevant amount referred to at
          clauses 25.4 to 25.6 (inclusive). The Creator will pay this amount to
          us and we will release this amount to the Client once received by us
          in our bank account in full and clear funds.
        </li>{' '}
        <li>
          25.9 If the amount of the Creator Payment released to the Creator as
          at the date of cancellation is less than the amount referred to at
          clauses 25.4 to 25.6 (inclusive) (as the case may be for each
          scenario), Client must pay to the Creator such amounts as is necessary
          to give effect to this clause 25.The Client will pay this amount to us
          within 7 days of cancellation and we will release this amount to the
          Creator once received by us in our bank account in full and clear
          funds.
        </li>
      </UL>{' '}
      <UL>
        26. <strong>CLIENT ASSETS.</strong>{' '}
        <li>
          26.1 If Client uploads Client Assets to the Platform, or otherwise
          provides Client Assets to us or any Creator, Client grants:
        </li>
        <li>
          26.1.1 us, a non-exclusive, revocable, royalty-free, worldwide licence
          to reproduce, host and store the Client Assets and to make them
          available to Creators strictly for the purposes of the operation of
          the Platform, Posting of Briefs and performance of the Contract in
          accordance with these Terms; and
        </li>{' '}
        <li>
          26.1.2 each Creator a non-exclusive, revocable, non-transferable,
          royalty-free, worldwide licence to use, reproduce, adapt, edit and
          modify the Client Assets as reasonably required (or directed by the
          Client) to both produce and submit Responses and perform the Contract,
          (the “<strong>Licence</strong>
          ”).
        </li>{' '}
        <li>
          26.2 Creator agrees to use the Client Assets in accordance with the
          Licence solely for the purposes of creating and submitting their
          relevant Response to a Brief and/or completing the Production.
        </li>{' '}
        <li>
          26.3 Upon final acceptance of the Production, cancellation of any
          Brief or notification that Creator's Response has been unsuccessful,
          Creator agrees to make no further use of the Client Assets and delete
          all copies of such Client Assets in Creator's possession.
        </li>{' '}
        <li>
          26.4 Notwithstanding anything in clause 26.3, for 3 years from the
          date of final acceptance of the Production, the Creator must retain
          (in a secure location), a safe and secure copy of the final Production
          files and any key project files pertaining to the Production. Within
          24 hours and if requested by BlueAvo or a Client, the Creator must
          (without claiming any additional fees) provide a downloadable link to
          all master files, super-less master files and separate audio splits.
          If the transfer can only be carried out physically, the Client will
          reimburse the Creator's reasonable costs for the purchase and
          couriering of a portable hard drive.
        </li>{' '}
        <li>
          26.5 Other than in relation to the grant of the Licence, nothing in
          the Contract will be taken to constitute a transfer, assignment or
          grant of any ownership or other rights in any Intellectual Property
          rights in the Client Assets to us or Creator.
        </li>{' '}
        <li>
          26.6 <strong>Client warrants that:</strong>
        </li>{' '}
        <li>
          26.6.1{' '}
          <strong>
            Client has all necessary rights, licences, permissions and consents
            to grant the Licence;
          </strong>
        </li>{' '}
        <li>
          26.6.2{' '}
          <strong>
            the Client Assets and the Licence, and our and/or any Creator's
            exercise of the Licence, shall not infringe the rights, including
            (without limitation) the Intellectual Property rights, of any third
            party; and
          </strong>
        </li>{' '}
        <li>
          26.6.3{' '}
          <strong>
            the Client Assets shall not include anything which is or may
            reasonably be regarded as offensive, blasphemous, obscene,
            defamatory or otherwise unlawful.
          </strong>
        </li>
      </UL>{' '}
      <UL>
        27. <strong>INTELLECTUAL PROPERTY UNDER THE CONTRACT.</strong>{' '}
        <li>
          27.1 In respect of the relevant Production, and in consideration of
          the payment of the Creator Payment in full, but subject at all times
          to applicable law and clause 27.2 (where applicable):
        </li>{' '}
        <li>
          27.1.1 each party shall retain all right, title and interest in any
          Intellectual Property that it owned prior to the Contract;
        </li>{' '}
        <li>
          27.1.2 the Client shall own all Assigned Materials, including the
          Production and deliverables, as well as any and all Intellectual
          Property forming part of such deliverables created or produced by the
          Creator under the Contract, (except any pre-existing Intellectual
          Property rights of the Creator) following receipt by the Creator of
          payment in full for each Deliverable{' '}
          <strong>
            and the Creator acknowledges that it shall not obtain any right,
            title or interest in or to such Intellectual Property rights;
          </strong>
        </li>{' '}
        <li>
          27.1.3 the Client shall own all Third-Party Materials comprised in the
          Production, where an assignment of such materials is available on
          commercially reasonable terms which have been approved by the Client
          in accordance with clause 27.8; and
        </li>
        <li>
          27.1.4 where no assignment of Third-Party Materials is available at
          all or on commercially reasonable terms, the Creator shall procure a
          licence of such materials on appropriate terms.
        </li>{' '}
        <li>
          27.2{' '}
          <strong>
            The Client understands and acknowledges that certain jurisdictions
            (e.g. South Africa) impose restrictions on the offshore transfer
            and/or licensing of Intellectual Property from those jurisdictions,
            and that, under applicable law, certain approvals, permits or
            consents must be obtained from the relevant authorities before the
            Intellectual Property can be transferred or licensed to the Client
            (e.g. where the Client and the Creator are located in different
            countries). It is the Client and the Creator’s responsibility to
            obtain such approvals, permits or consents and, to the extent
            permitted under applicable law, BlueAvo will not be liable for a
            failure to obtain same.
          </strong>
        </li>{' '}
        <li>
          27.3 Accordingly, following payment in full for the Production,
          subject to clause 27.2 and to the extent permitted under applicable
          law, the Creator hereby irrevocably cedes, assigns, transfers and
          makes over absolutely to the Client, automatically without the need
          for the Creator or the Client to take any further steps to effect such
          assignment, any and all rights in and to any existing and future
          Intellectual Property rights created or arising from the Assigned
          Materials and in and to any and all claims and potential claims
          (including any accrued rights and claims) in relation thereto (the “
          <strong>Assigned Rights</strong>
          ”) for the full duration of such rights, wherever in the world
          enforceable. In this regard, for the avoidance of doubt, the
          aforementioned assignment includes cession, assignment, transfer and
          making over of all of the following in relation to each of the
          Assigned Materials and Assigned Rights: the right to bring, make,
          oppose, defend, appeal proceedings, claims or actions and obtain
          relief (and to retain any damages recovered) in respect of any
          infringement, or any other cause of action arising from ownership, of
          any of the Assigned Materials or Assigned Rights.
        </li>{' '}
        <li>
          27.4 The Client hereby accepts the rights assigned, ceded, transferred
          or made over to it in this clause 27.
        </li>{' '}
        <li>
          27.5{' '}
          <strong>
            The Client and the Creator acknowledge that if the above assignment
            is not permitted under applicable law, and/or does not comply with
            all formalities prescribed under applicable law, the Client and the
            Creator may need to enter into a separate assignment agreement
            (signed in wet ink) to give effect to the assignment, and the
            Creator undertakes to do so.
          </strong>
        </li>{' '}
        <li>
          27.6 The Creator shall, at the Client’s cost, do and execute, and
          procure the doing and executing of, each necessary agreement, act,
          document and thing that may reasonably be necessary to perfect the
          right, title and interest of the Client in and to the Assigned
          Materials and the Assigned Rights; including but not limited to
          entering into a separate assignment agreement (if required), procuring
          such an assignment from any employee and any party who is not an
          employee of the Creator and who engages in producing or providing the
          Production or otherwise in any way authoring, creating, modifying or
          further developing any of the Assigned Materials or any Assigned
          Rights for or on behalf of the Creator (“
          <strong>Third Party Creators</strong>
          ”).
        </li>{' '}
        <li>
          27.7{' '}
          <strong>
            The Creator shall, at the Client’s request and cost, do all things
            reasonably necessary to protect the Client’s rights in registering
            or otherwise protecting any Intellectual Property created for, owned
            by, ceded and/or assigned to the Client by the Creator.
          </strong>
        </li>{' '}
        <li>
          27.8 The Creator shall ensure and/or procure that its written
          contracts with its sub-contractors (if any) include:
        </li>{' '}
        <li>
          27.8.1 a warranty that all Intellectual Property created by the
          sub-contractor is original and does not infringe the Intellectual
          Property rights of any third party;
        </li>{' '}
        <li>
          27.8.2 the necessary confidentiality provisions are adhered to, and
          procure the signature of the necessary non-disclosure and
          confidentiality undertakings from the sub-contractor(a) and/or any of
          its personnel who need to be given access to the Client’s confidential
          information; and
        </li>{' '}
        <li>
          27.8.3 a cession and assignment to the Client of all individual
          rights, title and interest in and to any and all Intellectual Property
          created by the sub-contractor as part of its sub-contracting services,
          for no additional consideration.
        </li>{' '}
        <li>
          27.9 The Creator indemnifies the Client against any losses, damages,
          costs, claims or penalties caused by its failure to procure the
          warranty and cession and assignment referred to in clause 27.7 above
          and shall, at its own cost, procure the assignment, cession or
          assignment upon the request of the Client.
        </li>{' '}
        <li>
          27.10 To the extent permitted by applicable law, the Creator hereby,
          and the Creator shall procure that any Third Party Creators,
          unconditionally and irrevocably waives, in favour of the Client or its
          successor in title, all moral rights in and to the Assigned Materials
          and the Assigned Rights, to which the Creator and/or such Third Party
          Creators may be entitled under applicable law. In this regard, the
          Creator hereby, and shall procure that any Third Party Creators,
          unconditionally and irrevocably: (i) agrees not to enforce any of his
          or her moral rights in and to the Assigned Materials; (ii) agrees not
          to claim authorship or ownership of the Assigned Materials or any
          Assigned Rights; and (iii) consents to and agrees not to object to any
          acts and omissions of the Client or its nominee in relation to the
          Assigned Materials, including any distortion, mutilation or other
          modification thereof.
        </li>{' '}
        <li>
          27.11 The Creator shall not incorporate and/or use Third-Party
          Materials in any deliverable unless the Creator has obtained the prior
          written consent of the Client to do so. The Creator shall give the
          Client reasonable prior notice of its intention to include any such
          Third-Party Materials and at the same time shall notify the Client in
          writing (via the Platform):
        </li>
        <li>
          27.11.1 whether an assignment to the Client of the relevant
          Third-Party Materials can be obtained and the cost of obtaining it;
          and
        </li>{' '}
        <li>
          27.11.2 if no assignment of the relevant Third-Party Materials can be
          obtained or cannot be obtained for a reasonable cost, the cost of
          obtaining a licence of such materials on appropriate terms. The
          Creator shall not propose the inclusion of any Third-Party Materials
          in any deliverable unless the Creator is able to negotiate a licence
          of such materials on appropriate terms.
        </li>{' '}
        <li>
          27.12 Following receipt of the Creator’s notice under clause 27.7, the
          Client shall notify the Creator in writing (via the Platform) of
          whether inclusion of the relevant Third-Party Material is approved,
          and whether the Creator is required to obtain an assignment (if
          available) or a licence, and if so, the extent of such licence
          required and the approved cost thereof. The Client may also require
          the Creator to negotiate prices at which the licence obtained may, at
          the Client’s option, be extended.
        </li>{' '}
        <li>
          27.13 The Creator shall ensure that all licences of Third-Party
          Materials are granted directly to the Client.
        </li>
        <li>
          27.14 The Client shall comply with all third party licence
          restrictions.
        </li>
        <li>
          27.15 <strong>The Creator warrants and undertakes that:</strong>
        </li>{' '}
        <li>
          27.15.1{' '}
          <strong>
            Creator has the sole right and authority to assign the rights to the
            Client expressed to be assigned above and, as applicable, to license
            any rights to be licensed to the Client; and
          </strong>
        </li>{' '}
        <li>
          27.15.2{' '}
          <strong>
            the Production is wholly original to Creator (or its licensors) and
            does not infringe the Intellectual Property or any other rights of
            any third party.
          </strong>
        </li>{' '}
        <li>
          27.16{' '}
          <strong>
            Notwithstanding the above, the Creator will not be responsible for,
            unless agreed in writing between Client and the Creator, public
            performance rights in respect of the Production (including without
            limitation in relation to musical compositions).
          </strong>
        </li>{' '}
        <li>
          27.17 The Creator will not directly or indirectly, adapt, vary,
          modify, reproduce, translate or create any derivative work from the
          Assigned Materials and/or any Intellectual Property of the Client.
        </li>{' '}
        <li>
          27.18 Client and Creator hereby grant us a non-exclusive,
          royalty-free, worldwide, perpetual licence to reproduce, host and
          store all Productions submitted by Creator under the Contract and to
          make them available to the relevant Client. We undertake to maintain
          accurate and up to date records of all materials retained by us by way
          of this licence.
        </li>{' '}
        <li>
          27.19 Unless otherwise stated in a Brief, if Client rejects a
          Production there will be no assignment or grant of rights by the
          Creator to Client in relation to that Production and, except as
          otherwise agreed by Client and the Creator, Client must not exploit
          the Production in any manner.
        </li>{' '}
        <li>
          27.20 The Creator shall procure from all artists, photographers,
          actors, models, performers of music, owners of any other Intellectual
          Property or other rights in materials incorporated into any
          deliverable and all other third parties used by the Creator in
          connection with deliverables, all necessary consents (including
          without limitation under the Performers’ Protection Act 1967),
          releases, and approvals to use their performance, work or images.
          27.21 Without limiting this clause 27, the Creator undertakes that all
          personnel involved in, and all contributors to (including talent and
          music), any Production will sign release forms and other written
          agreements (together “<strong>Production Contracts</strong>
          ”) if required which will amongst others:
        </li>{' '}
        <li>
          27.21.1 include all relevant assignments or licenses (as applicable)
          to enable the Client, its successors, assignees and licensees to
          exploit the Production (or any part of it) in all media, globally and
          in perpetuity, subject to the usage limitations specified in the Brief
          which only apply to such personnel and relevant third party
          contributors (e.g. music composers) for an unlimited number of
          transmissions and/or exhibitions and for the full period of copyright,
          without any requirement to pay any further costs (other than those
          contemplated in the Brief), subject at all times to applicable law;
        </li>
        <li>
          27.21.2 contain express waivers of moral rights in connection with the
          Production; and
        </li>{' '}
        <li>
          27.21.3 be free of any third party limitations or residual fees unless
          otherwise agreed in writing with the Client in accordance with the
          Contract.
        </li>{' '}
        <li>
          27.22 The Creator must enter into all relevant Production Contracts in
          relation to the Production.
        </li>{' '}
        <li>
          27.23 We provide digital release form templates to assist in the
          process of concluding Production Contracts.{' '}
          <strong>
            However, Creator and Client acknowledge that either of our digital
            release forms or a release form provided by the Client may be used.
            We are not responsible or liable for any failure of any Creator to
            enter into any Production Agreement or obtain any release from any
            third party and are not responsible or liable for a failure to
            obtain any rights or waivers required for the Creator to provide the
            Production or for the Client to fully exploit the Production.
            Accordingly, the Client is solely responsible for:
          </strong>
        </li>{' '}
        <li>
          27.23.1{' '}
          <strong>
            ensuring that the Creator has obtained all necessary Production
            Contracts and/or release forms;
          </strong>
        </li>{' '}
        <li>
          27.23.2{' '}
          <strong>
            ascertaining that the rights and waivers obtained pursuant to these
            documents are sufficient for Client's proposed exploitation of the
            Production; and
          </strong>
        </li>{' '}
        <li>
          27.23.3{' '}
          <strong>
            requesting any necessary copies and/or other evidence from the
            Creator for these purposes.
          </strong>
        </li>{' '}
        <li>
          27.24 Without limiting the foregoing, Client may request in a Brief
          that Creator use Client's preferred form of Production Contracts. If
          Client makes such a request, Creator must use Client's preferred form
          of Production Contracts. The Creator must not use its own Production
          Contracts without the express consent of the Client.
        </li>{' '}
        <li>
          27.25 Creator must provide copies of the Production Contracts to us
          and/or the relevant Client upon request, including completing any
          workflow (e.g. digital release forms) that is required of the Creator
          via the Platform.
        </li>{' '}
        <li>
          27.26 Creator will maintain in force, with a reputable insurance
          company, errors and omission insurance, professional indemnity
          insurance (which will include cover pertaining to a Creator breach of
          any third party Intellectual Property and Client Confidential
          Information) and public liability insurance to cover the liabilities
          that may arise under or in connection with any Contract and shall, on
          our or the Client's request, produce both the insurance certificate
          giving details of cover and the receipt for the current year's premium
          in respect of each insurance.{' '}
          <strong>
            Creator warrants that it holds all such insurance policies and that
            such insurance policies fully cover its participation in any Brief.
            Creator acknowledges that it alone will bear any and all liability
            arising from any failure to hold such insurance and it shall have no
            claim against us or the Client in respect of any losses Creator
            incurs. Client warrants that it has checked and satisfied itself of
            the Creator's insurance status, and we have no liability whatsoever
            to Client in relation to such insurance.
          </strong>
        </li>{' '}
        <li>
          27.27{' '}
          <strong>
            Creator warrants that neither it nor any contributor to any
            Production is subject to the requirements or obligations of any
            union, guild, collecting society or similar association (“Union”)
            or, if Creator or any such party is subject to any Union, Creator
            warrants and agrees to fully comply with (and procure the
            contributor’s full compliance with) such Union's rules and policies
            and Creator indemnifies and hold us and the Client harmless for any
            Union related claims whatsoever and howsoever caused.
          </strong>
        </li>{' '}
        <li>
          27.28 <strong>Creator agrees and consents:</strong>
        </li>{' '}
        <li>
          27.28.1{' '}
          <strong>
            if reasonably requested by us or the Client, to participate in press
            photography/filming, media interviews and/or other
            promotional/publicity activity in respect of the Platform, and/or
            any Production produced by Creator; and
          </strong>
        </li>{' '}
        <li>
          27.28.2{' '}
          <strong>
            that we and/or the Client shall have the right to use Creator or
            Creator's key personnel's name, likeness, image rights and biography
            to promote the Platform and/or any Production produced by the
            Creator. The Creator warrants that it has obtained all relevant
            consents from its key personnel.
          </strong>
        </li>{' '}
        <li>
          27.29 Creator undertakes to notify a Client where it proposes to work
          with a celebrity or well-known talent or social influencers in
          relation to any Production and further undertakes to not depict or
          refer to any person in any Production without having first obtained
          the Client’s written consent for the depiction or reference and to the
          manner in which it is made.
        </li>
      </UL>{' '}
      <UL>
        28. <strong>CLIENT USAGE RIGHTS AND ADDITIONAL CLEARANCE FEES.</strong>{' '}
        <li>
          28.1 Client's exploitation of a Production, including via Digital
          Media, Broadcast Media or Print Media, may be subject to usage
          restrictions and/or payment of additional clearance fees or other
          payments to third parties (including talent, voice-over artists and
          music rights holders).{' '}
          <strong>
            Client is solely responsible for ensuring that it has obtained all
            rights and waivers necessary to so exploit the Production.
          </strong>
        </li>{' '}
        <li>
          28.2 For clarity, with respect to this clause 28, any usage
          restrictions (which may require additional clearance fees or payments)
          that may be applicable to third parties (including talent, voice-over
          artists and music rights holders) are enforceable solely by those
          third parties and not by Creators, unless otherwise required under
          applicable law.
        </li>
      </UL>{' '}
      <UL>
        29. <strong>DEALINGS BETWEEN US, THE CLIENT AND THE CREATOR.</strong>{' '}
        <li>29.1 Each of Creator and Client:</li>{' '}
        <li>
          29.1.1 agree to act reasonably and in good faith in their respective
          dealings with us and each other and to respond promptly to all
          communications.
        </li>{' '}
        <li>
          29.1.2{' '}
          <strong>
            acknowledge that we are not responsible for any element of the
            Production or liable for the acts or non-performance by Client or
            Creator.
          </strong>
        </li>{' '}
        <li>
          29.2 Client and Creator shall not circumvent or attempt to circumvent
          the Platform or send or receive consideration for any Brief, Contract
          or related work other than via the Platform. Without prejudice to any
          other of our rights or remedies under these Terms, we reserve the
          right to charge the Client the BlueAvo Fee in such circumstances.
        </li>{' '}
        <li>
          29.3{' '}
          <strong>
            Client shall indemnify us against all liabilities, costs, expenses,
            damages and losses suffered or incurred by us arising out of or in
            connection with any claim made against us arising out of or in
            connection with any breach of these Terms or the Contract by Client
            or actual or alleged infringement of a third party’s Intellectual
            Property rights arising out of, or in connection with any Content
            posted by the Client on the Platform, including (without limitation)
            any Brief or Client Asset or act or omission of Client that causes
            us to breach any applicable laws.
          </strong>
        </li>{' '}
        <li>
          29.4{' '}
          <strong>
            Creator shall indemnify us against all liabilities, costs, expenses,
            damages and losses suffered or incurred by us arising out of or in
            connection with any claim made against us arising out of or in
            connection with any breach of these Terms or the Contract or actual
            or alleged infringement of a third party’s Intellectual Property
            rights arising out of, or in connection with any Content posted by
            the Creator on the Platform, including (without limitation) any
            Response or Production or act or omission of Creator that causes us
            to breach any applicable laws.
          </strong>
        </li>{' '}
        <li>
          29.5{' '}
          <strong>
            Creator shall indemnify the Client against all liabilities, costs,
            expenses, damages and losses suffered or incurred by the Client
            arising out of or in connection with any claim that the Production
            or the Client's exploitation of the Production (in accordance with
            the Contract) infringes the Intellectual Property or other rights of
            any person or entity.
          </strong>
        </li>{' '}
        <li>
          29.6 Neither Creator nor Client, while registered on the Platform and
          for a period of 3 months after ceasing to be registered, shall:
        </li>{' '}
        <li>
          29.6.1 in the case of the Client, knowingly seek or accept the
          services of any Creator independently from the Platform; or
        </li>{' '}
        <li>
          29.6.2 in the case of the Creator, knowingly seek or accept any Client
          independently from the Platform; or
        </li>{' '}
        <li>
          29.6.3 induce, or attempt to induce, any user of the Platform to: (i)
          cease or refrain from using the Platform; or (ii) reduce its use of
          the Platform, or do any other thing which is reasonably likely to have
          such an effect.
        </li>
      </UL>{' '}
      <UL>
        30. <strong>RESPONSIBLE CONTRACTING.</strong>
        <li>
          30.1 Client and Creator must comply with all applicable laws,
          including without limitation, anti-bribery laws and regulations.
        </li>{' '}
        <li>
          30.2 Client and Creator and any person or entity working on Client or
          Creator's behalf, in connection with the Contract, shall not make any
          payment or transfer anything of value, directly or indirectly to any
          of the following if such payment or transfer would violate the laws of
          the country in which the transaction is made:
        </li>{' '}
        <li>
          30.2.1 any governmental official or employee (including employees of
          government-owned and government-controlled corporations and public
          international organizations);
        </li>{' '}
        <li>
          30.2.2 any political party, official of a political party, or
          candidate for public office;
        </li>{' '}
        <li>
          30.2.3 any intermediary, including, but not limited to, agents or
          family members of government officials, for payment to any government
          official;
        </li>{' '}
        <li>
          30.2.4 any other person or entity in a corrupt or improper effort to
          obtain or retain business or any advantage, in connection with Client
          or Creator's affairs;
        </li>{' '}
        <li>
          30.2.5 any business entity selling a competing product in order to
          eliminate or restrict competition, including, but not limited to,
          agreements to divide the market; or
        </li>{' '}
        <li>30.2.6 any other person or entity.</li>
        <li>
          30.3{' '}
          <strong>
            Client and Creator each warrant and represent that, should it learn
            of or have reason to suspect any breach of the obligations in
            clauses 30.1 or 30.2 above, it will take appropriate remedial steps
            and promptly notify the other party and us.
          </strong>
        </li>{' '}
        <li>
          30.4{' '}
          <strong>
            Without limiting any other provision of the Contract, Client and
            Creator each represent and warrant that:
          </strong>
        </li>
        <li>
          30.4.1{' '}
          <strong>
            except as disclosed in writing, neither Client nor Creator has any
            conflict of interest with respect to its business dealings in
            accordance with the Contract;
          </strong>
        </li>{' '}
        <li>
          30.4.2{' '}
          <strong>
            their performance of their respective obligations under the Contract
            will not breach any applicable law (including, without limitation,
            any laws relating to export controls, sanctions, data protection,
            anti-trust prohibitions and minimum employment standards);
          </strong>
        </li>{' '}
        <li>
          30.4.3{' '}
          <strong>
            they will not engage in any activity that involves the exploitation
            of child labour or labour generally and will implement and comply
            with policies to protect the rights of workers in compliance with
            applicable laws (including, without limitation, ensuring that wages
            are paid to all applicable workers that meet or exceed legal minimum
            standards or applicable industry standards (whichever is higher) and
            workers are not required to work for more hours than applicable
            under the relevant legal standards and all overtime is voluntary);
          </strong>
        </li>{' '}
        <li>
          30.4.4{' '}
          <strong>
            they recognise and respect any legal rights of workers to freedom of
            association and collective bargaining.
          </strong>
        </li>
        <li>
          30.5 Creator must disclose to Client any ownership of or beneficial
          interest in Creator by:
        </li>{' '}
        <li>
          30.5.1 any governmental official or employee (including employees of
          government-owned and government-controlled corporations and public
          international organizations);
        </li>{' '}
        <li>
          30.5.2 any political party, official of a political party, or
          candidate for public office;
        </li>{' '}
        <li>
          30.5.3 any intermediary, including, but not limited to, agents or
          family members of government officials, for payment to any government
          official; or
        </li>{' '}
        <li>
          30.5.4 any employees of Client or family members of employees of
          Client.
        </li>
      </UL>{' '}
      <UL>
        31. <strong>LIMITATION OF LIABILITY UNDER THE CONTRACT.</strong>{' '}
        <li>
          31.1{' '}
          <strong>
            The restrictions on liability in this clause 31 apply to every
            liability arising under or in connection with the Contract including
            (without limitation) liability in contract, delict, tort.
            misrepresentation, breach of statutory duty or otherwise.
          </strong>
        </li>{' '}
        <li>
          31.2{' '}
          <strong>
            Nothing in the Contract shall limit or exclude a party’s liability:
            (i) for death or personal injury caused by its negligence, or the
            negligence of its personnel, agents or subcontractors; (ii) for
            fraud, fraudulent misrepresentation or wilful misconduct; or (iii)
            for any other liability which cannot be limited or excluded by
            Applicable Law.
          </strong>
        </li>{' '}
        <li>
          31.3{' '}
          <strong>
            No party to the Contract shall have any liability to any other
            parties for any indirect or consequential loss arising under or in
            connection with the Contract.
          </strong>
        </li>
        <li>
          31.4{' '}
          <strong>
            Our liability is limited as set out in these Terms, including
            without limitation clause 14.
          </strong>
        </li>
      </UL>{' '}
      <UL>
        32. <strong>GENERAL PROVISIONS UNDER THE CONTRACT.</strong>{' '}
        <li>
          32.1 The relationship between Client, Creator and us is and will be
          that of independent contractors and nothing in the Contract shall
          create an employment, trustee, partnership, joint venture or agency
          relationship and no party will make any representation as such.
        </li>{' '}
        <li>
          32.2 The Contract contains the entire agreement between the Client,
          the Creator and use and supersedes any prior written or oral agreement
          between them in relation to the matters dealt with in the Contract.
          <strong>
            Save as expressly provided for in the Contract or as required by
            law, no party shall have any claim or right arising from any
            undertaking, representation or warranty not included in the
            Contract.
          </strong>
        </li>{' '}
        <li>
          32.3 Save as otherwise expressly provided in these Terms, no agreement
          to amend, add to or otherwise vary or waive any of the provisions of
          the Contract (including this clause 32.3) or to cancel or terminate it
          shall be effective unless made in writing and duly signed by the
          Client, the Creator and use.
        </li>{' '}
        <li>
          32.4 If any provision or part of a provision of these Terms is held to
          be illegal, invalid, unenforceable or against public policy pursuant
          to a final adjudication by a court of competent jurisdiction, such
          provision will be deemed severed from these Terms and the remainder of
          these Terms will remain in full force and effect.
        </li>{' '}
        <li>
          32.5 Save as otherwise expressly provided in the Contract, neither the
          Client nor the Creator may assign, cede, delegate or otherwise
          transfer or deal with the Contract or any of its rights and
          obligations under it without the prior written consent of us and the
          other party (the Client or the Creator as applicable). We may at any
          time assign, cede, delegate or otherwise transfer or deal any of our
          rights or obligations under the Contract.
        </li>{' '}
        <li>
          32.6 Any failure by us, the Client or the Creator to exercise or delay
          by us, the Client or the Creator in exercising its rights or remedies
          provided under the Contract or by law does not constitute a waiver of
          that or any other right or remedy and does not prevent, limit or
          restrict the future exercise or enforceability of that or any other
          rights or remedies. No single or partial exercise of any right or
          remedy provided under the Contract or by law prevents or restricts the
          further exercise of that or any other right or remedy.
        </li>{' '}
        <li>
          32.7 No remedy conferred by the Contract is intended to be exclusive
          of any other remedy that is otherwise available under any law. Each
          remedy shall be cumulative and in addition to every other remedy given
          under the Contract or under any existing or future law. The election
          of any one or more remedy by one of the parties shall not constitute a
          waiver by such party of the right to pursue any other remedy.
        </li>{' '}
        <li>
          32.8 Termination of the Contract for any cause shall not release a
          party from any liability which at the time of termination has already
          accrued to such party or which thereafter may accrue in respect of any
          act or omission prior to such termination.
        </li>{' '}
        <li>
          32.9 Any provision of the Contract that expressly or by implication is
          intended to come into or continue in force on or after termination or
          expiry shall remain in full force and effect.
        </li>{' '}
        <li>
          32.10 Each party must pay its own costs (direct or otherwise) in
          relation to the negotiation, preparation, execution, delivery,
          stamping, registration, completion, variation and discharge of the
          Contract.
        </li>{' '}
        <li>
          32.11 <strong>Force Majeure Event</strong>{' '}
        </li>
        <li>
          32.11.1 Failure by the Client, the Creator or us to perform any of
          their respective obligations under the Contract (or a delay in such
          performance) due to a Force Majeure Event, will not constitute a
          breach of the Contract. The party affected by the Force Majeure Event
          will:
        </li>{' '}
        <li>
          32.11.1.1 promptly give the other parties notice of the Force Majeure
          Event and an estimate of the non-performance and delay;
        </li>{' '}
        <li>
          32.11.1.2 take all reasonable steps to overcome the effects of the
          Force Majeure Event; and
        </li>{' '}
        <li>
          32.11.1.3 resume performance as soon as practicable after the Force
          Majeure Event no longer affects that party, provided that if a Force
          Majeure Event continues for a period of 30 days or more, the Client
          may terminate a Brief or the Contract with immediate effect by
          providing notice to us and the Creator. Where a Force Majeure Event
          prevents production or filming which cannot be rescheduled despite the
          parties’ best efforts, the Client and Creator mutually agree to
          negotiate in good faith for the settlement of any reasonable out of
          pocket expenses incurred by the Creator prior to the Force Majeure
          Event (including a severe weather event).
        </li>{' '}
        <li>
          32.11.2 Creator must ensure that its production schedule for a
          Production that involves outdoor filming includes a back-up plan for
          inclement weather. If the Creator fails to have such a plan or to
          implement such a plan, then for the avoidance of doubt, clause 32.11.1
          above will not apply to any resulting failure by Creator to meet its
          obligations under the Contract.
        </li>{' '}
        <li>
          32.12 <strong>Confidentiality</strong>
        </li>{' '}
        <li>
          32.12.1 Each party shall treat and hold as secret and confidential all
          Confidential Information of any other party to the Contract that the
          recipient party may receive or which becomes known to the recipient
          party during the currency of, and pursuant to, the Contract.
        </li>{' '}
        <li>
          32.12.2 Each recipient party shall, in respect of information received
          from the other, employ the same methods and endeavours to prevent such
          information becoming known to third parties as it does in respect of
          its own Confidential Information.
        </li>{' '}
        <li>
          32.12.3 It is noted and agreed that no party will compromise the
          interests of any other party, by disclosing any of the other party’s
          know-how, client lists or any other Confidential Information to any
          third party in the provision of any service to such third party or
          otherwise. To this extent the parties specifically record that any
          information shared between them during the term of the Contract will
          be treated as confidential.
        </li>{' '}
        <li>
          32.12.4 Unless agreed otherwise in writing, all written instructions,
          drawings, notes, memoranda and records of whatever nature relating to
          the Confidential Information of a disclosing party, which has or will
          come into the possession of the recipient party and its personnel,
          will be, and will at all times remain, the sole and absolute property
          of the disclosing party, and shall be promptly handed over to such
          Party when no longer required for the purposes of the Contract,
          subject to the recipient party’s document retention policies.
        </li>{' '}
        <li>
          32.12.5 Each recipient party agrees and undertakes that, during the
          term of the Contract and thereafter for a period of 3 (three) years,
          it shall keep confidential and shall not use for its own purposes, nor
          without the prior written consent of the disclosing party disclose to
          any third party, all Confidential Information.
        </li>{' '}
        <li>
          32.12.6 To the extent necessary to implement the provisions of the
          Contract, each disclosing party may disclose Confidential Information
          to those of its employees and representatives as may be reasonably
          necessary or desirable, provided that before any such disclosure each
          recipient party shall make those employees and representatives aware
          of its obligations of confidentiality under the Contract and shall at
          all times procure compliance by those employees and representatives
          with such obligations.
        </li>
        <li>
          32.12.7 No recipient party shall use any disclosing party's
          Confidential Information for any purpose other than to exercise its
          rights and perform its obligations under or in connection with the
          Contract or to otherwise implement the provisions of the Contract.
        </li>{' '}
        <li>
          32.12.8 We, the Client or the Creator may not make, or permit any
          person to make, any public announcement, communication or circular
          (announcement) concerning the Contract without the prior written
          consent of the other parties.
        </li>
        <li>
          32.12.9 Upon termination or expiry of the Contract, the recipient
          party will deliver to the disclosing party or, at such disclosing
          party's option, destroy all originals and copies of Confidential
          Information in its possession, subject to the recipient party’s
          document retention policies.
        </li>{' '}
        <li>
          32.12.10 The recipient party agrees that a breach, whether
          deliberately or negligently, of this clause 32.12 by a recipient
          party, its direct or indirect employees, agents, consultants,
          sub-contractors or representatives, including, without limitation, the
          actual or threatened disclosure or unauthorised use of a disclosing
          party’s Confidential Information without the prior express written
          consent of the disclosing party, shall be a material breach of the
          Contract and these Terms. Furthermore, that the disclosing party may
          suffer irreparable harm and damages, such that no remedy at law will
          afford it adequate protection against, or for which monetary relief
          may be inadequate. Accordingly, in addition to any other remedies
          available to it, a disclosing party shall be entitled to equitable
          relief, including damages, specific performance and any other
          appropriate relief against the recipient party for such breach or
          threatened disclosure. Any such relief will be in addition to and not
          in lieu of any appropriate relief in the way of monetary damages.
        </li>{' '}
        <li>
          32.13 <strong>Personal Information</strong>
        </li>{' '}
        <li>
          32.13.1 Please refer to our Privacy & Cookies Policy for further
          explanation as to how we use the personal information we collect from
          you.
        </li>{' '}
        <li>
          32.13.2 Where we provide personal information or personal data (each
          as defined in the applicable Data Protection Laws (defined below))
          (together “<strong>personal information</strong>
          ”) to the Client or the Creator in respect of the Contract:
        </li>{' '}
        <li>
          32.13.2.1 the Client and the Creator shall each comply with applicable
          laws relating to data protection and privacy laws, including but not
          limited to the Protection of Personal Information Act, 4 of 2013 (“
          <strong>POPIA</strong>
          ”), (“
          <strong>Data Protection Laws</strong>
          ”);
        </li>{' '}
        <li>
          32.13.2.2 where the Client or the Creator acts as a “processor” or
          “operator” (as defined in the applicable Data Protection Laws), it
          shall establish and maintain adequate security measures to secure the
          integrity and confidentiality of any personal information that it
          processes, shall follow the requirements in the applicable Data
          Protection Laws, including section 19 of POPIA (where applicable), and
          shall not provide or give access to such personal information to any
          third party without the responsible party’s prior, written consent;
          and
        </li>{' '}
        <li>
          32.13.2.3 where the Client or the Creator acts as the “responsible
          party” or the “data controller” (as defined in the applicable Data
          Protection Laws), it shall ensure that it has all necessary
          appropriate consents and notices in place to enable lawful transfer of
          the personal information to the other of them and to us so that the
          other party and/or we (as applicable) may lawfully use, process and
          transfer the personal information in accordance with these Terms; and
        </li>{' '}
        <li>
          32.13.2.4 the provisions of this clause 32.13 are in addition to and
          do not relieve, remove or replace, a party’s obligations under the
          Data Protection Laws.
        </li>{' '}
        <li>
          32.14 <strong>Notices</strong>
        </li>{' '}
        <li>
          32.14.1 The Client, the Creator and we choose, for the purposes of the
          Contract and these Terms, the following addresses:
        </li>{' '}
        <li>
          32.14.1.1 us: The registered address in clause 3 of these Terms;
        </li>{' '}
        <li>
          32.14.1.2 Client and Creator: the physical address and email address
          provided to us on registration. To the extent required for legal
          purposes, and not already shared under clause 20.6, these will be
          provided to the Client or Creator (as applicable) upon written
          request, unless prohibited under applicable law.
        </li>{' '}
        <li>
          32.14.2 Any legal process to be served on us, the Client or the
          Creator may be served on it at the address specified for it in clause
          32.14.1 and it chooses that address as its domicilium citandi et
          executandi for all purposes under the Contract and these Terms.
        </li>{' '}
        <li>
          32.14.3 Any notice or other communication to be given to us, the
          Client or the Creator (as applicable) in terms of the Contract or
          these Terms or shall be valid and effective only if it is given in
          writing, provided that any notice given by email shall be regarded for
          this purpose as having been given in writing.
        </li>{' '}
        <li>
          32.14.4 A notice to us, the Client or the Creator (as applicable)
          which is sent by prepaid registered post in a correctly addressed
          envelope to the relevant address specified in clause 32.14.1 shall be
          deemed to have been received (unless the contrary is proved) within 14
          (fourteen) days from the date on which it was posted, or which is
          delivered to us, the Client or the Creator (as applicable) by hand at
          that address is deemed to have been received on the day of delivery,
          provided it was delivered to a responsible person during ordinary
          business hours.
        </li>{' '}
        <li>
          32.14.5 Each notice by email to us, the Client or the Creator (as
          applicable) at the relevant email address specified in clause 32.14.1
          shall be deemed to have been received (unless the contrary is proved)
          within 4 (four) hours of transmission if it is transmitted during
          ordinary business hours of the receiving Party or within 4 (four)
          hours of the beginning of the next business day after it is
          transmitted, if it is transmitted outside those ordinary business
          hours.
        </li>{' '}
        <li>
          32.14.6 Notwithstanding anything to the contrary in this clause 32.14
          a written notice or other communication actually received by us, the
          Client or the Creator (as applicable) (and for which written receipt
          has been obtained) shall be adequate written notice or communication
          to it notwithstanding that the notice was not sent to or delivered at
          its chosen address.
        </li>
        <li>
          32.14.7 We, the Client or the Creator (as applicable) may by written
          notice to the other parties change its address or email address for
          the purposes of this clause 32.14 to any other address (other than a
          post office box number) provided that the change shall become
          effective on the 7th (seventh) day after the receipt of the notice.
        </li>
        <li>
          These Terms were last updated on <strong>29/09/2020</strong> BlueAvo
          (Pty) Ltd, all rights reserved.
        </li>
      </UL>
    </div>
  );
}

export default TermsConditions;
