import React from 'react';
import Markdown from 'react-markdown';

const markdown = `**You have just completed a treatment submission!**
\n 
**Your treatment has been submitted for review by the BlueAvo Team.**
\n
**You will be notified if your treatment has been selected.**`;

function TreatmentSubmissionNotification() {
  return <Markdown source={markdown} />;
}

export default TreatmentSubmissionNotification;
