/* eslint-disable */
import React, { Component, useEffect } from 'react';
import * as S from '../Signup/Signup.style';
import * as L from './loginStyle';
import { Container } from './loginStyle';
import FormInput from '../../components/FormInput';
import FormLabel from '../../components/FormLabel';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import {
  loginUser,
  userSelector,
  clearState,
  uploadFile,
} from '../../features/User/UserSlice';

import { useHistory } from 'react-router-dom';

const FileUpload = ({}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { register, errors, handleSubmit } = useForm();

  const {
    isFetching,
    isSuccess,
    isError,
    errorMessage,
    user,
    isAuthenticated,
  } = useSelector(userSelector);

  const onSubmit = (data) => {
    dispatch(uploadFile(data));
  };

  return (
    <>
      <Container
        onSubmit={handleSubmit(onSubmit)}
        enctype="multipart/form-data"
      >
        <S.Heading>Upload Image</S.Heading>
        {/* <FormLabel>Username</FormLabel>
        <L.Input type="text" name="username" ref={register} /> */}
        <FormLabel>Select Image</FormLabel>
        <L.Input type="file" name="image" ref={register({ required: true })} />

        <L.Button type="submit">Upload</L.Button>
      </Container>
    </>
  );
};

export default FileUpload;
