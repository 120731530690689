/* eslint-disable */
import React from 'react';
import * as S from '../Signup/Signup.style';
import * as L from '../Login/loginStyle';
import { Container } from '../Login/loginStyle';
import FormLabel from '../../components/FormLabel';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { forgotPassword } from '../../features/User/UserSlice';
import { Link } from 'react-router-dom';

export default function ForgotPassword() {
  const dispatch = useDispatch();
  const onSubmit = (data) => {
    dispatch(forgotPassword(data));
  };
  const { register, errors, handleSubmit } = useForm();

  return (
    <>
      <Container onSubmit={handleSubmit(onSubmit)}>
        <S.Heading>Forgot Password</S.Heading>
        <FormLabel>Email</FormLabel>
        <L.Input
          type="email"
          name="email"
          placeholder="Enter your email address"
          ref={register({ required: true })}
        />
        <S.Error>{errors.email && 'Email is required'}</S.Error>

        <L.Button style={{ width: 'auto' }} type="submit">
          Reset Password
        </L.Button>

        <S.TermsContainer>
          <S.Terms
            style={{
              position: 'absolute',
              marginTop: '5px',
              marginLeft: '0',
            }}
          >
            Already have an account?
            <Link to="/login" style={{ fontSize: '10px' }}>
              {' '}
              Login
            </Link>
          </S.Terms>
        </S.TermsContainer>
      </Container>
    </>
  );
}
