/* eslint-disable */
import styled from 'styled-components';
import Dots from '../../assets/images/dots_small.png';

const MENU_MARGIN_XS = 62;
const MENU_MARGIN_M = 123;

export const Wrapper = styled.div`
  height: auto;
  min-height: 100vh;
  width: 100%;
  background: ${(props) => props.theme.colors.gray2};
  background-image: url(${Dots});
  background-repeat: repeat;
`;

export const Main = styled.div``;

export const LogoSection = styled.div`
  display: none;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: center;
  margin-bottom: 60px;
  margin-top: 18px;
  text-align: center;

  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
    margin-top: -5px;
    margin-left: 6px;
  }
`;

export const Logo = styled.img`
  height: 20.6px;
`;

export const LeftMenu = styled.nav`
  flex-flow: column nowrap;
  background-color: ${(props) => props.theme.colors.blueberry};
  box-shadow: 5px -5px 10px rgba(0, 0, 0, 0.2);
  position: fixed;

  top: 0;
  left: 0;
  transform: ${({ open }) => (open ? 'translateX(-100%)' : 'translateX(0%)')};
  height: 100vh;
  width: 211px;
  padding-top: 17px;
  transition: transform 0.1s ease-in-out;
  z-index: 1;

  /* For all screens larger than 992px */
  @media (min-width: 992px) {
    /* display: ${({ open }) => (open ? 'none' : 'block')}; */
    display: flex;
    left: 0;
    top: 0;
    width: 239px;
    position: fixed;
    align-content: flex-start;
  }
`;

export const MenuTitle = styled.a`
  margin-left: 5px;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 14px;
  color: white;

  /* &:hover {
    background-color: ${(props) => props.theme.colors.orange};
    height: 20px;
    width: 239px;
  } */
`;

export const SideNav = styled.div`
  margin-top: 60px;
  @media (min-width: ${(props) =>
      props.theme.breakpoints.s}) and (max-width: ${(props) =>
      props.theme.breakpoints.m}) {
    margin-top: 150px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    margin-top: 0;
  }
`;

export const List = styled.ul`
  cursor: pointer;
  /* padding: 5px; */
  /* @media (min-width: 900px) {
    padding: 5px;
    margin-left: 20px;
    margin-right: auto;
  } */
  /* height: 25px; */

  &:hover {
    background-color: ${(props) => props.theme.colors.orange};
    height: 20px;
  }
`;

export const ListItem = styled.li`
  list-style: none;
  text-decoration: none;
  /* &:hover {
    background-color: ${(props) => props.theme.colors.orange};
  } */
`;

export const IconMenu = styled.i`
  color: white;
  &:hover {
    color: red;
  }
`;

export const BackgroundUnderlay = styled.div`
  position: absolute;
  z-index: -1;

  @media (max-width: ${(props) => props.theme.breakpoints.xs}) {
  }
  @media (min-width: ${(props) =>
      props.theme.breakpoints.xs}) and (max-width: ${(props) =>
      props.theme.breakpoints.s}) {
  }
  @media (min-width: ${(props) =>
      props.theme.breakpoints.s}) and (max-width: ${(props) =>
      props.theme.breakpoints.m}) {
  }
  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
  }
`;

export const DotsUnderlay = styled.div`
  position: absolute;
  /* height: ${(props) => `calc(100% - ${props.heightReduction})`}; */
  height: 100%;
  width: 100%;
  overflow: hidden;

  @media (max-width: ${(props) => props.theme.breakpoints.s}) {
    margin-top: ${MENU_MARGIN_XS}px;
    height: calc(100% - ${MENU_MARGIN_XS}px);
  }
  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    /* margin-top: ${MENU_MARGIN_M}px; */
    /* height: calc(100% - ${MENU_MARGIN_M}px); */
  }
  top: 0;
  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
  }
`;
