/* eslint-disable */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as S from './dashboardLayout.styled';
import MenuBar from '../../components/MenuBar/MenuBar';
import TopMenuBar from '../../components/TopMenuBar/TopMenuBar';

const MENU_HEIGHT = '62px';

function DashboardLayout({ children, title, icon }) {
  const [renderView, setRenderView] = useState('');
  return (
    <S.Wrapper>
      <TopMenuBar setRenderView={setRenderView} />

      <S.SceneMenu>
        <MenuBar containerHeight={MENU_HEIGHT} />
      </S.SceneMenu>
      <S.SceneContainer>
        <S.ShotContainer>
          <S.Shot>
            <S.Frame>
              <S.Container>
                <S.Content>
                  <S.ContainerHeader>
                    <S.TitleContainer>
                      <S.Title>
                        {icon}
                        {title}
                      </S.Title>
                    </S.TitleContainer>
                  </S.ContainerHeader>
                  <S.ContainerBody>{children}</S.ContainerBody>
                </S.Content>
              </S.Container>
            </S.Frame>
          </S.Shot>
        </S.ShotContainer>
      </S.SceneContainer>
    </S.Wrapper>
  );
}
DashboardLayout.prototypes = {
  icon: PropTypes.element.isNotRequired,
  children: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
};
export default DashboardLayout;
