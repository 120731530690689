/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import * as S from './ViewBrief.styled';
import { viewBrief } from '../../../../redux/action';
import { useAsyncFunction } from '../../../helpers/AsyncFunction';
import { userSelector } from '../../../../features/User/UserSlice';
import axios from 'axios';
import Loader from '../../../../components/Loader/Loader';

export default function ViewBrief() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [brief, setBrief] = useState({});
  const [isError, setIsError] = useState(false);
  const [briefData, setBriefData] = useState({});

  let history = useHistory();

  const handleApplyClick = () => {
    history.push(`/briefs/${id}/apply`);
  };

  const handleEditClick = () => {
    history.push(`/briefs/my/${id}/edit`);
  };

  const cancel = () => {
    if (user.roles[0] === 'Creative') {
      history.push('/briefs');
    }
    if (user.roles[0] === 'Brand') {
      history.push('/briefs/my');
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      setIsError(false);
      setIsLoading(true);
      try {
        const result = await axios({
          method: 'get',
          url: `/api/briefs/${id}`,
          id,
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwtToken'),
          },
        });
        setBriefData(result.data);
        setIsLoading(false);
      } catch (error) {
        setIsError(true);
      }
    };
    fetchData();
  }, []);

  const { isAuthenticated, user, isFetching, isSuccess } = useSelector(
    userSelector,
  );

  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <S.Wrapper>
        <S.FlexRow>
          <S.Button
            onClick={() => {
              cancel();
            }}
            type="submit"
            style={{ marginRight: '15px' }}
          >
            Cancel
          </S.Button>
          {user.roles[0] === 'Creative' && briefData.stage === 'Open' ? (
            <S.Button onClick={handleApplyClick} type="submit">
              Apply
            </S.Button>
          ) : (
            <S.Button onClick={handleEditClick} type="submit">
              Edit
            </S.Button>
          )}
        </S.FlexRow>
        <S.BriefSummaryCard>
          <S.Heading>Brief Summary</S.Heading>
          <S.Line />
          <S.Grid>
            <S.LeftColumn>
              <S.ImageDiv>
                <S.Image src={briefData.imageUrl} alt="briefData image" />
              </S.ImageDiv>
            </S.LeftColumn>
            <S.MiddleColumn>
              <S.Title>Brief Type</S.Title>
              <S.OrangeValue>{`${briefData.type}`}</S.OrangeValue>
              <S.Title>Title</S.Title>
              <S.OrangeValue>{`${briefData.title}`}</S.OrangeValue>
              <S.Title>Client</S.Title>
              <S.OrangeValue>{`${briefData.client}`}</S.OrangeValue>
              <S.Title>Budget</S.Title>
              <S.OrangeValue>
                {`${briefData.currency}`}{' '}
                {parseFloat(briefData.budget.$numberDecimal)}
              </S.OrangeValue>
              <S.Title>Production Location</S.Title>
              <S.OrangeValue>{`${briefData.location}`}</S.OrangeValue>
            </S.MiddleColumn>

            <S.RightColumn>
              <S.Title>Submissions Due</S.Title>
              <S.OrangeValue>
                {new Date(briefData.dueDateUtc).toDateString()}
              </S.OrangeValue>
              <S.Title>Creator Commission Date</S.Title>
              <S.OrangeValue>
                {new Date(briefData.commissionDateUtc).toDateString()}
              </S.OrangeValue>
              <S.Title>Final Delivery Date</S.Title>
              <S.OrangeValue>
                {new Date(briefData.deliveryDateUtc).toDateString()}
              </S.OrangeValue>
            </S.RightColumn>
          </S.Grid>
        </S.BriefSummaryCard>
        <S.Card style={{ display: 'flex', flexDirection: 'column' }}>
          <div>
            <S.Heading>The Brief</S.Heading>

            <S.Line />
            <S.TextBody>{`${briefData.briefDetails}`}</S.TextBody>
          </div>

          <div>
            <S.Heading>About the Client</S.Heading>
            <S.Line />
            <S.TextBody>{`${briefData.clientDetails}`}</S.TextBody>
          </div>

          <div>
            {' '}
            <S.Heading>Goals and Objectives</S.Heading>
            <S.Line />
            <S.TextBody>{`${briefData.objectives}`}</S.TextBody>
          </div>

          <div>
            {' '}
            <S.Heading>Target Audience</S.Heading>
            <S.Line />
            <S.TextBody>{`${briefData.targetAudience}`}</S.TextBody>
          </div>

          <div>
            {' '}
            <S.Heading>Creative Direction</S.Heading>
            <S.Line />
            <S.TextBody>{`${briefData.creativeDirection}`}</S.TextBody>
          </div>

          <div>
            {' '}
            <S.Heading>Deliverables</S.Heading>
            <S.Line />
            {/* <S.TextBody> */}
            {briefData.deliverables.map((item, index) => (
              <div key={index}>
                <S.UL>
                  <li>{item}</li>
                </S.UL>
              </div>
            ))}
            {/* </S.TextBody> */}
          </div>

          <div>
            {' '}
            <S.Heading>Additional References</S.Heading>
            <S.Line />
            <S.UL>
              {briefData.urls.map((link, index) => (
                <li
                  key={index}
                  style={{
                    marginBottom: '5px',
                    display: 'block',
                    whiteSpace: 'pre-line',
                  }}
                >
                  <a
                    href={`${link}`}
                    rel="noreferrer noopener"
                    target="_blank"
                    style={{ color: '#5EC3EA', fontSize: '14px' }}
                  >
                    Link {index + 1}
                  </a>
                </li>
              ))}
            </S.UL>
          </div>

          <div>
            <S.Heading>Treatment Requirements</S.Heading>
            <S.Line />
            <S.TextBody>{`${briefData.treatmentRequirements}`}</S.TextBody>
          </div>

          <div>
            <S.Heading>Additional Notes</S.Heading>
            <S.Line />
            <S.TextBody>{`${briefData.additionalNotes}`}</S.TextBody>
          </div>
        </S.Card>
      </S.Wrapper>
    );
  }
}
