/* eslint-disable */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import * as S from './BriefCreationForm.style';
import LeftColumn from './LeftColumn';
import { briefFormSelector, setDeliverables } from './briefFormSlice';

import Terms from '../../../../pages/Terms';
import { storeToSessionStorage } from '../../../helpers/SessionController';

export const Step8 = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  let data;
  const { deliverables, keyDetailsProgressIndicator } = useSelector(
    briefFormSelector,
  );
  const { register, required, errors, handleSubmit } = useForm({
    defaultValues: { deliverables },
  });

  const [indexes, setIndexes] = React.useState([]);
  const [counter, setCounter] = React.useState(0);
  const [disableButton, setDisableButton] = useState(true);

  const fillArray = (data) => {
    let tempArray = [];
    if (data) {
      for (let i = 0; i < data.deliverables.length; i++) {
        tempArray.push(data.deliverables[i].deliverable);
      }
    }

    return tempArray;
  };

  const onSubmit = (data) => {
    const newData = fillArray(data);
    dispatch(setDeliverables(newData));
    storeToSessionStorage(data);
    history.push('/briefs/my/new/step9');
  };

  const previous = () => {
    history.push('/briefs/my/new/step7');
  };

  const addDeliverable = () => {
    setIndexes((prevIndexes) => [...prevIndexes, counter]);
    setCounter((prevCounter) => prevCounter + 1);
  };

  const removeDeliverable = (index) => () => {
    setIndexes((prevIndexes) => [
      ...prevIndexes.filter((item) => item !== index),
    ]);
    setCounter((prevCounter) => prevCounter - 1);
  };
  const activate = () => {
    setDisableButton(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <S.Wrapper>
        <S.Card>
          <S.Heading> Create a brief</S.Heading>
          <S.Line />
          <S.Grid>
            <LeftColumn
              progress={70}
              keyDetailsCompleted={keyDetailsProgressIndicator}
            />
            <S.RightColumn>
              <S.InputLabel htmlFor="deliverables">Deliverables</S.InputLabel>
              {indexes.map((index) => {
                const fieldName = `deliverables[${index}]`;
                return (
                  <S.DeliverableGroup
                    name={fieldName}
                    key={fieldName}
                    style={{ display: 'flex' }}
                  >
                    <S.Input
                      type="text"
                      name={`${fieldName}.deliverable`}
                      ref={register}
                      onChange={activate}
                    />
                    <i
                      className="fa fa-trash"
                      onClick={removeDeliverable(index)}
                      style={{
                        color: '#FFAA00',
                        float: 'right',
                        marginLeft: '25px',
                      }}
                    />
                  </S.DeliverableGroup>
                );
              })}

              <S.DeliverableGroup>
                Add deliverables
                <S.AddButton type="button" onClick={addDeliverable}>
                  Add
                </S.AddButton>
              </S.DeliverableGroup>
            </S.RightColumn>
          </S.Grid>

          <S.Footer>
            <S.Button onClick={previous}>Previous</S.Button>
            <S.NextButton disabled={disableButton} type="submit">
              Next
            </S.NextButton>
          </S.Footer>
        </S.Card>
      </S.Wrapper>
    </form>
  );
};
