/* eslint-disable */
import styled from 'styled-components';
import { FaBars } from 'react-icons/fa';

export const Wrapper = styled.div`
  z-index: 999;
  display: flex;
  position: fixed;
  top: 0;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: ${(props) => props.theme.colors.blueberry};
  display: none;
  @media (max-width: ${(props) => props.theme.breakpoints.s}) {
    display: flex;
    height: 62px;
    position: fixed;
  }
  @media (min-width: ${(props) =>
      props.theme.breakpoints.s}) and (max-width: ${(props) =>
      props.theme.breakpoints.m}) {
    display: flex;
    height: 123px;
    position: fixed;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    top: 50px;
  }
`;

export const Logo = styled.img`
  height: 40%;
  width: 60%;
  margin-left: 80px;
`;

export const MenuIcon = styled(FaBars)`
  color: #fff;
  position: absolute;
  right: 30px;
  height: 26px;
  width: 26px;
  @media (min-width: ${(props) =>
      props.theme.breakpoints.s}) and (max-width: ${(props) =>
      props.theme.breakpoints.xl}) {
    height: 50px;
    width: 50px;
    right: 58px;
  }
`;
