/* eslint-disable */
import styled from 'styled-components';
import Dots from '../../assets/images/dots_small.png';

const MENU_MARGIN_XS = 62;
const MENU_MARGIN_M = 123;

// export const Wrapper = styled.div`
//   position: fixed;
//   height: auto;
//   min-height: 100vh;
//   width: 100%;
//   background: ${(props) => props.theme.colors.blueberry};
//   background-image: url(${Dots});
//   background-repeat: repeat;
//   top: 0;
//   overflow-y: none;
// `;

export const Wrapper = styled.div`
  opacity: 1;
  display: grid;
  grid-template-rows: -webkit-min-content 1fr;
  grid-template-rows: min-content 1fr;
  transition: opacity 600ms;
  background: ${(props) => props.theme.colors.blueberry};
  background-image: url(${Dots});
  background-repeat: repeat;
  height: auto;
  width: 100%;
  min-height: 100vh;
  position: fixed;
`;

export const SceneMenu = styled.div`
  @media (min-width: 992px) {
    background: ${(props) => props.theme.colors.blueberry};
    display: flex;
    position: fixed;
    flex-direction: column;
    width: 64px;
    --opac: 0;
    height: 100vh;
    top: 0;
    z-index: 0;
    box-shadow: 0 10px 30px rgb(0 0 0 / 32%);
    transition: width 250ms 100ms;
  }
`;

export const SceneContainer = styled.div`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  opacity: 1;
  transition: opacity 400ms 100ms;
  z-index: 0;
  border-radius: 10px;
`;

export const ShotContainer = styled.div`
  width: 100vw;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
`;

export const Shot = styled.div`
  @media (min-width: 992px) {
    padding-left: 0;
    margin-left: 64px;
    display: flex;
    height: 100%;

    -webkit-overflow-scrolling: touch;
  }
`;

export const Frame = styled.div`
  display: flex;
  height: calc(100vh - 56px);
  -webkit-animation: fadeIn 900ms ease-out;
  animation: fadeIn 900ms ease-out;
  box-sizing: border-box;
  padding-bottom: 36px;
  margin: 0;
  flex-grow: 0;
  flex-shrink: 0;

  :last-child {
    padding-right: 24px;
  }
  @media (min-width: 992px) {
    margin: 0 0 0 24px;
  }
`;
export const PanelContainer = styled.div``;

export const Container = styled.div`
  /* background: ${(props) => props.theme.colors.gray6}; */
  background-color: #f1f2f5;
  border-radius: 0 0 10px 10px;
  /* max-width: calc(100vw - 110px); */
  width: 100vw;
  height: 100vh;
  margin: 62px auto 100px auto;
  /* width: 500px; */
  border: none;
  padding: 0;
  flex-shrink: 0;

  @media (min-width: 768px) {
    margin: 123px auto 100px auto;
  }
  @media (min-width: 992px) {
    border-radius: 10px;
    margin: 60px auto 100px auto;
    width: calc(100vw - 112px);
    max-width: none;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
export const ContainerHeader = styled.div`
  border-bottom: 1px solid #dddedf;
  margin: 0 var(--basePadding);
  transition: padding-top 200ms;
  position: relative;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: auto;
`;

export const Title = styled.h6`
  overflow: hidden;
  white-space: nowrap;
  margin: 16px 0;
  font-size: 1.5rem;
  line-height: 2rem;
`;

export const ContainerBody = styled.div`
  flex: 1 1 auto;
  overflow-y: auto;
 background-image: url(${Dots});
  background-repeat: repeat;
  padding: 16px var(--basePadding);
  -webkit-overflow-scrolling: touch;
  :not(.panel-body--no-bottom-padding) {
    padding-bottom: 0;
`;
