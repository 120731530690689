/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as S from './AccountVerified.style';
import { accountVerified, resendConfirmationEmail } from '../../redux/action';
import styled from 'styled-components';

const InputControl = styled.input`
  width: 208.52px;
  height: 34.88px;
  background: rgba(196, 196, 196, 0.2);
  border: none;
  outline: none;
  border-radius: 10px;
  margin-bottom: 14px;
  text-align: center;

  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    width: 407px;
    height: 53px;
  }
`;

function AccountVerified({ setForm, formData }) {
  const { email, token } = useParams();
  const emailAgain = formData;
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(true);
  const [status, setStatus] = useState({});
  const [message, setMessage] = useState('');
  const { handleSubmit, register, errors, required } = useForm();
  let errorNotification;
  let notification;

  const notify = (errorNotification) =>
    toast.error(`${errorNotification}`, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const dispatch = useDispatch();
  let history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      setError(false);
      setIsLoading(true);
      try {
        const result = await axios({
          method: 'get',
          url: `/api/auth/confirmation/${email}/${token}`,
        });

        setStatus(result); //HTTP Status Code: 200
        console.log(result.data.message);
        setMessage(result.data.message);
        setIsLoading(false);
        if (result === undefined) throw 'There is an ERROR HERE';
      } catch (error) {
        notify(error.response.data.message);

        // if (error.response.status !== 200) {
        //   console.log('unauthorized, logging out ...');
        //   console.log(error.response.data.message);
        //   notify(error.response.data.message);
        // }
        // return Promise.reject(error.response);
      }
    };
    fetchData();
  }, []);

  const handleLoginClick = () => {
    history.push('/login');
  };

  const handleResendVerification = () => {
    dispatch(resendConfirmationEmail(emailAgain, history));
  };

  if (!isLoading) {
    return (
      <S.Card>
        {status.status !== 200 ? (
          <form onSubmit={handleSubmit(handleResendVerification)}>
            <S.H1 style={{ color: '#A50418' }}>ERROR!</S.H1>
            {/* <S.H2>{error}</S.H2> */}
            <S.H2>{message}</S.H2>
            <S.P>Click the button to resend verification email.</S.P>
            <S.Container>
              <InputControl
                type="email"
                value={emailAgain}
                id="email"
                placeholder="Your email address"
                onChange={setForm}
              />

              <S.Button onClick={handleResendVerification}>
                Resend Verification Email
              </S.Button>
            </S.Container>
          </form>
        ) : (
          <div style={{ textAlign: 'center' }}>
            <S.H1>
              {message.includes('Congratulations')
                ? 'Congratulations!'
                : 'Error'}
            </S.H1>
            <S.Container>
              <S.H2>{message}</S.H2>
              <S.P>Click login to get started.</S.P>

              <S.Button onClick={handleLoginClick}>Login</S.Button>
            </S.Container>
          </div>
        )}
      </S.Card>
    );
  } else {
    return <></>;
  }
}

export default AccountVerified;
