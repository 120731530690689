/* eslint-disable */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import * as S from './BriefCreationForm.style';
import LeftColumn from './LeftColumn';
import { briefFormSelector, setURLS } from './briefFormSlice';
import { storeToSessionStorage } from '../../../helpers/SessionController';

export const Step9 = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { urls, keyDetailsProgressIndicator } = useSelector(briefFormSelector);
  const { register, handleSubmit } = useForm({
    defaultValues: { urls },
  });

  const fillArray = (data) => {
    let tempArray = [];
    if (data) {
      for (let o in data) {
        if (data[o].length > 0) {
          tempArray.push(data[o]);
        }
      }
    }

    return tempArray;
  };

  const onSubmit = (data) => {
    const newData = fillArray(data);
    dispatch(setURLS(newData));
    storeToSessionStorage(newData);
    history.push('/briefs/my/new/step10');
  };

  const previous = () => {
    history.push('/briefs/my/new/step8');
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <S.Wrapper>
        <S.Card>
          <S.Heading> Create a brief</S.Heading>
          <S.Line />
          <S.Grid>
            <LeftColumn
              progress={80}
              keyDetailsCompleted={keyDetailsProgressIndicator}
            />
            <S.RightColumn>
              <S.InputLabel htmlFor="additionalReferences">
                Additional References
              </S.InputLabel>
              <S.Input
                autoFocus
                id="url0"
                name="url0"
                type="text"
                placeholder="Url"
                ref={register}

                // Prop: Puts data into state
              />
              {/* <S.InputLabel htmlFor="twHandle">Your role</S.InputLabel> */}
              <S.Input
                id="url1"
                name="url1"
                type="text"
                placeholder="Url"
                ref={register}
                // Prop: Puts data into state
              />

              {/* <S.InputLabel htmlFor="igHandle">Instagram</S.InputLabel> */}
              <S.Input
                id="url2"
                name="url2"
                type="text"
                placeholder="Url"
                ref={register}
                // Prop: Puts data into state
              />

              {/* <S.InputLabel htmlFor="ytHandle">YouTube</S.InputLabel> */}
              <S.Input
                id="url3"
                name="url3"
                type="text"
                placeholder="Url"
                ref={register}
                // Prop: Puts data into state
              />

              {/* <S.InputLabel htmlFor="liHandle">LinkedIn</S.InputLabel> */}
              <S.Input
                id="url4"
                name="url4"
                type="text"
                placeholder="Url"
                ref={register}
                // Prop: Puts data into state
              />
            </S.RightColumn>
          </S.Grid>
          <S.Footer>
            <S.Button onClick={previous}>Previous</S.Button>
            <S.NextButton type="submit">Next</S.NextButton>
          </S.Footer>
        </S.Card>
      </S.Wrapper>
    </form>
  );
};
